<button class="btn btn-sm btn-gaj publierpost text-white" data-toggle="modal" data-target="#addcartesejourpost" *ngIf="currentuser?.role == 'admin'"><i class="fas fa-plus"></i> Publier </button>

<div class="col-lg-8 col-md-10 col-sm-12 mx-auto bg-white shadow px-3 pb-5 pt-3 blog-container">
	<h5 class="card-title">
		Carte de Séjour
	</h5>
	<div *ngFor="let post of posts">
		<app-presentationblog-item [post]="post"></app-presentationblog-item>
	</div>

</div>


<!-- Modal -->
<div class="modal fade" id="addcartesejourpost" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <div class="currentuserinfo px-3">
        	<div class="avatar">
        		<img src="assets/img/avatar.png" *ngIf="currentuser?.avatar == null">
				<img class="avatarimg" src="{{filepath}}/profileAvatars/{{currentuser?.avatar}}" *ngIf="currentuser?.avatar != null">
        	</div>
        	<div class="name">
        		{{currentuser?.lname}} {{currentuser?.fname}}
        	</div>
        </div>

        <form (submit)="AddBlogPost($event)" autocomplete="off">
    		<div class="form-group mx-0 my-2">
			    <textarea class="form-control radius-0 border-0" id="exampleFormControlTextarea1" rows="4" placeholder="Que voulez-vouz dire {{currentuser?.fname}} ?" name="description" [(ngModel)]="bpform.description"></textarea>
			</div>

			<div class="px-3" style="line-height: 60px; justify-content: space-between; display: flex; flex-direction: row;">
				<div>Ajouter à votre publication</div>
				<div>
					<label for="bpimage" class="btn ml-2 btn-light text-gaj"><i class="fas fa-image"></i></label>
					<label for="bpvideo" class="btn ml-2 btn-light text-gaj"><i class="fas fa-video"></i></label>
				</div>
				<input type="file" name="bpimage" id="bpimage" accept="image/*" name="image" (change)="onImageSelected($event)" [(ngModel)]="bpform.image"><!-- bp === blog post -->
				<input type="file" name="bpvideo" id="bpvideo" accept="video/mp4,video/x-m4v,video/*,video/mkv" name="video" (change)="onVideoSelected($event)" [(ngModel)]="bpform.video">
			</div>
			<div class="row mx-0 px-0 col-sm-12">
				<button type="submit" class="mx-3 mb-3 btn btn-sm btn-block btn-gaj">
					Ajouter
				</button>
			</div>

        </form>
      </div>
    </div>
  </div>
</div>
