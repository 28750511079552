export class Favoris {
	id: string;
    email: string;
    pubid: string;
	

	constructor(id, email, pubid) {
        this.id = '';
        this.email = email;
        this.pubid = pubid;
	}
}