export class User {
	id: string;
	userid: string;
	avatar: string;
	fname: string;
	lname: string;
	email: string;
	role: string;
	usertype: string;
	password: string;
	status: number;
	phone: string;
	passport: string;
	nationality: string;
	residencecountry: string;
	naissance: string;
	token: string;

	constructor(id, userid, avatar, fname, lname, email, role, usertype, password, status, phone, passport, nationality, residencecountry, naissance, token) {
		this.id = '';
		this.userid = userid;
		this.avatar = avatar;
		this.fname = fname;
		this.lname = lname;
		this.email = email;
		this.role = role;
		this.usertype = usertype;
		this.password = password;
		this.status = status;
		this.phone = phone;
		this.passport = passport;
		this.nationality = nationality;
		this.residencecountry = residencecountry;
		this.naissance = naissance;
		this.token = token;
	}
}