export class Comment {
	id: string;
	pub_id: string;
	user_email: string;
	commenttext: string;
	created_at: string;

	constructor(id, pub_id, user_email, commenttext, created_at) {
		this.id = 'not set';
		this.pub_id = pub_id;
		this.user_email = user_email;
		this.commenttext = commenttext;
		this.created_at = created_at;
	}
}