<div class="card p-0 col-lg-8 col-md-10 col-sm-12 mx-auto">
    <img src="{{filepath}}/actualitiesimgs/{{actuality?.image}}" alt="" style="max-height: 300px; width: 100%; object-fit: cover;" data-toggle="modal" href="#actualityimg">
    <div class="card-body">
        <h5 class="card-title">{{actuality?.title}}</h5>
        <p>
            {{actuality?.description}}
        </p>
    </div>
</div>


<!-- Modal -->
<div class="modal fade" id="actualityimg" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <img src="{{filepath}}/actualitiesimgs/{{actuality?.image}}" alt="">

      </div>
    </div>
  </div>
