import { Component, OnInit } from '@angular/core';
import { SelectService } from '../../services/select.service';
import { AddService } from '../../services/add.service';
import { UpdateService } from '../../services/update.service';
import { Message3Service } from '../../services/message3.service';
import { Notification } from '../../models/Notification';
import { User } from '../../models/User';
import { UserdataService } from '../../services/userdata.service';
import { Notif } from '../../models/Notif';


@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  currentusermail: any;
  notif: Notification;
  userinfo: User;

  allNotifs: Notif[] = [];

  pollingnotif: any;

  constructor(
  	private select: SelectService,
  	private add: AddService,
  	private update: UpdateService,
  	private msg3: Message3Service,
    private userdata: UserdataService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();

    this.userdata.getUserData(this.currentusermail).subscribe(data => {
      this.userinfo = data[0];
      console.log(this.userinfo);

      this.select.getOneNotifRow(this.currentusermail).subscribe(res => {
        if(res.length == 0 && this.userinfo.role != 'admin') {
          //add the notif row for the current user addNotif
          this.add.addNotifRow('', this.currentusermail, '1','1','1','1','1','1','1').subscribe(res => {
            console.log(res);
            this.msg3.setMessage('new notif row added');
          });
        } else {
          this.notif = res[0];
        }
      });

    });


    this.select.getNotifs(this.currentusermail).subscribe(res => {
      this.allNotifs = res.reverse();
      console.log(this.allNotifs);
    });



  	this.msg3.getMessage().subscribe(res => {
  		this.select.getOneNotifRow(this.currentusermail).subscribe(data => {
  			this.notif = data[0];
  		});
  	});

    this.consultnotif();
    this.pollDatanotif();

  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }


  showNotif($row_name) {
  	this.update.showNotif($row_name, this.currentusermail).subscribe(res => {
  		//console.log(res);
  		this.msg3.setMessage('an other operation');
  	});
  }

  hideNotif($row_name) {
  	this.update.hideNotif($row_name, this.currentusermail).subscribe(res => {
  		//console.log(res);
  		this.msg3.setMessage('an other operation');
  	});
  }


  pollDatanotif() {
    this.pollingnotif = setInterval(() => {
      this.consultnotif();
    }, 5000);
  }

  consultnotif() {

    this.select.getNotifs(this.currentusermail).subscribe(res => {
      if(this.allNotifs.length != res.length) {
        this.allNotifs = res.reverse();
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.pollingnotif);
  }


}
