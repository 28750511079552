import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Cycle } from 'src/app/models/Cycle';
import { Filiere } from 'src/app/models/Filiere';
import { SelectService } from 'src/app/services/select.service';

@Component({
  selector: 'app-v-cycle-details',
  templateUrl: './v-cycle-details.component.html',
  styleUrls: ['./v-cycle-details.component.css']
})
export class VCycleDetailsComponent implements OnInit {
  
  cycle: Cycle;
  filieres: Filiere[] = [];

  constructor(
    private select: SelectService,
    private router: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.router.params.subscribe(data => {
      this.select.getOneCycle(data.id).subscribe(res => {
        this.cycle = res[0];

        this.select.getFilieres(data.id).subscribe(all => {
          this.filieres = all;
        });
      });
    });
  }

}
