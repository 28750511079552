export class Notification {
	id: string;
	useremail: string;
	actuality: string;
	officiel: string;
	gajconsulting: string;
	gajTraining: string;
	gajtravel: string;
	gajScholarship: string;
	gajFablab: string;
	gajMagazine: string;

	constructor(id, useremail, actuality, officiel, gajconsulting, gajTraining, gajScholarship, gajFablab, gajMagazine) {
		this.id = '';
		this.useremail = useremail;
		this.actuality = actuality;
		this.officiel = officiel;
		this.gajconsulting = gajconsulting;
		this.gajTraining = gajTraining;
		this.gajScholarship = gajScholarship;
		this.gajFablab = gajFablab;
		this.gajMagazine = gajMagazine;
	}
}
