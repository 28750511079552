export class Cycle {
	id: string;
	email: string;
	cycle_id: string;
	name: string;
	description: string;

	constructor(id, email, cycle_id, name, description) {
		this.email = email;
		this.cycle_id = cycle_id;
		this.name = name;
		this.description = description;
	}
}