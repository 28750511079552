import { Component, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { SelectService } from '../../services/select.service';
import { Favoris } from '../../models/Favoris';
import { Publication } from '../../models/Publication';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-favoritepubs',
  templateUrl: './favoritepubs.component.html',
  styleUrls: ['./favoritepubs.component.css']
})
export class FavoritepubsComponent implements OnInit {

  user: User;
  currentmail: any;
  favorites: Favoris[] = [];

  publications: Publication[] = [];
  pub: Publication;

  i: any = 0;
  j: any = 0;

  constructor(
  	private select: SelectService,
  	private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.currentmail = localStorage.getItem('currentusermail');

  	this.select.selectUserFavorite(this.currentmail).subscribe(data => {
  		this.favorites = data.reverse();
  		//console.log(this.favorites);

  		for(this.i = 0; this.i < this.favorites.length; this.i++) {
  			this.select.getFavoritePublicationItem(this.favorites[this.i].pubid).subscribe(data => {
  				this.pub = data;
	  			this.publications[this.j] = this.pub[0];
	  			this.j++;
				//console.log(this.publications);
	  		});
	  	}
		
  	});


  	this.msg.getMessage().subscribe(data => {
  		this.publications = [];
  		this.j = 0;
  		this.favorites = [];

  		this.select.selectUserFavorite(this.currentmail).subscribe(data => {
	  		this.favorites = data.reverse();
	  		//console.log(this.favorites);

	  		for(this.i = 0; this.i < this.favorites.length; this.i++) {
	  			this.select.getFavoritePublicationItem(this.favorites[this.i].pubid).subscribe(data => {
	  				this.pub = data[0];
		  			this.publications[this.j] = this.pub;
		  			this.j++;
		  		});
		  	}
	  	});
  	});

	  	

  }

}
