import { Component, OnInit, Input } from '@angular/core';
import { Inscription } from '../../../models/Inscription';
import { UserdataService } from '../../../services/userdata.service';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';
import { User } from '../../../models/User';
import { SelectService } from 'src/app/services/select.service';
import { Filiere } from 'src/app/models/Filiere';
import { InscriptFile } from 'src/app/models/InscriptFile';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-demande-item',
  templateUrl: './admin-demande-item.component.html',
  styleUrls: ['./admin-demande-item.component.css']
})
export class AdminDemandeItemComponent implements OnInit {

  user: User;
  filiere: Filiere;
  files: InscriptFile[] = [];

  filepath = environment.filepath;

  @Input() demande: Inscription;
  constructor(
  	private userdata: UserdataService,
  	private update: UpdateService,
  	private msg: MessageService,
	  private selectservice: SelectService
  	) { }

  ngOnInit(): void {
  	this.userdata.getUserData(this.demande.email).subscribe(data => {
  		this.user = data[0];
  	});

	  this.selectservice.getInscriptItemFiles(this.demande.id).subscribe(res => {
		this.files = res;
	  });
	  this.selectservice.getOneFiliere(this.demande.filiere_id).subscribe(f => {
		this.filiere = f[0];
	  });
  }

  acceptInscription() {
  	this.update.acceptInscription(this.demande.id).subscribe(res => {
  		this.msg.setMessage('new operation');
  	});
  }

  rejectInscription() {
  	this.update.rejectInscription(this.demande.id).subscribe(res => {
  		this.msg.setMessage('new operation');
  	});
  }

}
