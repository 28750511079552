import { Component, OnInit } from '@angular/core';
import { UserdataService } from '../../services/userdata.service';
import { User } from '../../models/User';
import { Actuality } from '../../models/Actuality';
import { Message1Service } from '../../services/message1.service';
import { AddService } from '../../services/add.service';
import { HttpClient } from '@angular/common/http';
import { SelectService } from '../../services/select.service';

@Component({
  selector: 'app-actualities',
  templateUrl: './actualities.component.html',
  styleUrls: ['./actualities.component.css']
})
export class ActualitiesComponent implements OnInit {
  
  user: User;
  role: any;
  currentusermail: any;

  acts: Actuality[] = [];
  actualities: Actuality[] = [];

  i: any = 0;
  j: any = 0;

  constructor(
  	private userdataservice: UserdataService,
    private addservice: AddService,
    private http: HttpClient,
    private selectservice: SelectService,
    private msg1: Message1Service
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  		this.role = this.user.role; 
  	});

  	this.selectservice.getAll('actualities').subscribe((acts) => {
        this.acts = acts.reverse();
        this.actualities = this.acts;
    });

    this.msg1.getMessage().subscribe((pjct) => {
        this.selectservice.getAll('actualities').subscribe((acts) => {
            this.actualities = acts.reverse();
        }); 
    });
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

}
