import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-item-header',
  templateUrl: './item-header.component.html',
  styleUrls: ['./item-header.component.css']
})
export class ItemHeaderComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  form: FormGroup;
  public emailerror = [];
  public passworderror = [];

  public loginform = {
    email: null,
    password: null
  };

  public error = null;

  constructor(
  	private authentication: AuthenticationService,
    private token: TokenService,
  	private formBuilder: FormBuilder,
  	private http: HttpClient,
    private router: Router,
    private Auth: AuthService
  	) { }

  ngOnInit(): void {
  	this.form = this.formBuilder.group({
  		avatar: '',
  		fname: '',
  		lname: '',
  		email: '',
  		role: 'student',
  		password: '',
      password_confirmation: '',
  		blocked: 0,
  		phone: '',
  		passport: '',
  		nationality: '',
  		residencecountry: '',
  		token: ''
  	});
  }

  onSignup(): void {
    localStorage.setItem('currentusermail', this.form.getRawValue().email);

    this.authentication.register(this.form.getRawValue()).subscribe(
      data => this.handleRegisterResponse(data),
      error => {
      if(error.error.errors.password != null) {
        this.passworderror = error.error.errors.password[0];
      }
      if(error.error.errors.email != null) {
        this.emailerror = error.error.errors.email[0];
      }
    }
    );
  }

  handleRegisterResponse(data) {
    this.token.handle(data.access_token);
    localStorage.setItem('loggedUser', 'true');
    this.closebutton.nativeElement.click();
    this.router.navigateByUrl('/gaj/accueil');
  }

  handleRegisterError(error) {
    this.error = error.error.errors;
  }


  onSubmit() {
    this.authentication.login(this.loginform).subscribe(
      data => this.handleResponse(data),
      error => this.handleError(error)
    );
    localStorage.setItem('currentusermail', this.loginform.email);
    localStorage.setItem('loggedUser', 'true');

  }

  handleResponse(data) {
    this.token.handle(data.access_token);
    this.Auth.changeAuthStatus(true);
    this.router.navigateByUrl('/gaj/accueil');
    this.closebutton.nativeElement.click();
  }

  handleError(error) {
    this.error = error.error.error;
  }


}
