<div class="card col-lg-10 col-md-11 col-sm-12 my-5 mx-auto bg-white p-0">

	<div>
		<img src="{{filepath}}/publicationsimgs/{{publication?.image}}" alt="" style="width: 100%">
	</div>

	<div class="card-body">
		{{publication?.description}}
	</div>

</div>
