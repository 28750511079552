<div class="row m-0 cycle-item border col-12 d-flex justify-content-between">
	<div class="cycle-name" routerLink="/gaj/cycles/{{cycle?.cycle_id}}">
		{{cycle?.name}}
	</div>
	<div>
		<div class="dropdown">
		  <button class="btn btn-gaj cycle-option-btn m-0" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
		    <i class="fas fa-ellipsis-v"></i>
		  </button>
		  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="role == 'admin'">
		    <a class="dropdown-item py-0 text-primary"  data-toggle="modal" href="#editCycle{{cycle?.id}}"><i class="fas fa-edit"></i> Modifier</a>
		    <button class="dropdown-item py-0 text-danger" (click)="Delete()"><i class="fas fa-trash-alt"></i> Supprimer</button>
		    <!--<a class="dropdown-item py-0 text-secondary" href="#"><i class="fas fa-eye-slash"></i> Caché</a>-->
		  </div>
		  <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" *ngIf="role != 'admin'">
		  	<a class="dropdown-item py-0 text-primary"  data-toggle="modal" href="#viewCycle{{cycle?.id}}"><i class="fas fa-eye"></i> En Savoir Plus</a>
		  </div>
		</div>
	</div>
</div>

<div class="modal fade" id="editCycle{{cycle?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
      	<h5 class="modal-title" id="exampleModalLabel">Modifier {{cycleform?.name}}</h5>
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <form (submit)="UpdateCycle()">
        	<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="name">Spécialitée <strong class="required-star">*</strong></label>
				    <input type="text" class="form-control form-control-sm" name="name"  [(ngModel)]="cycleform.name" required>
				</div>
			</div>
			<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="description">Description</label>
				    <textarea class="form-control form-control-sm" rows="2" name="description" [(ngModel)]="cycleform.description"></textarea>
				</div>
			</div>
			<div class="row mx-0 col-sm-12">
				<button type="submit" class="ml-auto btn btn-sm col-sm-4 btn-gaj">
					Modifier
				</button>
			</div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="modal fade" id="viewCycle{{cycle?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
      	<h5 class="modal-title" id="exampleModalLabel">{{cycle?.name}}</h5>
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <p *ngIf="cycle?.description != null">
        	{{cycle?.description}}
        </p>
        <div class="alert col-sm-8 mx-auto alert-primary" role="alert" *ngIf="cycle?.description == null">
		  Aucune description pour cette spécialitée!
		</div>

      </div>
    </div>
  </div>
</div>


<div id="confirmmessage{{cycle?.id}}" class="confirm-message py-1 px-3 bg-success text-center ml-auto shadow text-white" style="z-index: 1; bottom: 0; left: 0; right: 0; position: fixed; margin-bottom: 10px; margin-right: 25px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 60px; line-height: 50px;">
	<i class="fas fa-check-circle"></i> Mise à jour du cycle réussie!
</div>

<div id="deletemessage{{cycle?.id}}" class="delete-message py-1 px-3 text-danger text-center mx-auto shadow bg-white" style="z-index: 1; top: 0; left: 0; right: 0; position: fixed; margin-top: 60px; display: none; border-radius: 12px; width: auto; max-width: 300px;">
	<i class="fas fa-times"></i> Suppression a été effectuée avec succée!
</div>