<div *ngIf="role == 'admin'">
  <div class="row col-12 mx-0 px-0 justify-content-end">
    <label for="addpubbtn" class="addpublicbtn">
      <button
        type="button"
        id="addpubbtn"
        class="btn ml-auto btn-sm btn-gaj add-pub-btn"
        data-toggle="modal"
        data-target="#addPublication"
      >
        <i class="fas fa-plus"></i>
      </button>
      <span>Ajouter</span>
    </label>
  </div>
</div>

<!-- Modal -->
<div
  class="modal fade"
  id="addPublication"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button
          type="button"
          class="btn close-btn text-white"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <app-add-publication-form></app-add-publication-form>
      </div>
    </div>
  </div>
</div>

<div
  class="col-12 text-right"
  style="top: 60px; left: 0; right: 0; position: fixed"
>
  <div
    *ngIf="role != 'admin'"
    style="
      background: transparent;
      line-height: 40px;
      position: relative;
      margin-right: 10px;
    "
  >
    <span
      class="badge badge-danger"
      style="top: -4px; right: 0; position: absolute"
      *ngIf="allFavoris.length <= 9"
      >{{ allFavoris.length }}</span
    >
    <span
      class="badge badge-danger"
      style="top: -4px; right: 0; position: absolute"
      *ngIf="allFavoris.length > 9"
      >+9</span
    >
    <button
      type="button"
      class="btn btn-sm btn-light text-gaj"
      style="margin-right: 15px"
      routerLink="/gaj/favoris"
    >
      <i class="far fa-bookmark"></i>
    </button>
  </div>
</div>

<div class="col-lg-8 mx-auto bg-white col-md-10 col-sm-12 px-0">
  <div class="filterbtns d-flex px-3">
    <button
      class="btn btn-sm btn-secondary my-auto mr-2"
      (click)="filter('all')"
    >
      Tout
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2"
      (click)="filter('actuality')"
    >
      Actualité
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2"
      (click)="filter('officiel')"
    >
      Officiel
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajconsulting')"
    >
      GAJ Consulting
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajTraining')"
    >
      GAJ Training
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajScholarship')"
    >
      GAJ Scholarship
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajFablab')"
    >
      GAJ Fablab
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajMagazine')"
    >
      GAJ Magazine
    </button>
  </div>
  <div class="mx-0 my-0 py-5 pubscontent">
    <div class="mx-0 my-3" *ngFor="let publication of publications">
      <app-publication-item [publication]="publication"></app-publication-item>
    </div>
  </div>
</div>
<div
  class="alert col-md-8 mx-auto text-center alert-info mt-5"
  role="alert"
  *ngIf="publications.length == 0"
>
  Il n'y a pas encore de publication
</div>
