import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../../models/User';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-item',
  templateUrl: './student-item.component.html',
  styleUrls: ['./student-item.component.css']
})
export class StudentItemComponent implements OnInit {

  @Input() user: User;
  currentusermail: any;

  filepath = environment.filepath;

  constructor(
    private updateservice: UpdateService,
    private msg: MessageService
    ) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
    console.log(this.user);
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  blockUser($id) {
    this.updateservice.BlockUser($id, '1').subscribe(res => {
      console.log(res);
      this.msg.setMessage('new operation');
    });
  }
  unblockUser($id) {
    this.updateservice.BlockUser($id, '0').subscribe(res => {
      console.log(res);
      this.msg.setMessage('new operation');
    });
  }



}
