import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { BlogPost } from '../../../../models/BlogPost';
import { User } from '../../../../models/User';
import { UserdataService } from '../../../../services/userdata.service';
import { UpdateService } from '../../../../services/update.service';
import { DeleteService } from 'src/app/services/delete.service';
import { MessageService } from 'src/app/services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-presentationblog-item',
  templateUrl: './presentationblog-item.component.html',
  styleUrls: ['./presentationblog-item.component.css']
})
export class PresentationblogItemComponent implements OnInit {
  @ViewChild('closedeletebutton') closedeletebutton: any;
  @ViewChild('closedeleteIVbutton') closedeleteIVbutton: any;

  @Input() post: BlogPost;

  currentuser: User;
  currentmail: any;

  filepath = environment.filepath;

  id: any;
  selectedImageFile: File = null;
  selectedVideoFile: File = null;

  nbmedia: number = 0;

  description: string;

  constructor(
    private userdata: UserdataService,
    private editservice: UpdateService,
    private deleteservice: DeleteService,
    private msg: MessageService
    ) { }

  ngOnInit(): void {
  	this.currentmail = this.getMail();

    this.description = this.post.description;

    this.userdata.getUserData(this.currentmail).subscribe(data => {
      this.currentuser = data[0];
    });
    this.id = this.post.id;
    if(this.post.image != null) {
      this.nbmedia++;
    }
    if(this.post.video != null) {
      this.nbmedia++;
    }
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }


  EditBlogPost() {
    this.editservice.updateBlogPost(this.post).subscribe(data => {
    });
  }

  deleteBlogPost(id) {
    this.deleteservice.deleteData('blog_posts', id).subscribe(res => {
      this.msg.setMessage("new operation");
      this.closedeletebutton.nativeElement.click();
    });
  }

  DeleteImg(id) {
    this.editservice.deleteBlogPostImg(id).subscribe(res => {
      this.msg.setMessage("new operation");
      this.closedeleteIVbutton.nativeElement.click();
    });
  }

  DeleteVid(id) {
    this.editservice.deleteBlogPostVideo(id).subscribe(res => {
      this.msg.setMessage("new operation");
      this.closedeleteIVbutton.nativeElement.click();
    });
  }

  editImage(id, event) {
    this.selectedImageFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('id', id);
    fd.append('image', this.selectedImageFile);

    this.editservice.updateBlogPostPic(fd).subscribe(res => {
      this.msg.setMessage("new operation");
      this.closedeleteIVbutton.nativeElement.click();
    });
  }

  editVideo(id, event) {
    this.selectedVideoFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('id', id);
    fd.append('video', this.selectedVideoFile);

    this.editservice.updateBlogPostVideo(fd).subscribe(res => {
      this.msg.setMessage("new operation");
      this.closedeleteIVbutton.nativeElement.click();
    });
  }


}
