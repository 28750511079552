<div style="position: relative;" *ngIf="file?.file != null">
  <div style="top: 4px; left: 8px; position: absolute;" *ngIf="role == 'admin'">
    <!--<button class="btn btn-sm btn-primary mr-1"><i class="fas fa-pen"></i></button>-->
    <button class="btn btn-sm btn-danger" (click)="DeletePicOrVideo(file?.id)"><i class="fas fa-times"></i></button>
  </div>
  <a data-toggle="modal" href="#showpubimgaux{{file.id}}" *ngIf="file?.file_type == 'img'">
    <img *ngIf="file?.file_type == 'img' && file?.file != null" class="mx-1" src="{{filepath}}/publicationsimgs/{{file?.file}}" style="height: 140px;">
  </a>
</div>

<div style="position: relative;" *ngIf="file?.file != null">
  <div style="top: 4px; left: 8px; position: absolute; z-index: 10000;" *ngIf="role == 'admin'">
    <!--<button class="btn btn-sm btn-primary mr-1"><i class="fas fa-pen"></i></button>-->
    <button class="btn btn-sm btn-danger" (click)="DeletePicOrVideo(file?.id)"><i class="fas fa-times"></i></button>
  </div>
  <a data-toggle="modal" href="#showvideoaux{{file.id}}">
    <video src="{{filepath}}/publicationsvideos/{{file?.file}}"  *ngIf="file?.file_type == 'video' && file?.file != null" class="mx-1" controls style="height: 140px;">
      Sorry, your browser doesn't support embedded videos.
    </video>
  </a>
</div>



<!-- Show the init image Modal -->
<div class="modal fade" id="showpubimgaux{{file?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body" style="padding: 0; width: auto; background: transparent;">
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <img src="{{filepath}}/publicationsimgs/{{file?.file}}" style="width: 100%;" *ngIf="file?.file != null && file?.file_type == 'img'">

      </div>
    </div>
  </div>
</div>
<!-- Show the init video Modal -->
<div class="modal fade" id="showvideoaux{{file?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body p-0" style="padding: 0; width: auto; background: transparent;">
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <video src="{{filepath}}/publicationsvideos/{{file?.file}}" controls style="width: 100%; height: auto; margin-bottom: -20px;" *ngIf="file?.file != null && file?.file_type == 'video'">
          Sorry, your browser doesn't support embedded videos.
        </video>

      </div>
    </div>
  </div>
</div>
