import { Component, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { UserdataService } from '../../services/userdata.service';
import { SelectService } from '../../services/select.service';
import { MessageService } from '../../services/message.service';
import { Inscription } from '../../models/Inscription';

@Component({
  selector: 'app-suivi-demarches',
  templateUrl: './suivi-demarches.component.html',
  styleUrls: ['./suivi-demarches.component.css']
})
export class SuiviDemarchesComponent implements OnInit {
  
  user: User;
  role: any;
  currentusermail: any;

  i: any = 0;

  j: any = 0;
  k: any = 0;
  l: any = 0;

  inscriptions: Inscription[] = [];

  encours: Inscription[] = [];
  approved: Inscription[] = [];
  refused: Inscription[] = [];

  allinscriptions: Inscription[] = [];

  allencours: Inscription[] = [];
  allapproved: Inscription[] = [];
  allrefused: Inscription[] = [];

  constructor(
  	private userdataservice: UserdataService,
  	private select: SelectService,
    private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  		//this.role = this.user.role; 
  	});

    this.select.getAll('inscriptions').subscribe(res => {
      this.allinscriptions = res.reverse();

      for(this.i = 0; this.i < this.allinscriptions.length; this.i++) {
          if(this.allinscriptions[this.i].status == '0') {
            this.allencours[this.j] = this.allinscriptions[this.i];
            this.j++;
          } else if(this.allinscriptions[this.i].status == '1') {
            this.allapproved[this.k] = this.allinscriptions[this.i];
            this.k++;
          } else if(this.allinscriptions[this.i].status == '2') {
            this.allrefused[this.l] = this.allinscriptions[this.i];
            this.l++;
          }
        }
    });

    this.msg.getMessage().subscribe(ins => {
      this.select.getAll('inscriptions').subscribe(res => {
        this.allinscriptions = res.reverse();

        this.allencours=[];
        this.allapproved=[];
        this.allrefused=[];
        this.j=0;
        this.k=0;
        this.l=0;

        for(this.i = 0; this.i < this.allinscriptions.length; this.i++) {
            if(this.allinscriptions[this.i].status == '0') {
              this.allencours[this.j] = this.allinscriptions[this.i];
              this.j++;
            } else if(this.allinscriptions[this.i].status == '1') {
              this.allapproved[this.k] = this.allinscriptions[this.i];
              this.k++;
            } else if(this.allinscriptions[this.i].status == '2') {
              this.allrefused[this.l] = this.allinscriptions[this.i];
              this.l++;
            }
          }
      });
    });

  	this.select.searchInscrit(this.currentusermail).subscribe(res => {
  			this.inscriptions = res.reverse();
  			for(this.i = 0; this.i < this.inscriptions.length; this.i++) {
  				if(this.inscriptions[this.i].status == '0') {
  					this.encours[this.j] = this.inscriptions[this.i];
  					this.j++;
  				} else if(this.inscriptions[this.i].status == '1') {
  					this.approved[this.k] = this.inscriptions[this.i];
  					this.k++;
  				} else if(this.inscriptions[this.i].status == '2') {
  					this.refused[this.l] = this.inscriptions[this.i];
  					this.l++;
  				}
  			}
  		});
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

}
