import { Component, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { SelectService } from '../../services/select.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.css']
})
export class StudentsComponent implements OnInit {
  
  users: User[] = [];
  currentusermail: any;

  constructor(
  	private select: SelectService,
    private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	
  	this.select.getAll('users').subscribe(res => {
  		this.users = res;
  		console.log(this.users);
  	});

    this.msg.getMessage().subscribe(res => {
      this.select.getAll('users').subscribe(res => {
        this.users = res;
        console.log(this.users);
      });
    })
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

}
