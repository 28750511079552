import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/User';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class UserdataService {

  server: string = environment.api;

  constructor(private http: HttpClient) {}

  getUserData(email): Observable<User> {
   	return this.http.get<User>(this.server + '/getuserdata/'+email);
  }

  updateprofile(id, user_id, avatar, fname, lname, email, role, usertype, password, blocked, phone, passport, nationality, residencecountry, naissance, token) {
    const newUser = new User(id, user_id, avatar, fname, lname, email, role, usertype, password, blocked, phone, passport, nationality, residencecountry, naissance, token);

    return this.http.post<User>(this.server + '/updateprofile', newUser);
  }
  generateUID(id, user_id, avatar, fname, lname, email, role, usertype, password, blocked, phone, passport, nationality, residencecountry, naissance, token) {
    const newUser = new User(id, user_id, avatar, fname, lname, email, role, usertype, password, blocked, phone, passport, nationality, residencecountry, naissance, token);

    return this.http.post<User>(this.server + '/generateuserid', newUser);
  }

  getUserDataByUserId(id): Observable<User> {
    return this.http.get<User>(this.server + '/getUserDataByUserId/'+id);
  }
}
