import { Component, OnInit } from '@angular/core';
import { User } from '../../models/User';
import { UserdataService } from '../../services/userdata.service';
import { SelectService } from '../../services/select.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  
  user: User;
  role: any;
  currentusermail: any;

  constructor(
  	private userdataservice: UserdataService,
  	private select: SelectService,
    private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  		//this.role = this.user.role; 
  	});
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

}
