<div class="container-fluid pb-5">
	<h5 class="card-title mt-3 ml-3">
		Mes Fichiers
	</h5>
	<hr>

	<div class="row col-12 mx-3 d-flex adddocrow">
		<span class="label-title"><strong>Passport: </strong></span>
		<div class="form-group p-0" *ngIf="passport.length == 0">
			<label class="btn btn-sm text-gaj btn-light" for="passportinputfile"><i class="fas fa-plus"></i></label>
			<input type="file" class="form-control-file" id="passportinputfile" name="realname" (change)="onFileSelected($event, 'passport')" required>
		</div>
	</div>


	<div class="col-12" style="display: flex; flex-wrap: wrap;">
		<div class="col-md-6 mb-2" style="overflow: hidden;" *ngFor="let document of passport">
			<app-file-item [document]="document"></app-file-item>
		</div>
	</div>


	<div class="row col-12 mx-3 mt-3 d-flex adddocrow">
		<span class="label-title"><strong>Diplômes: </strong></span>
		<div class="form-group p-0">
			<label class="btn btn-sm text-gaj btn-light" for="diplomeinputfile"><i class="fas fa-plus"></i></label>
			<input type="file" class="form-control-file" id="diplomeinputfile" name="realname" (change)="onFileSelected($event, 'diplome')" required>
		</div>
	</div>
	<div class="col-12" style="display: flex; flex-wrap: wrap;">
		<div class="col-md-6 mb-2" style="overflow: hidden;" *ngFor="let document of diplome">
			<app-file-item [document]="document"></app-file-item>
		</div>
	</div>

	<div class="row col-12 mx-3 mt-3 d-flex adddocrow">
		<span class="label-title"><strong>Attestation Baccalauréat: </strong></span>
		<div class="form-group p-0" *ngIf="bac.length == 0">
			<label class="btn btn-sm text-gaj btn-light" for="attestbacinputfile"><i class="fas fa-plus"></i></label>
			<input type="file" class="form-control-file" id="attestbacinputfile" name="realname" (change)="onFileSelected($event, 'attestbac')" required>
		</div>
	</div>
	<div class="col-12" style="display: flex; flex-wrap: wrap;">
		<div class="col-md-6 mb-2" style="overflow: hidden;" *ngFor="let document of bac">
			<app-file-item [document]="document"></app-file-item>
		</div>
	</div>

	<div class="row col-12 mx-3 mt-3 d-flex adddocrow">
		<span class="label-title"><strong>Relevés de notes: </strong></span>
		<div class="form-group p-0">
			<label class="btn btn-sm text-gaj btn-light" for="releveeinputfile"><i class="fas fa-plus"></i></label>
			<input type="file" class="form-control-file" id="releveeinputfile" name="realname" (change)="onFileSelected($event, 'relevenotes')" required>
		</div>
	</div>
	<div class="col-12" style="display: flex; flex-wrap: wrap;">
		<div class="col-md-6 mb-2" style="overflow: hidden;" *ngFor="let document of relevees">
			<app-file-item [document]="document"></app-file-item>
		</div>
	</div>
</div>

<!-- Modal -->
<!--<div class="modal fade" id="addfilemodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <app-add-file-form></app-add-file-form>
      </div>
    </div>
  </div>
</div>-->




<div id="wrongfileextention" class="confirm-message py-1 px-3 bg-danger text-center mr-auto shadow text-white" style="z-index: 10000; bottom: 0; left: 0; position: fixed; margin-bottom: 6px; margin-left: 6px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 50px; line-height: 20px; vertical-align: middle;">
	<i class="fas fa-times-circle" style="top: 0; left: 0; bottom: 0; position: absolute; font-size: 24px; margin: 10px 15px;"></i> s'il vous plaît sélectionner seulement <strong>pdf</strong> file.
</div>

<div id="fileadded" class="confirm-message py-1 px-3 bg-success text-center mr-auto shadow text-white" style="z-index: 10000; bottom: 0; left: 0; position: fixed; margin-bottom: 6px; margin-left: 6px; display: none; border-radius: 4px; width: auto; max-width: 350px; height: 50px; line-height: 40px; vertical-align: middle; padding-left: 22px;">
	<i class="fas fa-check" style="top: 0; left: 0; bottom: 0; position: absolute; font-size: 20px; margin: 14px 15px;"></i> <span style="margin-left: 28px;">Document ajouter avec succée!</span>
</div>