import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Actuality } from '../../../models/Actuality';
import { UserdataService } from '../../../services/userdata.service';
import { UpdateService } from '../../../services/update.service';
import { DeleteService } from '../../../services/delete.service';
import { User } from '../../../models/User';
import { Message1Service } from '../../../services/message1.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-actuality-item',
  templateUrl: './actuality-item.component.html',
  styleUrls: ['./actuality-item.component.css']
})
export class ActualityItemComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closedeleteactbutton') closedeleteactbutton: any;

  @Input() actuality: Actuality;

  currentuser: User;
  currentemail: any;
  role: any;

  filepath = environment.filepath;

  updateresponse: any;
  deleteresponse: any;

  selectedImageFile: File = null;

  public actform = {
  	id: null,
  	email: null,
    title: null,
    description: null,
    show: null
  };

  constructor(
  	private userdataservice: UserdataService,
  	private updateservice: UpdateService,
  	private deleteservice: DeleteService,
  	private msg1: Message1Service
  	) { }

  ngOnInit(): void {
  	this.actform.id = this.actuality.id;
  	this.actform.email = this.actuality.email;
  	this.actform.title = this.actuality.title;
  	this.actform.description = this.actuality.description;
  	this.actform.show = this.actuality.show;

  	this.currentemail = localStorage.getItem('currentusermail');
  	this.userdataservice.getUserData(this.currentemail).subscribe((data) => {
  		this.currentuser = data[0];
  		this.role = this.currentuser.role;
  	});
  }

  updateactuality() {
  	this.updateservice.updateActuality('actualities', this.actform).subscribe(res => {
  		this.updateresponse = res.message;
      this.msg1.setMessage("new operation");


      var aux = 'pubupdatesuccess'+this.actuality.id;


      setTimeout(function() {
        document.getElementById(aux).style.display = "block";
      }, 1000);
      this.closebutton.nativeElement.click();

  		setTimeout(function() {
			document.getElementById(aux).style.display = "none";
  		},8000);

  	});
  }

  Delete() {
    this.deleteservice.deleteData('actualities', this.actuality.id).subscribe((res) => {
      this.msg1.setMessage("new operation");
      this.deleteresponse = res.message;
      this.closedeleteactbutton.nativeElement.click();
    });
  }

  editActPic(id, event) {
    this.selectedImageFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('id', id);
    fd.append('image', this.selectedImageFile);

    this.updateservice.updateActualityPic(fd).subscribe(res => {
      this.msg1.setMessage("new operation");
    });
  }

}
