<div *ngIf="user?.role == 'admin'">
	<div class="col-lg-8 col-md-10 col-sm-12 mx-auto p-3 my-5 bg-white border" style="min-height: 120px">
		<h5 *ngIf="terms.length == 0" class="card-title">Ajouter les termes et les conditions d'utilisation</h5>
		<h5 *ngIf="terms.length != 0" class="card-title">Fichier des termes et des conditions d'utilisation</h5>
		<hr>

		<label for="temrsFichier" id="termslabel" class="text-center pt-4" *ngIf="terms.length == 0">
			<div class="col-12 mx-auto my-2">
				<i class="fas fa-2x fa-upload mb-3"></i><br><br>
				<h5>Glisser et Déposer votre fichier ici</h5>
				<h5>Ou</h5>


			</div>
			<input type="file" name="name" id="temrsFichier" (change)="onFileSelected($event)">
			<label class="btn btn-block btn-gaj labelbottom"> Sélectionner le fichier des termes</label>
		</label>



		<ul class="list-group">
		  <li class="list-group-item justify-content-between d-flex py-1">
		  	<a target="_blank" class="my-auto" href="{{filepath}}/TermsFiles/{{firsttermsfile?.termsfile}}">{{firsttermsfile?.termsfile}}</a>
		  	<div>
		  		<!--<button class="btn btn-primary my-0 mr-1"><i class="fas fa-edit"></i></button>-->
		  		<button class="btn btn-light text-danger my-0" (click)="delete(firsttermsfile?.id)"><i class="fas fa-times"></i></button>
		  	</div>
		  </li>
		</ul>


	</div>
</div>

<div *ngIf="user?.role != 'admin'">
	<div class="col-lg-8 col-md-10 col-sm-12 mx-auto p-3 my-5 bg-white border" style="min-height: 120px">
		<h5 class="card-title">
			Les termes et les conditions d'utilisation
		</h5>
		<ul class="list-group">
		  <li class="list-group-item py-1">
		  	<a target="_blank" class="my-auto" href="{{filepath}}/TermsFiles/{{firsttermsfile?.termsfile}}">{{firsttermsfile?.termsfile}}</a>
		  </li>
		</ul>
	</div>
</div>



<div id="confirmAddTerms" class="confirm-message py-1 px-3 bg-success text-center ml-auto shadow text-white" style="z-index: 1; bottom: 0; left: 0; right: 0; position: fixed; margin-bottom: 70px; margin-right: 25px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 60px; line-height: 50px;">
	<i class="fas fa-check-circle"></i> Termes et Conditions a été ajouté avec succés!
</div>

<div id="wrongTermsextention" class="confirm-message py-1 px-3 bg-danger text-center mr-auto shadow text-white" style="z-index: 10000; bottom: 0; left: 0; position: fixed; margin-bottom: 6px; margin-left: 6px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 50px; line-height: 20px; vertical-align: middle;">
	<i class="fas fa-times-circle" style="top: 0; left: 0; bottom: 0; position: absolute; font-size: 24px; margin: 10px 15px;"></i> s'il vous plaît sélectionner seulement <strong>pdf</strong> file.
</div>
