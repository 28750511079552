import { Component, OnInit, Injectable, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';
import * as firebase from 'firebase';

@Component({
  selector: 'app-not-auth-header',
  templateUrl: './not-auth-header.component.html',
  styleUrls: ['./not-auth-header.component.css']
})
export class NotAuthHeaderComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  form: FormGroup;
  public emailerror = [];
  public passworderror = [];

  public loginform = {
    email: null,
    password: null
  };

  public error = null;

  constructor(
    private authentication: AuthenticationService,
    private token: TokenService,
  	private formBuilder: FormBuilder,
  	private http: HttpClient,
    private router: Router,
    private Auth: AuthService
  	) { }

  ngOnInit(): void {
  	this.form = this.formBuilder.group({
  		avatar: '',
  		fname: '',
  		lname: '',
  		email: '',
  		role: 'user',
      usertype: 'etudiant',
  		password: '',
      password_confirmation: '',
  		status: 2,
  		phone: '',
  		passport: '',
  		nationality: '',
  		residencecountry: '',
  		token: ''
  	});
  }

  onSignup(): void {
    localStorage.setItem('currentusermail', this.form.getRawValue().email);

    this.authentication.register(this.form.getRawValue()).subscribe(
      data => {
        this.handleRegisterResponse(data);

        const email = this.form.getRawValue().email;
        const password = this.form.getRawValue().password;

        firebase.auth().createUserWithEmailAndPassword(email, password);
      }
    );
  }

  handleRegisterResponse(data) {
    this.token.handle(data.access_token);
    localStorage.setItem('loggedUser', 'true');
    this.closebutton.nativeElement.click();
    this.router.navigateByUrl('/gaj/accueil');
  }

  handleRegisterError(error) {
    this.error = error.error.errors;
  }


  onSubmit() {
    this.authentication.login(this.loginform).subscribe(
      data => {
        this.handleResponse(data);
        firebase.auth().onAuthStateChanged(user => {
          if(user) {
            firebase.auth().signInWithEmailAndPassword(this.loginform.email, this.loginform.password)
          } else {
            const email = this.form.getRawValue().email;
            const password = this.form.getRawValue().password;

            firebase.auth().createUserWithEmailAndPassword(email, password);
          }
        });
      },
      error => this.handleError(error)
    );
    localStorage.setItem('currentusermail', this.loginform.email);
    localStorage.setItem('loggedUser', 'true');


  }

  handleResponse(data) {
    this.token.handle(data.access_token);
    this.Auth.changeAuthStatus(true);
    this.router.navigateByUrl('/gaj/accueil');
    this.closebutton.nativeElement.click();
  }

  handleError(error) {
    this.error = error.error.error;
  }

}
