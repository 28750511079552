import { Component, OnInit, ViewChild } from '@angular/core';
import { UserdataService } from '../../services/userdata.service';
import { User } from '../../models/User';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';
import { Message3Service } from '../../services/message3.service';
import { SelectService } from 'src/app/services/select.service';
import { Message } from 'src/app/models/Message';
import { HttpClient } from '@angular/common/http';
import { AddService } from '../../services/add.service';
import { DeleteService } from '../../services/delete.service';

import { ActivatedRoute } from '@angular/router';
import { Notif } from '../../models/Notif';

import * as firebase from 'firebase';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-header',
  templateUrl: './auth-header.component.html',
  styleUrls: ['./auth-header.component.css']
})
export class AuthHeaderComponent implements OnInit {
  @ViewChild('selectFileMessageModal') selectFileMessageModal: any;

  db = firebase.database();

  filepath = environment.filepath;

  user: User;
  allNotifs: Notif[] = [];
  notreadnotif: number = 0;
  i = 0;

  msgUserList: any[] = [];
  userMessagesAdmin: Message[] = [];

  allMsg: Message[] = [];


  currentusermail: any;

  toggle = false;
  renstoggle= false;
  formSubmitted: boolean = false;

  public message = {
    sendermail: '',
    senderid: '',
    receiverid: '',
    text: '',
    file: '',
    fileformat: '',
    created_at: '',
    seen: false
  };

  msgrid: any;
  pollingUM: any;
  pollingmsgr: any;
  pollingnotif: any;

  selectedFile: File = null;

  allMessages: any[] = [];
  uxMessages: any[] = [];


  constructor(
    private route: ActivatedRoute,
    private userdataservice: UserdataService,
    private Auth: AuthService,
    private router: Router,
    private token: TokenService,
    private msg: Message3Service,
    private selectservice: SelectService,
    private http: HttpClient,
    private add: AddService,
    private deleteservice: DeleteService
    ) {
  }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
    this.selectservice.getNotifs(this.currentusermail).subscribe(res => {
      this.allNotifs = res.reverse();

      for(this.i=0; this.i < this.allNotifs.length; this.i++) {
        if(this.allNotifs[this.i].status == '0') {
          this.notreadnotif++;
        }
      }
    });

  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
      this.message.sendermail = this.currentusermail;
      this.message.senderid = this.user.userid;
      this.message.receiverid = 'admin_id';
      this.msgrid = this.user.userid;
      this.getMessages(this.user.userid);
  	});

    this.msg.getMessage().subscribe((pjct) => {
      this.userdataservice.getUserData(this.currentusermail).subscribe((all) => {
        this.user = all[0];
      });
    });

    this.getAllMessengerListIds();

  this.consultnotif();
  this.pollDatanotif();
  }

  async getAllMessengerListIds() {
    const dbRef = firebase.database().ref('messages/');
    await dbRef.on('value', (value) => {
      if(value.exists()) {
        this.msgUserList = [];
        for(let key in value.val()){
          this.msgUserList.push(key);
        }
      }
    });
  }

  async getMessages(messengerId) {
    const dbRef = firebase.database().ref('messages/'+messengerId);

    await dbRef.on('value', (value) => {
      //const data = value.val();
      if(value.exists()) {
        this.allMessages = [];
        for(let key in value.val()){
          this.allMessages.push(value.val()[key]);
        }
      }
      this.uxMessages = this.allMessages.reverse();
    });
    this.getAllMessengerListIds();
  }

  addTextMessage() {
    this.message.created_at = (new Date()).toString();
    firebase.database().ref('messages/' + this.user.userid+'').push({
      senderid: this.message.senderid,
      receiverid: this.message.receiverid,
      msg: this.message.text,
      created_at: this.message.created_at,
      seen: this.message.seen
    });
    this.message.text = '';
  }


  getMail() {
    return localStorage.getItem('currentusermail');
  }

  logout(event: MouseEvent) {
    event.preventDefault();
    this.token.removeToken();
    this.Auth.changeAuthStatus(false);
    this.router.navigateByUrl('/');

    localStorage.removeItem('currentusermail');
    localStorage.removeItem('loggedUser');
  }

  openmsgbox() {
    if(this.toggle == false) {
      document.getElementById('msglistadmin').style.width = "249px";
      this.toggle = true;
    } else {
      document.getElementById('msglistadmin').style.width = "0";
      this.toggle = false;
    }
  }


  togglerenseignement() {
    if(this.renstoggle == false) {
      //document.getElementById('renseignementboxlink').style.height = 'auto';
      document.getElementById('renseignementboxlink').style.minHeight = '252px';
      this.renstoggle = true;
    } else {
      //document.getElementById('renseignementboxlink').style.height = '0';
      document.getElementById('renseignementboxlink').style.minHeight = '0';
      this.renstoggle = false;
    }
  }

  pollDatanotif() {
    this.pollingnotif = setInterval(() => {
      this.consultnotif();
    }, 300000);
  }

  consultnotif() {

    this.selectservice.getNotifs(this.currentusermail).subscribe(res => {
      if(this.allNotifs.length != res.length) {
        this.notreadnotif = 0;
        this.allNotifs = res.reverse();

        for(this.i=0; this.i < this.allNotifs.length; this.i++) {
          if(this.allNotifs[this.i].status == '0') {
            this.notreadnotif++;
          }
        }
      }
    });
  }

  ngOnDestroy() {
    clearInterval(this.pollingUM);
    clearInterval(this.pollingmsgr);
    clearInterval(this.pollingnotif);
  }

  delete($id) {
    this.deleteservice.deleteData('messages', $id).subscribe(res => {
      this.msg.setMessage('new operation');
    });
  }


  selectImage(event){
    this.selectedFile = event.target.files[0];
  }

  addImgMsg() {
    let metadata = {
      contentType: 'image/*'
    };

    const storage = firebase.storage();

    const storageRef = storage.ref();
    const uploadTask = storageRef.child('/messagesImages/'+this.selectedFile.name).put(this.selectedFile, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is '+progress+'% done');
      switch(snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log('unknown error');
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at : ',downloadURL);

        this.message.created_at = (new Date()).toString();
        firebase.database().ref('messages/' + this.user.userid+'').push({
          senderid: this.message.senderid,
          receiverid: this.message.receiverid,
          msg: '',
          created_at: this.message.created_at,
          fileType: 'img',
          file: downloadURL,
          seen: this.message.seen
        });
        this.selectFileMessageModal.nativeElement.click();
      });
    });
  }

  selectVideo(event){
    this.selectedFile = event.target.files[0];
  }

  addVideoMsg() {
    let metadata = {
      contentType: 'video/*'
    };

    const storage = firebase.storage();

    const storageRef = storage.ref();
    const uploadTask = storageRef.child('/messagesVideos/'+this.selectedFile.name).put(this.selectedFile, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is '+progress+'% done');
      switch(snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log('unknown error');
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at : ',downloadURL);

        this.message.created_at = (new Date()).toString();
        firebase.database().ref('messages/' + this.user.userid+'').push({
          senderid: this.message.senderid,
          receiverid: this.message.receiverid,
          msg: '',
          created_at: this.message.created_at,
          fileType: 'video',
          file: downloadURL,
          seen: this.message.seen
        });
        this.selectFileMessageModal.nativeElement.click();
      });
    });
  }

  selectDoc(event){
    this.selectedFile = event.target.files[0];
  }

  addDocMsg() {
    let metadata = {
      contentType: '*'
    };

    const storage = firebase.storage();

    const storageRef = storage.ref();
    const uploadTask = storageRef.child('/messagesDocs/'+this.selectedFile.name).put(this.selectedFile, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is '+progress+'% done');
      switch(snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log('unknown error');
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at : ',downloadURL);

        this.message.created_at = (Date.now()).toString();
        firebase.database().ref('messages/' + this.user.userid+'').push({
          senderid: this.message.senderid,
          receiverid: this.message.receiverid,
          msg: '',
          created_at: this.message.created_at,
          fileType: 'doc',
          file: downloadURL,
          seen: this.message.seen
        });
        this.selectFileMessageModal.nativeElement.click();
      });
    });
  }




























  addVidepMsg(event) {
    this.selectedFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('sendermail', this.message.sendermail);
    fd.append('senderid', this.message.senderid);
    fd.append('receiverid', this.message.receiverid);
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('fileformat', 'video');

    this.http.post(environment.api+'/addFileMsg', fd).subscribe(res => {
      this.msg.setMessage('new img added');
    });

  }
  addAudioMsg(event) {
    this.selectedFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('sendermail', this.message.sendermail);
    fd.append('senderid', this.message.senderid);
    fd.append('receiverid', this.message.receiverid);
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('fileformat', 'audio');

    this.http.post(environment.api+'/addFileMsg', fd).subscribe(res => {
      this.msg.setMessage('new img added');
    });

  }

  addOtherFileMsg(event) {
    this.selectedFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('sendermail', this.message.sendermail);
    fd.append('senderid', this.message.senderid);
    fd.append('receiverid', this.message.receiverid);
    fd.append('file', this.selectedFile, this.selectedFile.name);
    fd.append('fileformat', 'otherfile');

    this.http.post(environment.api+'/addFileMsg', fd).subscribe(res => {
      this.msg.setMessage('new img added');
    });

  }

}
