import { Component, OnInit, ViewChild } from '@angular/core';
import { UserdataService } from '../../services/userdata.service';
import { User } from '../../models/User';
import { Router } from '@angular/router';
import { TokenService } from 'src/app/services/token.service';
import { AuthService } from 'src/app/services/auth.service';
import { MessageService } from '../../services/message.service';
import { SelectService } from 'src/app/services/select.service';
import { Message } from 'src/app/models/Message';
import { HttpClient } from '@angular/common/http';
import { AddService } from '../../services/add.service';
import { DeleteService } from '../../services/delete.service';

import { ActivatedRoute } from '@angular/router';

import * as firebase from 'firebase';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
  @ViewChild('selectFileMessageModal') selectFileMessageModal: any;

  db = firebase.database();

  user: User;

  selectedFile: File = null;

  currentusermail: any;

  public message = {
    sendermail: '',
    senderid: '',
    receiverid: '',
    text: '',
    file: '',
    fileformat: '',
    created_at: '',
    seen: false
  };

  pollingAM: any;
  currentmessengerid: '';

  adminselectedFile: File = null;


  allMessages: any[] = [];
  uxMessages: any[] = [];

  constructor(
    private route: ActivatedRoute,
  	private userdataservice: UserdataService,
    private msg: MessageService,
    private add: AddService,
    private deleteservice: DeleteService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
      this.message.sendermail = this.currentusermail;
      this.message.senderid = this.user.userid;
  	});

    this.msg.getMessage().subscribe((pjct) => {
      this.userdataservice.getUserData(this.currentusermail).subscribe((all) => {
        this.user = all[0];
      });
    });

    this.route.params.subscribe((data) => {
      this.currentmessengerid = data.id;
      this.getMessages(this.currentmessengerid);
      this.message.receiverid = data.id;
    });

    this.msg.getMessage().subscribe(res => {
      this.route.params.subscribe((data) => {
        this.currentmessengerid = data.id;
      });
    });


  }

  async getMessages(messengerId) {
    const dbRef = firebase.database().ref('messages/'+messengerId);

    await dbRef.on('value', (value) => {
      //const data = value.val();
      if(value.exists()) {
        this.allMessages = [];
        for(let key in value.val()){
          //console.log("data[key]",data[key]);
          //console.log("key",key);
          this.allMessages.push(value.val()[key]);
          //console.log("intVal",this.intVal);
        }
      } else {
        console.log("No Messages available");
      }
      this.uxMessages = this.allMessages.reverse();
    });

  }

  addTextMessage() {
    this.message.created_at = (new Date()).toString();
    firebase.database().ref('messages/' + this.message.receiverid+'').push({
      senderid: this.message.senderid,
      receiverid: this.message.receiverid,
      msg: this.message.text,
      created_at: this.message.created_at,
      seen: this.message.seen
    });
    this.message.text = '';
    //console.log(new Date());
  }

  async markAsSeenMessages() {
    /*const dbRef = firebase.database().ref('messages/'+this.currentmessengerid);
    await dbRef.on('value', (value) => {
      if(value.exists()) {
        this.allMessages = [];
        for(let key in value.val()){
          this.allMessages.push(value.val()[key]);
        }
      } else {
        console.log("No Messages available");
      }
      this.allMessages.forEach((msg) => {
        if(msg.senderid === this.currentmessengerid) {
          if(msg.seen == false) {
            msg.seen = true
          }
        } else {
          console.log('nothing here');
        }
      });
    });
    firebase.database().ref('messages/'+this.currentmessengerid).set([]);
    //firebase.database().ref('messages/'+this.currentmessengerid).push(this.allMessages);
    //return firebase.database().ref('messages/'+this.currentmessengerid).update(this.allMessages);

    this.allMessages.forEach((x) => {
      firebase.database().ref('messages/' + this.message.receiverid+'').push({
        senderid: x.senderid,
        receiverid: x.receiverid,
        msg: x.msg,
        created_at: x.created_at,
        seen: x.seen
      });
    })*/
  }


  selectImage(event){
    this.selectedFile = event.target.files[0];
  }

  addImgMsg() {
    let metadata = {
      contentType: 'image/*'
    };

    const storage = firebase.storage();

    const storageRef = storage.ref();
    const uploadTask = storageRef.child('/messagesImages/'+this.selectedFile.name).put(this.selectedFile, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is '+progress+'% done');
      switch(snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log('unknown error');
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at : ',downloadURL);

        this.message.created_at = (new Date()).toString();
        firebase.database().ref('messages/' + this.message.receiverid).push({
          senderid: this.message.senderid,
          receiverid: this.message.receiverid,
          msg: '',
          created_at: this.message.created_at,
          fileType: 'img',
          file: downloadURL,
          seen: this.message.seen
        });
        this.selectFileMessageModal.nativeElement.click();
      });
    });
  }


  selectVideo(event){
    this.selectedFile = event.target.files[0];
  }

  addVideoMsg() {
    let metadata = {
      contentType: 'video/*'
    };

    const storage = firebase.storage();

    const storageRef = storage.ref();
    const uploadTask = storageRef.child('/messagesVideos/'+this.selectedFile.name).put(this.selectedFile, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is '+progress+'% done');
      switch(snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log('unknown error');
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at : ',downloadURL);

        this.message.created_at = (new Date()).toString();
        firebase.database().ref('messages/' + this.message.receiverid).push({
          senderid: this.message.senderid,
          receiverid: this.message.receiverid,
          msg: '',
          created_at: this.message.created_at,
          fileType: 'video',
          file: downloadURL,
          seen: this.message.seen
        });
        this.selectFileMessageModal.nativeElement.click();
      });
    });
  }



  selectDoc(event){
    this.selectedFile = event.target.files[0];
  }

  addDocMsg() {
    let metadata = {
      contentType: '*'
    };

    const storage = firebase.storage();

    const storageRef = storage.ref();
    const uploadTask = storageRef.child('/messagesDocs/'+this.selectedFile.name).put(this.selectedFile, metadata);

    uploadTask.on(firebase.storage.TaskEvent.STATE_CHANGED, (snapshot) => {
      let progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      console.log('Upload is '+progress+'% done');
      switch(snapshot.state) {
        case firebase.storage.TaskState.PAUSED:
          console.log('Upload is paused');
          break;
        case firebase.storage.TaskState.RUNNING:
          console.log('Upload is running');
          break;
      }
    },
    (error) => {
      console.log('unknown error');
    },
    () => {
      uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
        console.log('File available at : ',downloadURL);

        this.message.created_at = (Date.now()).toString();
        firebase.database().ref('messages/' + this.message.receiverid).push({
          senderid: this.message.senderid,
          receiverid: this.message.receiverid,
          msg: '',
          created_at: this.message.created_at,
          fileType: 'doc',
          file: downloadURL,
          seen: this.message.seen
        });
        this.selectFileMessageModal.nativeElement.click();
      });
    });
  }














  getMail() {
    return localStorage.getItem('currentusermail');
  }

  ngOnDestroy() {
    clearInterval(this.pollingAM);
  }

  delete($id) {
    this.deleteservice.deleteData('messages', $id).subscribe(res => {
      console.log(res);
      this.msg.setMessage('new operation');
    });
  }

}
