import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Filiere } from 'src/app/models/Filiere';
import { InscriptFile } from 'src/app/models/InscriptFile';
import { Inscription } from 'src/app/models/Inscription';
import { AddService } from 'src/app/services/add.service';
import { DeleteService } from 'src/app/services/delete.service';
import { MessageService } from 'src/app/services/message.service';
import { SelectService } from 'src/app/services/select.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-demande-approved-details',
  templateUrl: './demande-approved-details.component.html',
  styleUrls: ['./demande-approved-details.component.css']
})
export class DemandeApprovedDetailsComponent implements OnInit {

  inscription: Inscription;
  filiere: Filiere;
  files: InscriptFile[] = [];

  selectedFile: File = null;

  filepath = environment.filepath;

  public inscriptFile = {
    filename: null,
    demande_id: null,
    fichier: null
  };

  constructor(
    private route: ActivatedRoute,
    private selectservice: SelectService,
    private addservice: AddService,
    private deleteservice: DeleteService,
    private msg: MessageService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.selectservice.selectInscriptionItem(data.id).subscribe(res => {
        this.inscription = res[0];

        this.inscriptFile.demande_id = this.inscription.id;

        this.selectservice.getInscriptItemFiles(this.inscription.id).subscribe(res => {
          this.files = res;
        })

        this.selectservice.getOneFiliere(this.inscription.filiere_id).subscribe(f => {
          this.filiere = f[0];
        });
      });

      this.msg.getMessage().subscribe(all => {
        this.selectservice.getInscriptItemFiles(this.inscription.id).subscribe(res => {
          this.files = res;
        });
      });
    });
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  uploadInscriptFile(e) {
    this.inscriptFile.demande_id = this.inscription.id;

    const fd = new FormData();

    fd.append('fichier', this.selectedFile);
    fd.append('filename', this.inscriptFile.filename);
    fd.append('demande_id', this.inscriptFile.demande_id);

    this.addservice.uploadInscriptFile(fd).subscribe(res => {
      console.log(res);
      this.inscriptFile.filename = null,
      this.inscriptFile.fichier = null;
      this.msg.setMessage('msg');
    });
  }

  Delete(id) {
    this.deleteservice.deleteData('inscript_files', id).subscribe(res => {
      console.log(res);
      this.msg.setMessage('msg');
    })
  }

}
