import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/User';
import { UserdataService } from '../../../services/userdata.service';
import { AddService } from '../../../services/add.service';
import { ContactUs } from '../../../models/ContactUs';
import { SelectService } from '../../../services/select.service';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-contact-list',
  templateUrl: './contact-list.component.html',
  styleUrls: ['./contact-list.component.css']
})
export class ContactListComponent implements OnInit {
  
  user: User;
  currentusermail: any;

  contacts: ContactUs[] = [];

    name:string = '';
    email:string = '';
    message:string = '';

  constructor(
    private userdataservice: UserdataService,
    private addservice: AddService,
    private selectservice: SelectService,
    private updateservice: UpdateService,
    private msg: MessageService
  	) { }

  ngOnInit(): void {
    this.currentusermail = this.getMail();

    this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
      this.user = data[0];
    });

    this.selectservice.getAll('contact_us').subscribe(data => {
      this.contacts = data.reverse();
    });

    this.msg.getMessage().subscribe(data => {
      this.selectservice.getAll('contact_us').subscribe(data => {
        this.contacts = data.reverse();
      });
    });
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  addContactUs(e) {
    e.preventDefault();
    this.addservice.contactus('',this.name, this.email, this.message, 0, '').subscribe(data => {
      console.log(data);

      setTimeout(function() {
        document.getElementById('confirmAddContact').style.display = "block";
      }, 1000);


      setTimeout(function() {
        document.getElementById('confirmAddContact').style.display = "none";
      }, 8000);

      this.name = '';
      this.email = '';
      this.message = '';
    });
  }

  marquercommelu($id) {
    this.updateservice.updateStatusID('contact_us', $id).subscribe(res=> {
      console.log(res);
      this.msg.setMessage('new opt');
    });
  }

}
