import { Component, OnInit, ViewChild } from '@angular/core';
import { SelectService } from '../../services/select.service';
import { Publication } from '../../models/Publication';
import { Actuality } from '../../models/Actuality';
import { AddService } from 'src/app/services/add.service';
import { Faq } from 'src/app/models/Faq';
import { Cycle } from 'src/app/models/Cycle';
import { Partenaire } from 'src/app/models/Partenaire';
import { GalleryFile } from 'src/app/models/GalleryFile';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  @ViewChild('video') video;

  filepath = environment.filepath;

  publications: Publication[] = [];
  lastpubs: Publication[] = [];

  actualities: Actuality[] = [];
  lastacts: Actuality[] = [];

  cycles: Cycle[] = [];
  partenaires: Partenaire[] = [];
  galleryfiles: GalleryFile[] = [];
  firstgalleryimg: GalleryFile;
  firstgalleryvideo: GalleryFile;

  /* Contact Us attributes */
  name:string = '';
  email:string = '';
  message:string = '';
  /* ********* */
  /* FAQs */
  faqs: Faq[] = [];
  /* ********* */
  i: any = 0;
  constructor(
  	private select: SelectService,
    private addservice: AddService,
    private toastr: ToastrService
  	) { }

  ngOnInit(): void {
  	this.select.getAll('publications').subscribe(res => {
  		this.publications = res.reverse();
    	if(this.publications.length >= 3) {
        for(this.i = 0; this.i < 3; this.i++) {
          this.lastpubs[this.i] = this.publications[this.i];
        }
      } else {
        for(this.i = 0; this.i < this.publications.length; this.i++) {
          this.lastpubs[this.i] = this.publications[this.i];
        }
      }
  	});

    this.select.getAll('cycles').subscribe(data => {
      this.cycles = data;
    });
    this.select.getAll('partenaires').subscribe(res => {
      this.partenaires = res;
    });

    this.select.getAll('gallery_files').subscribe(data => {
  		this.galleryfiles = data.reverse();
  		//console.log(this.galleryfilesfiles);

      if(this.galleryfiles.length > 0) {
        if(this.galleryfiles[0].format == 'img') {
          this.firstgalleryimg = this.galleryfiles[0];

          if(this.firstgalleryvideo != null) {
            document.getElementById('videotoshow').style.display = "none";
          }


        } else if(this.galleryfiles[0].format == 'video') {
          this.firstgalleryvideo = this.galleryfiles[0];

          if(this.firstgalleryimg != null) {
            document.getElementById('imgtoshow').style.display = "none";
          }


        }
      }
  	});

    this.select.getAll('actualities').subscribe(res => {
      this.actualities = res.reverse();
      console.log(this.actualities);
      if(this.actualities.length >= 3) {
        for(this.i = 0; this.i < 3; this.i++) {
          this.lastacts[this.i] = this.actualities[this.i];
        }
      } else {
        for(this.i = 0; this.i < this.actualities.length; this.i++) {
          this.lastacts[this.i] = this.actualities[this.i];
        }
      }
    })

    this.select.getAll('faqs').subscribe(res => {
      this.faqs = res;
    });
  }


  addContactUs(e) {
    e.preventDefault();
    this.addservice.contactus('',this.name, this.email, this.message, 0, '').subscribe(data => {
      console.log(data);
      this.toastr.success('Votre message a été envoyé avec succès!', 'Succès');

      this.name = '';
      this.email = '';
      this.message = '';
    });
  }

  getimg(index, video) {
    if(this.galleryfiles[index].format == 'img') {
      this.firstgalleryimg = this.galleryfiles[index];
      document.getElementById('videotoshow').style.display = "none";
      document.getElementById('imgtoshow').style.display = "block";
    } else if(this.galleryfiles[index].format == 'video') {
      this.firstgalleryvideo = this.galleryfiles[index];
      document.getElementById('imgtoshow').style.display = "none";
      document.getElementById('videotoshow').style.display = "block";
    }

    video.pause();

    for(let i = 0; i < this.galleryfiles.length; i++) {
      document.getElementById('file'+i).style.border = "1px solid #f7f8ff";
    }
    document.getElementById('file'+index).style.border = "1px solid #921831";
  }

}
