import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { TokenService } from '../services/token.service';

@Injectable({
  providedIn: 'root'
})
export class NotAuthGuard implements CanActivate {
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | Observable<boolean> | Promise<boolean>  {
      if(Boolean(!localStorage.getItem('token'))) {
        return true
      } else {
        this.router.navigateByUrl('/gaj/accueil');
        return false;
      }
  }

  constructor(
    private token: TokenService,
    private router: Router
    ) {}

}
