<section>
<h4 class="card-title text-white text-center main-title">
    Présentation de la société
</h4>

</section>

<div class="content-section">
    <div class="card col-lg-10 col-md-11 col-sm-12 mx-auto shadow">
        <div class="card-body">
            <p>
                Le Groupe ATUA JUNIOR est une société d’expertise en coopération internationale et
                développement des affaires spécialisée dans le secteur de l’éducation. La société est
                légalement constituée en Tunisie depuis l’année 2020 ainsi qu’en République Démocratique
                du Congo en 2022.
            </p>
            <p>
                Notre objectif est de pouvoir fédérer l’ensemble des ressources intellectuelles, techniques et
                financières autour des défis liés au secteur éducatif, socle et catalyseur de croissance
                économique et surtout l’adapter aux besoins de la région de manière générale. A l’ère de la
                mondialisation, le Congo Kinshasa en particulier et l’Afrique en générale découvre
                véritablement son réel potentiel qui se voit depuis plusieurs années être convoiter du
                monde entier. La nécessité d’une politique éducative de développement socio-économique
                est désormais impérative pour notre pays tout en mettant la jeunesse et l’entrepreneuriat
                au cœur du projet.
            </p>
            <p>
                La réalisation de cette ambition passe également par un réseautage à l’échelle tant nationale
                qu’internationale afin de favoriser un transfert de technologie et partage des connaissances
                avec différents pays assez développer.
            </p>
        </div>
    </div>
</div>