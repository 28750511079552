<div class="row accueil-container">
  <div class="actualities-col border-right px-0 col-md-5 py-2">
    <div class="header col-12 d-flex border-bottom justify-content-between">
      <div>
        <h5 class="componentlink ml-1" routerLink="/gaj/actualities">
          Actualités<sup><i class="fas fa-external-link-alt ml-1"></i></sup>
        </h5>
      </div>
      <div>
        <label
          for="addactualitybtn"
          class="addpublicbtn"
          *ngIf="role == 'admin'"
        >
          <button
            type="button"
            id="addactualitybtn"
            class="btn btn-sm btn-gaj add-pub-btn"
            data-toggle="modal"
            data-target="#addActuality"
          >
            <i class="fas fa-plus"></i>
          </button>
          <span>Actualité</span>
        </label>
      </div>
    </div>
    <div class="body p-3">
      <div
        class="alert alert-primary p-1 text-center"
        role="alert"
        *ngIf="someactualities.length == 0"
      >
        Aucune actualitée à affichée!
      </div>

      <div *ngFor="let actuality of someactualities">
        <app-actuality-item [actuality]="actuality"></app-actuality-item>
      </div>
    </div>
  </div>
  <div class="publication-col px-0 col-md-7 py-2">
    <div class="header col-12 d-flex border-bottom justify-content-between">
      <div>
        <h5 class="componentlink ml-1" routerLink="/gaj/publications">
          Publications<sup><i class="fas fa-external-link-alt ml-1"></i></sup>
        </h5>
      </div>
      <div
        *ngIf="role != 'admin'"
        style="
          background: transparent;
          line-height: 40px;
          margin: 0;
          position: relative;
        "
      >
        <span
          class="badge badge-danger"
          style="top: -4px; right: 0; position: absolute"
          *ngIf="allFavoris.length <= 9"
          >{{ allFavoris.length }}</span
        >
        <span
          class="badge badge-danger"
          style="top: -4px; right: 0; position: absolute"
          *ngIf="allFavoris.length > 9"
          >+9</span
        >
        <button
          type="button"
          class="btn btn-sm btn-light text-gaj"
          style="margin: auto 10px auto 4px"
          routerLink="/gaj/favoris"
        >
          <i class="far fa-bookmark"></i>
        </button>
      </div>
      <div *ngIf="role == 'admin'">
        <label for="addpubbtn" class="addpublicbtn">
          <span>Publication</span>
          <button
            type="button"
            id="addpubbtn"
            class="btn btn-sm btn-gaj add-pub-btn"
            data-toggle="modal"
            data-target="#addPublication"
          >
            <i class="fas fa-plus"></i>
          </button>
        </label>
      </div>
    </div>
    <div class="filterbtns d-flex px-3">
      <button
        class="btn btn-sm btn-secondary my-auto mr-2"
        (click)="filter('all')"
      >
        Tout
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2"
        (click)="filter('actuality')"
      >
        Actualité
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2"
        (click)="filter('officiel')"
      >
        Officiel
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
        (click)="filter('gajconsulting')"
      >
        GAJ Consulting
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
        (click)="filter('gajTraining')"
      >
        GAJ Training
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
        (click)="filter('gajScholarship')"
      >
        GAJ Scholarship
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
        (click)="filter('gajFablab')"
      >
        GAJ Fablab
      </button>
      <button
        class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
        (click)="filter('gajMagazine')"
      >
        GAJ Magazine
      </button>
    </div>

    <div class="body px-3 pb-3">
      <div
        class="alert alert-primary p-1 text-center"
        role="alert"
        *ngIf="somepublications.length == 0"
      >
        Aucune publication à affichée!
      </div>

      <div *ngFor="let publication of somepublications">
        <app-publication-item
          [publication]="publication"
        ></app-publication-item>
      </div>
      <button
        class="btn btn-light btn-block border-info text-gaj my-3 fw-6"
        routerLink="/gaj/publications"
        *ngIf="somepublications.length != 0"
      >
        voir plus de publications
      </button>
    </div>
  </div>
</div>

<!-- Add Publication Modal -->
<div
  class="modal fade"
  id="addPublication"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button
          type="button"
          class="btn close-btn text-white"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <app-add-publication-form></app-add-publication-form>
      </div>
    </div>
  </div>
</div>

<!-- Add Actualité Modal -->
<div
  class="modal fade"
  id="addActuality"
  tabindex="-1"
  aria-labelledby="exampleModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button
          type="button"
          class="btn close-btn text-white"
          data-dismiss="modal"
          aria-label="Close"
        >
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <app-add-actuality-form></app-add-actuality-form>
      </div>
    </div>
  </div>
</div>
