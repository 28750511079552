<div class="card col-12 border p-0">
	<div class="card-body p-0 col-12 d-flex">
		<a style="width: calc(100% - 40px); text-decoration: none; color: #222; font-size: 16px; font-weight: 300; height: 40px;" target="_blank" download="{{filepath}}/documents/{{document?.differentname}}" href="{{filepath}}/documents/{{document?.differentname}}">
			<div class="pl-2" style="line-height: 40px;">
				{{document?.realname}}
			</div>
		</a>
		<button style="height: 40px; width: 40px;" class="btn btn-danger" (click)="DeleteFile()"><i class="fas fa-trash-alt"></i></button>
	</div>
</div>
