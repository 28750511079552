import { Component, OnInit } from '@angular/core';
import { MessageService } from '../../services/message.service';
import { SelectService } from '../../services/select.service';
import { User } from '../../models/User';
import { Cycle } from '../../models/Cycle';
import { UserdataService } from '../../services/userdata.service';

@Component({
  selector: 'app-cycles',
  templateUrl: './cycles.component.html',
  styleUrls: ['./cycles.component.css']
})
export class CyclesComponent implements OnInit {

  user: User;
  cycles: Cycle[] = [];

  currentusermail: any;


  constructor(
  	private selectservice: SelectService,
    private msg: MessageService,
    private userdata: UserdataService
  	) { }

  ngOnInit(): void {
    this.currentusermail = this.getMail();

    this.userdata.getUserData(this.currentusermail).subscribe(user => {
      this.user = user[0];
    });

  	this.selectservice.getAll('cycles').subscribe(res => {
  		this.cycles = res;
  	});

  	this.msg.getMessage().subscribe(res => {
  		this.selectservice.getAll('cycles').subscribe(res => {
	  		this.cycles = res;
	  	});
  	});
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }


}
