import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectService } from '../../services/select.service';
import { UpdateService } from '../../services/update.service';
import { Publication } from '../../models/Publication';
import { User } from '../../models/User';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pub-item',
  templateUrl: './pub-item.component.html',
  styleUrls: ['./pub-item.component.css']
})
export class PubItemComponent implements OnInit {

  pubid: any;
  email: any;

  filepath = environment.filepath;

  userdata: User[] = [];
  useritem: User;
  publication: Publication;

  constructor(
  	private route: ActivatedRoute,
  	private selectservice: SelectService,
  	private updateservice: UpdateService,
  	) {
    }

  ngOnInit(): void {
  	this.email = this.getMail();


  	this.route.params.subscribe(data => {
  		this.pubid = data.id;

  		this.updateservice.updatePubNotifStatus(this.pubid, this.email).subscribe(result => {
  		});
  		this.selectservice.getOnePublication(this.pubid).subscribe(res => {
  			this.publication = res[0];

  		});
  	});
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

}
