import { Component, OnInit } from '@angular/core';
import { SelectService } from '../../services/select.service';
import { Publication } from '../../models/Publication';

@Component({
  selector: 'app-allpublications',
  templateUrl: './allpublications.component.html',
  styleUrls: ['./allpublications.component.css']
})
export class AllpublicationsComponent implements OnInit {
  publications: Publication[] = [];
  initpublications: Publication[] = [];

  i: any = 0;
  j: any = 0;

  constructor(
  	private select: SelectService
  	) { }

  ngOnInit(): void {
  	this.select.getAll('publications').subscribe(res => {
  		this.publications = res.reverse();
      this.initpublications = res.reverse();
  		console.log(this.publications);
  	});
  }

  filter($category) {
    if($category == 'all') {
      this.publications = this.initpublications;
    } else {
      this.publications = [];
      this.j = 0;
      for(this.i = 0; this.i < this.initpublications.length; this.i++) {
        if(this.initpublications[this.i].category == $category) {
          this.publications[this.j] = this.initpublications[this.i];
          this.j++;
        }
      }
    }
  }

}
