import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SelectService } from 'src/app/services/select.service';

@Component({
  selector: 'app-response-reset',
  templateUrl: './response-reset.component.html',
  styleUrls: ['./response-reset.component.css']
})
export class ResponseResetComponent implements OnInit {
  
  public error = [];

  public form = {
  	email: null,
  	password: null,
  	password_confirmation: null,
  	resetToken: null
  }

  constructor(
    private route: ActivatedRoute,
    private authentication: AuthenticationService,
    private router: Router,
    private selectservice: SelectService
  ) {
    route.queryParams.subscribe(params => {
  		this.form.resetToken = params['token']
  	});
  }

  ngOnInit(): void {
    this.selectservice.getResetPasswordData(this.form.resetToken).subscribe(res => {
      this.form.email = res[0].email;
    })
  }

  onSubmit() {
  	this.authentication.changePassword(this.form).subscribe(
  			data => this.handleResponse(data),
  			error => this.handleError(error)
  		);
  }

  handleResponse(data) {
  	this.router.navigateByUrl('/accueil');
  }

  handleError(error) {

  }

}
