import { Component, OnInit } from '@angular/core';
import { UserdataService } from '../../services/userdata.service';
import { TokenService } from '../../services/token.service';
import { User } from '../../models/User';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../services/message.service';
import { Message1Service } from '../../services/message1.service';
import { SelectService } from '../../services/select.service';
import { Filiere } from '../../models/Filiere';
import { Diplomes } from '../../models/Diplomes';
import { DeleteService } from '../../services/delete.service';
import { environment } from 'src/environments/environment';




@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {

  togglesidebar: any = false;
  user: User;
  allFilieres: Filiere[] = [];
  diplomes: Diplomes[] = [];

  filepath = environment.filepath;

  currentusermail: any;
  selectedFile: File = null;

  user_id: string;
  avatar: string;
  fname: string;
  lname: string;
  email: string;
  role: any;
  usertype: string;
  password: string;
  status: number;
  phone: string;
  passport: string;
  nationality: string;
  residencecountry: string;
  naissance: string;
  token: string;

  public diplomform = {
    userid: null,
    diplometitle: null,
    start: null,
    end: null,
    filiere: null,
    status: '0'
  };

  constructor(
  	private userdataservice: UserdataService,
  	private tokenservice: TokenService,
  	private http: HttpClient,
  	private msg: MessageService,
    private msg1: Message1Service,
    private select: SelectService,
    private deleteservice: DeleteService
  	) {}



    nationalities = [
      { pay: "Autre", nationality: "Autre" },
      { pay: "Afrique du Sud", nationality: "Sud Africain(e)" },
      { pay: "Algérie", nationality: "Algérien(ne)" },
      { pay: "Angola", nationality: "Angolaise" },

      { pay: "Bénin", nationality: "Béninois" },
      { pay: "Botswana", nationality: "Botswanaise" },
      { pay: "Burkina Faso", nationality: "Burkinabè" },
      { pay: "Burundi", nationality: "Burundaise " },
      { pay: "Cameroun", nationality: "Camerounaise" },
      { pay: "Canaries", nationality: "Canaries" },
      { pay: "Cap-Vert", nationality: "Cap-Vert" },
      { pay: "Comores", nationality: "Comorien(ne)" },
      { pay: "Congo", nationality: "Congolaise" },

      { pay: "Côte d’Ivoire", nationality: "Ivoirien(ne)" },
      { pay: "Djibouti", nationality: "Djiboutien(ne)" },
      { pay: "Égypte", nationality: "Égyptien(ne)" },
      { pay: "Érythrée", nationality: "Érythréen(ne)" },
      { pay: "Eswatini", nationality: "Eswatini" },
      { pay: "Éthiopie", nationality: "Éthiopien(ne)" },
      { pay: "Gabon", nationality: "Gabonaise" },
      { pay: "Gambie", nationality: "Gambien(ne)" },
      { pay: "Ghana", nationality: "Ghanéen(ne)" },
      { pay: "Guinée", nationality: "Guinéen(ne)" },

      { pay: "Guinée équatoriale", nationality: "Guinée équatoriale" },
      { pay: "Guinée-Bissau", nationality: "Guinée-Bissau" },
      { pay: "Kenya", nationality: "Kényane" },
      { pay: "Lesotho", nationality: "Mosotho" },
      { pay: "Libéria", nationality: "Libérien(ne)" },
      { pay: "Libye", nationality: "Libyen(ne)" },
      { pay: "Madagascar", nationality: "Malgache" },
      { pay: "Madère", nationality: "Madère" },
      { pay: "Malawi", nationality: "Malawi" },
      { pay: "Mali", nationality: "Malien(ne)" },

      { pay: "Maroc", nationality: "Marocain(e)" },
      { pay: "Maurice", nationality: "Mauricien(ne)" },
      { pay: "Mauritanie", nationality: "Mauritanien(ne)" },
      { pay: "Mayotte", nationality: "Mayotte" },
      { pay: "Mozambique", nationality: "Mozambique" },
      { pay: "Namibie", nationality: "Namibien(ne)" },
      { pay: "Niger", nationality: "Nigérien(ne)" },
      { pay: "Nigéria", nationality: "Nigérian(ne)" },
      { pay: "Ouganda", nationality: "Ougandaise" },

      { pay: "République centrafricaine", nationality: "Centrafricain(e)" },
      { pay: "République démocratique du Congo", nationality: "Congolaise " },
      { pay: "Réunion", nationality: "Réunionnaise" },
      { pay: "Rwanda", nationality: "Rwandaise" },
      { pay: "Sahara occidental", nationality: "Sahara occidental" },
      { pay: "Sainte-Hélène, Ascension et Tristan da Cunha", nationality: "Sainte-Hélène, Ascension et Tristan da Cunha" },
      { pay: "Sao Tomé-et-Principe", nationality: "Sao Tomé-et-Principe" },
      { pay: "Sénégal", nationality: "sénégalais(e)" },
      { pay: "Seychelles", nationality: "Seychellois" },
      { pay: "Sierra Leone	", nationality: "Sierra-Léonaise" },

      { pay: "Somalie", nationality: "Somalien(ne)" },
      { pay: "Somaliland", nationality: "Somalilandaise" },
      { pay: "Soudan", nationality: "Soudanaise" },
      { pay: "Soudan du Sud", nationality: "Sud-Soudanaise" },
      { pay: "Tanzanie", nationality: "Tanzanien(ne)" },
      { pay: "Tchad", nationality: "Tchadien(ne)" },
      { pay: "Togo", nationality: "Togolaise " },
      { pay: "Tunisie", nationality: "Tunisien(ne)" },
      { pay: "Zambie", nationality: "Zambien(ne)" },
      { pay: "Zimbabwé", nationality: "Zimbabwéen(ne)" }
    ];

  ngOnInit(): void {
  	localStorage.setItem('togglesidebar', this.togglesidebar);

  	//console.log(this.tokenservice.isValid());

    this.select.getAll('filieres').subscribe(res => {
      this.allFilieres = res;
    });

  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];


      this.user_id = this.user.userid;

      this.select.getDiplomes(this.user.userid).subscribe(res => {
        this.diplomes = res;
      });

      this.msg.getMessage().subscribe(res => {
        this.select.getDiplomes(this.user.userid).subscribe(res => {
          this.diplomes = res;
          console.log(this.diplomes);
        });
      });

      this.msg1.getMessage().subscribe(res => {
        this.select.getDiplomes(this.user.userid).subscribe(res => {
          this.diplomes = res;
          console.log(this.diplomes);
        });
      });

      this.diplomform.userid = this.user.userid;

  		this.avatar = this.user.avatar;
		  this.fname = this.user.fname;
		  this.lname = this.user.lname;
		  this.email = this.user.email;
		  this.role = this.user.role;
      this.usertype = this.user.usertype;
		  this.password = this.user.password;
		  this.status = this.user.status;
		  this.phone = this.user.phone;
		  this.passport = this.user.passport;
		  this.nationality = this.user.nationality;
		  this.residencecountry = this.user.residencecountry;
		  this.naissance = this.user.naissance;
		  this.token = this.user.token;

      if(this.user_id == null) {
        this.generateUserID();
      } else {
        console.log('')
      }

  	});



  	this.msg.getMessage().subscribe((pjct) => {
      this.userdataservice.getUserData(this.currentusermail).subscribe((all) => {
        this.user = all[0];

      });
    });
  }

  generateUserID() {
    if(this.user_id == null) {
      this.user_id = '';
      const chars = '0123456789';
      const l = 16;
      let randomstring = '';
      for (let i=0; i<l; i++) {
        const rnum = Math.floor(Math.random() * chars.length);
        randomstring = randomstring + chars.substring(rnum, rnum+1);
      }

      this.user_id = randomstring;

      this.userdataservice.generateUID('', this.user_id, this.avatar, this.fname, this.lname, this.email, this.role, this.usertype, this.password, this.status, this.phone, this.passport, this.nationality, this.residencecountry, this.naissance, this.token).subscribe((data) => {
        console.log(data);
        this.msg.setMessage("new operation");
      });
    }
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];
  }

  onUpload() {
    const fd = new FormData();
    fd.append('email', this.currentusermail);
    fd.append('image', this.selectedFile, this.selectedFile.name);
    this.http.post(environment.api+'/profileAvatarUpload', fd).subscribe(res => {

      console.log(res);

      document.getElementById("cameraupload").style.display = "none";
      document.getElementById("camerauploaded").style.display = "block";
      setTimeout(function(){
        document.getElementById("cameraupload").style.display = "block";
        document.getElementById("camerauploaded").style.display = "none";
      }, 1200);
      this.msg.setMessage("new operation");
    });
  }

  editprofile() {
    this.userdataservice.updateprofile('', this.user_id, this.avatar, this.fname, this.lname, this.email, this.role, this.usertype, this.password, this.status, this.phone, this.passport, this.nationality, this.residencecountry, this.naissance, this.token).subscribe((data) => {
      console.log('Profile updated successfully');
      document.getElementById("profileupdatesuccess").style.display = "block";
      setTimeout(function(){
        document.getElementById("profileupdatesuccess").style.display = "none";
      }, 8000);
    });
  }


  addDiploma(e) {
    const fd  = new FormData();

    fd.append('userid', this.diplomform.userid);
    fd.append('diplometitle', this.diplomform.diplometitle);
    fd.append('start', this.diplomform.start);
    fd.append('end', this.diplomform.end);
    fd.append('filiere', this.diplomform.filiere);
    fd.append('status', this.diplomform.status);



    this.http.post(environment.api+'/addDiploma', fd).subscribe(res => {
      console.log(res);
      this.msg.setMessage("new operation");

      this.diplomform.diplometitle = null;
      this.diplomform.start = null;
      this.diplomform.end = null;
      this.diplomform.filiere = null;
    });
  }

  DeleteDiplome($id) {
    this.deleteservice.deleteData('user_studies', $id).subscribe(res => {
      console.log(res);
      this.msg1.setMessage('new Operation');
    });
  }

}
