<section>
    <h4 class="card-title text-white text-center main-title">
        Départements
    </h4>
    
    </section>
    
    <div class="content-section col-lg-10 col-md-11 col-sm-12 mx-auto justify-content-center">
        <div class="card border-0 relative" style="width: 18rem;">
            <div class="dept-box">G.A.J CONSULTING</div>
            <hr class="my-1">
            <div class="card-body">
                <p>
                    GAJ CONSULTING est un programme d’orientation académique et d’accompagnement
                    administratif et logistique pour les études supérieures à l’étranger notamment en Tunisie, en
                    Chypre, en France et au Canada.
                </p>
            </div>
        </div>

        <div class="card border-0 relative" style="width: 18rem;">
            <div class="dept-box">GAJ TRAINING</div>
            <hr class="my-1">
            <div class="card-body">
                <p>
                    GAJ TRAINING est un programme de formation professionnelle, technique et de
                    renforcement de capacité destiné aux individues, aux organismes et entreprises publiques
                    ou privées, aux établissements d’enseignements primaires, secondaires, universitaires et
                    centre de formations.
                </p>
            </div>
        </div>

        <div class="card border-0 relative" style="width: 18rem;">
            <div class="dept-box">GAJ SCHOLARSHIP</div>
            <hr class="my-1">
            <div class="card-body">
                <p>
                    GAJ SCHOLARSHIP est un programme de gestion administrative et logistique d’offres de
                    bourses d’études. Ce programme prend en compte la sous-traitance de toute la procédure
                    relative ces offres auprès des organismes privés ou étatiques qui allouent des budgets
                    destinés aux financements pour les études supérieures et formations professionnelles à
                    l’étranger.
                </p>
            </div>
        </div>

        <div class="card border-0 relative" style="width: 18rem;">
            <div class="dept-box">GAJ FABLAB</div>
            <hr class="my-1">
            <div class="card-body">
                <p>
                    GAJ FABLAB est un programme de développement des projets dans le secteur de
                    l’éducation. Notre réseau des professionnels du secteur éducatif à l’international nous
                    permet de mettre en place des projets visant à améliorer la qualité d’enseignement d’un
                    établissement ou du système éducatif.
                </p>
            </div>
        </div>

        <div class="card border-0 relative" style="width: 18rem;">
            <div class="dept-box">GAJ MAGAZINE</div>
            <hr class="my-1">
            <div class="card-body">
                <p>
                    GAJ MAGAZINE est un programme de communication lier au secteur de l’éducation et de
                    promotion de l’excellence académique et professionnelle.
                </p>
            </div>
        </div>
    </div>