<section>
  <h4 class="card-title text-white text-center main-title">Nos Services</h4>
</section>

<hr class="mt-5" />

<div
  class="prevision-section col-lg-10 col-md-11 col-sm-12 mx-auto justify-content-center"
>
  <div class="card relative mb-3 shadow">
    <div class="title-of-card">I. GAJ CONSULTING</div>
    <hr class="my-1" />
    <div class="card-body">
      <h3>A. TUNISIE</h3>
      <p>
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Etudes de dossier et orientation académique <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Préparation de dossier <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance préinscription et inscription <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance obtention visa <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance voyage <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance accueil en Tunisie <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance logement et aménagement complet de la chambre <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Mentorat continu
      </p>
      <h3>B. CHYPRE</h3>
      <p>
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Etudes de dossier et orientation académique <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Préparation de dossier <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance admission et logement <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance voyage <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance accueil en Chypre <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance logement et aménagement<br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Mentorat continu
      </p>

      <h3>C. TURQUIE</h3>
      <p>
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Etudes de dossier et orientation académique <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Préparation de dossier <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance préinscription et inscription <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance obtention visa <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance voyage <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance accueil en Turquie <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance logement <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Mentorat continu
      </p>

      <h3>D. CHINE</h3>
      <p>
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Etudes de dossier et orientation académique <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Préparation de dossier <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance préinscription et inscription <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance obtention visa <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance voyage <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance accueil en Chine <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance logement <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Mentorat continu
      </p>

      <h3>E. CANADA</h3>
      <p>
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Etudes de dossier et orientation académique <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Préparation de dossier <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance admission <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance obtention CAQ, permis d’études et visa <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance voyage <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance accueil en Canada <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Assistance logement <br />
        <i class="fas fa-chevron-circle-right text-gaj"></i>
        Mentorat continu
      </p>
    </div>
  </div>

  <div class="card relative mb-3 shadow">
    <div class="title-of-card">II. GAJ TRAINING</div>
    <hr class="my-1" />
    <div class="card-body">
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Formations
        organisées pour les particuliers en partenariat avec une organisation </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Formations
        organisées pour les particuliers en partenariat avec deux organisations </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Formations
        organisées pour les entreprises en partenariat avec une organisation </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Formations
        organisées pour les établissements d’enseignement en partenariat avec
        une organisation </span
      ><br />
    </div>
  </div>

  <div class="card relative mb-3 shadow">
    <div class="title-of-card">III. GAJ SCHOLARSHIP</div>
    <hr class="my-1" />
    <div class="card-body">
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Promotion et
        appels à candidature </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Organisation du
        concours </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Conférence
        d’orientation académique pour les gagnants </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Etudes et
        préparation de dossier </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Assistance pour
        l’admission </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Assistance pour
        l’obtention du visa </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Assistance pour le
        voyage </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Assistance pour
        l’accueil </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Assistance pour
        l’obtention du logement et aménagement </span
      ><br />
    </div>
  </div>

  <div class="card relative mb-3 shadow">
    <div class="title-of-card">IV. GAJ FABLAB</div>
    <hr class="my-1" />
    <div class="card-body">
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Facilitation des
        partenariats inter-universitaires </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Séminaires de
        formation </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Création des clubs </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Mise en place des
        incubateurs </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Mise en place des
        laboratoires </span
      ><br />
      <span
        ><i class="fas fa-chevron-circle-right text-gaj"></i> Autres projets </span
      ><br />
    </div>
  </div>
</div>

<!-- <div class="bienvenue-frais bg-gaj p-3 mt-3">
  <p>
    Nous vous souhaitons la bienvenue en Tunisie et vous garantissons être à vos
    côtés pour vous accompagner dans votre intégration socio-académique en
    Tunisie pour bénéficier au maximum des avantages que porte la Tunisie dans
    le secteur académique et professionnel qui est reconnu à l’échelle mondial.
  </p>
  <p class="col-12 text-right">
    <i>Directeur Général du Groupe ATUA JUNIOR</i>
  </p>
</div> -->
