<div class="top-img-section">
  <div class="bienvenue-top">
    <p>Bienvenue à <strong>Groupe ATUA JUNIOR </strong></p>
  </div>
  <p class="big-text top-section-title-animate">Groupe ATUA JUNIOR</p>
  <p class="small-caption">Pour une éducation de développement</p>
  <a class="commencer-btn" data-toggle="modal" href="#authmodal">Commencer</a>
</div>

<div class="actuality-section container-fluid p-3 my-3" id="actualités">
  <div class="row col-12 m-0 justify-content-center">
    <h5 class="col-12 text-center section-title section-title-animate">
      Actualités
    </h5>
    <a routerLink="/actualities" class="voir-plus"
      ><h5 class="col-12 text-center">
        Voir Plus <i class="fas fa-arrow-right"></i></h5
    ></a>
  </div>
  <div class="p-1 act-item" *ngFor="let actuality of lastacts">
    <div class="">
      <app-act-item [actuality]="actuality"></app-act-item>
    </div>
  </div>
</div>
<div class="inscription-reminder">
  <img src="assets/img/studying.jpg" />
  <span class="text-center remind-text col-md-9 mx-auto"
    >Inscrivez-vous dès maintenant</span
  >
  <a
    class="commencer-btn absolute-btn text-center"
    data-toggle="modal"
    href="#authmodal"
    >Commencer</a
  >
</div>
<div class="publication-section container-fluid p-3 my-3" id="publications">
  <div class="row col-12 m-0 justify-content-center">
    <h3 class="col-12 text-center section-title section-title-animate">
      Publications
    </h3>
    <a routerLink="/publications" class="voir-plus"
      ><h5 class="col-12 text-center">
        Voir Plus <i class="fas fa-arrow-right"></i></h5
    ></a>
  </div>
  <div class="p-1 pub-item" *ngFor="let publication of lastpubs">
    <div class="">
      <app-pub-item [publication]="publication"></app-pub-item>
    </div>
  </div>
</div>

<section class="cycles-section" style="background: #921831" id="cycles">
  <div
    class="col-lg-10 col-md-11 col-sm-12 mx-auto p-3 my-5"
    style="min-height: 120px"
  >
    <h3
      class="card-title col-12 text-center section-title text-white section-title-animate"
    >
      Cycles
    </h3>
    <hr />
    <div style="display: flex; flex-wrap: wrap; justify-content: center">
      <div
        class="card"
        style="width: 18rem; margin: 5px"
        *ngFor="let cycle of cycles"
      >
        <app-v-cycle-item [cycle]="cycle"></app-v-cycle-item>
      </div>
    </div>
  </div>
</section>

<section class="py-3" id="gallerie">
  <h3 class="card-title col-12 text-center text-gaj">Galerie</h3>
  <hr class="my-2" />
  <div class="big-img" *ngIf="galleryfiles.length != 0">
    <img
      src="{{ filepath }}/galleryFiles/{{ firstgalleryimg?.file }}"
      *ngIf="firstgalleryimg != null"
      id="imgtoshow"
      class="img-to-show"
      alt=""
    />
    <video
      #video
      controls
      src="{{ filepath }}/galleryFiles/{{ firstgalleryvideo?.file }}"
      *ngIf="firstgalleryvideo != null"
      class="img-to-show"
      id="videotoshow"
    ></video>
  </div>
  <div class="all-imgs-row" *ngIf="galleryfiles.length != 0">
    <div *ngFor="let file of galleryfiles; let index = index">
      <img
        src="{{ filepath }}/galleryFiles/{{ file?.file }}"
        (click)="getimg(index, video)"
        id="file{{ index }}"
        alt=""
        *ngIf="file?.format == 'img'"
      />
      <video
        src="{{ filepath }}/galleryFiles/{{ firstgalleryvideo?.file }}"
        id="file{{ index }}"
        (click)="getimg(index, video)"
        *ngIf="file?.format == 'video'"
      ></video>
    </div>
  </div>
</section>

<section
  class="partenaire-section pb-5 pt-3 bg-light border-top"
  id="partenaires"
>
  <h3
    class="card-title col-12 text-center section-title text-gaj my-3 section-title-animate"
  >
    Partenaires
  </h3>
  <div class="partenaire-container px-0 pt-3">
    <div
      class="card border-0 shadow"
      style="min-width: 17rem; max-width: 17rem; margin: 5px"
      *ngFor="let partenaire of partenaires"
    >
      <img
        src="{{ filepath }}/partenaires/{{ partenaire?.image }}"
        class="card-img-top"
        alt="..."
      />
      <div class="card-body">
        <h5 class="card-title">{{ partenaire?.name }}</h5>
      </div>
    </div>
  </div>
</section>

<section class="contactUs-section py-3 bg-gaj" id="contacter-nous">
  <div
    class="col-lg-10 col-md-11 col-sm-12 mx-auto p-3 my-5"
    style="min-height: 120px"
  >
    <h3 class="card-title text-center section-title-animate">Contactez-Nous</h3>
    <hr />
    <div class="row">
      <div class="col-md-4 text-center d-flex">
        <div class="contact-icon"><i class="fas fa-map-marker-alt"></i></div>
        <div class="contact-info text-left pl-2 pt-2">
          <h5>Localisation</h5>
          <p>Tunisie, R.D. Congo</p>
        </div>
      </div>
      <div class="col-md-4 text-center d-flex">
        <div class="contact-icon"><i class="far fa-envelope"></i></div>
        <div class="contact-info text-left pl-2 pt-2">
          <h5>Email</h5>
          <p>direction@groupe-atuajunior.com</p>
        </div>
      </div>
      <div class="col-md-4 text-center d-flex">
        <div class="contact-icon"><i class="fas fa-phone-alt"></i></div>
        <div class="contact-info text-left pl-2 pt-2">
          <h5>Téléphone</h5>
          <p>+243 827 445 329 / +216 54 490 638</p>
        </div>
      </div>
    </div>
    <hr />
    <form #contactform="ngForm" (submit)="addContactUs($event)">
      <div class="row m-0">
        <div class="form-group col-sm-12">
          <label for="name"
            >Votre Nom Complet
            <strong class="required-star-yellow">*</strong></label
          >
          <input
            type="text"
            class="form-control form-control-sm"
            name="name"
            id="name"
            [(ngModel)]="name"
            required
            placeholder="Nom & Prénom"
          />
        </div>
      </div>
      <div class="row m-0">
        <div class="form-group col-sm-12">
          <label for="email"
            >Votre Email <strong class="required-star-yellow">*</strong></label
          >
          <input
            type="email"
            class="form-control form-control-sm"
            name="email"
            id="email"
            [(ngModel)]="email"
            required
            placeholder="Email"
          />
        </div>
      </div>

      <div class="row m-0">
        <div class="form-group col-sm-12">
          <label for="message"
            >Votre Message
            <strong class="required-star-yellow">*</strong></label
          >
          <textarea
            rows="3"
            class="form-control form-control-sm"
            name="message"
            id="message"
            [(ngModel)]="message"
            required
            placeholder="Message..."
          ></textarea>
        </div>
      </div>
      <div class="row mx-0 px-3">
        <button
          type="submit"
          [disabled]="!contactform.valid"
          class="messagesubmitbtn btn btn-light btn-block"
        >
          Envoyer
        </button>
      </div>
    </form>
  </div>
</section>
<section id="faqs">
  <div class="col-lg-10 col-md-11 col-sm-12 mx-auto p-3 my-5 bg-white">
    <h4 class="subtitle my-2 ml-2">Liste des Questions Fréquentes</h4>
    <hr />

    <div class="accordion" id="accordionExample">
      <div class="card" *ngFor="let faq of faqs">
        <div class="card-header p-0" id="headingOne">
          <h2 class="mb-0">
            <a
              class="btn btn-gaj btn-block text-left"
              type="button"
              data-toggle="collapse"
              href="#collapseOne{{ faq?.id }}"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {{ faq?.question }}
            </a>
          </h2>
        </div>

        <div
          id="collapseOne{{ faq?.id }}"
          class="collapse"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div class="card-body">
            {{ faq?.response }}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
