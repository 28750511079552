<div class="container-fluid bg-gaj py-5">
    <div class="reg-form col-md-10 col-lg-8 col-sm-12 mx-auto p-0 mt-5 bg-white radius-0 shadow">
  
      <div class="row m-0 justify-content-center bg-bmk">
        <h5 class="my-3 form-title text-center text-dark"> Send email for reset password </h5>
      </div>
      <div class="row col-12 py-3">
  
        <div class="col-12 pt-5 pr-0">
  
          <form #RequestResetForm=ngForm (ngSubmit)="onSubmit()">
  
            <div class="form-group">
              <label for="inputEmailreset" class="col-md-4 col-form-label text-dark">Email</label>
              <div class="col-md-12">
                <input type="email" name="email" class="form-control" id="inputEmailreset" placeholder="Email" [(ngModel)]="form.email" required>
              </div>
            </div>
  
            <div class="form-group">
              <div class="col-md-12">
                <button type="submit" class="col-md-12 btn btn-gaj" [disabled]="!RequestResetForm.valid">Send reset password email</button>
              </div>
              
            </div>
  
          </form>
  
        </div>
  
      </div>
    </div>
  </div>