<div class="actuality-card card col-md-12 p-0 shadow" routerLink="/actuality/{{actuality?.actid}}">

	<div class="topimg" *ngIf="actuality?.image != null" style="width: 100%; height: 200px;">
		<a data-toggle="modal" href="#showimgvisitor{{actuality?.id}}"><img style="width: 100%; height: 100%; object-fit: cover;" src="{{filepath}}/actualitiesimgs/{{actuality?.image}}"></a>
	</div>
	<div class="body-content">
		<h5 class="card-title">{{actuality?.title}}</h5>
		<p class="card-text description">{{actuality?.description}}</p>
	</div>
</div>

<!-- Show l'image de l'Actualité Modal -->
<div class="modal fade" id="showimgvisitor{{actuality?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content moremodal">
      <div class="modal-body px-0 pb-0 pt-0" style="overflow: hidden;">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <img style="width: 100%; border-radius: 4px;" src="{{filepath}}/actualitiesimgs/{{actuality?.image}}">
      </div>
    </div>
  </div>
</div>
