import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Filiere } from '../../../../../models/Filiere';
import { UserdataService } from '../../../../../services/userdata.service';
import { User } from '../../../../../models/User';
import { Inscription } from '../../../../../models/Inscription';
import { SelectService } from '../../../../../services/select.service';
import { AddService } from '../../../../../services/add.service';
import { MessageService } from '../../../../../services/message.service';
import { DeleteService } from '../../../../../services/delete.service';
import { UpdateService } from '../../../../../services/update.service';

@Component({
  selector: 'app-filiere-item',
  templateUrl: './filiere-item.component.html',
  styleUrls: ['./filiere-item.component.css']
})
export class FiliereItemComponent implements OnInit {
  @ViewChild('closebuttonI') closebuttonI: any;
  @ViewChild('closebuttonII') closebuttonII: any;

  @Input() filiere: Filiere;
  user: User;
  inscriptionverif: Inscription[] = [];
  verif: boolean = false;
  c: any = 0;

  public inscritform = {

  	filiere_id: '',
    niveau: '',
    nom: '',
    postnom: '',
    prenom: '',
    nationality: '',
    passportcin: '',
    email: '',
    tlf: '',
    diplome: '',
    status: 0
  };

  filiereupdate = {
    id: '',
    name: '',
    description: '',
    years_number: ''
  }

  formSubmitted: boolean = false;
  currentusermail: any;

  constructor(
  	private userdataservice: UserdataService,
  	private add: AddService,
    private select: SelectService,
    private msg: MessageService,
    private deleteservice: DeleteService,
    private updateservice: UpdateService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.inscritform.filiere_id = this.filiere.filiere_id;


    this.filiereupdate.id = this.filiere.id;
    this.filiereupdate.name = this.filiere.name;
    this.filiereupdate.description = this.filiere.description;
    this.filiereupdate.years_number = this.filiere.years_number;

    this.select.searchInscrit(this.currentusermail).subscribe(res => {
      this.inscriptionverif = res;
      if(this.inscriptionverif.length > 0) {
        do {
          if(this.inscriptionverif[this.c].filiere_id == this.filiere.filiere_id && this.inscriptionverif[this.c].email == this.currentusermail) {
            this.verif = true;
            this.c++;
          }
          else {
            this.verif = false;
            this.c++;
          }
        } while (this.verif == false && this.c < this.inscriptionverif.length);
      }
    });

  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];

  		this.inscritform.nom = this.user.lname;
  		this.inscritform.prenom = this.user.fname;
  		this.inscritform.nationality = this.user.nationality;
  		this.inscritform.passportcin = this.user.passport;
  		this.inscritform.email = this.user.email;
  		this.inscritform.tlf = this.user.phone;
  	});

  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  Inscription(e) {
  	this.formSubmitted = true;
  	e.preventDefault();

    this.add.Inscrit('', this.inscritform.filiere_id, this.inscritform.niveau, this.inscritform.nom, this.inscritform.postnom, this.inscritform.prenom, this.inscritform.nationality, this.inscritform.passportcin, this.inscritform.email, this.inscritform.tlf, this.inscritform.diplome, this.inscritform.status, null).subscribe(res => {
      this.closebuttonI.nativeElement.click();
      setTimeout(function() {
        console.log('hello');
      }, 1000);

      this.msg.setMessage('an other operation');


    	this.inscritform.niveau = '';
    	this.inscritform.postnom = '';
    	this.inscritform.diplome = '';
    });
  }

  delete() {
    this.deleteservice.deleteData('filieres', this.filiere.id).subscribe(res => {
      this.msg.setMessage('an other operation');
    });
  }

  update() {
   this.updateservice.updateFiliere('filieres', this.filiereupdate).subscribe((data) => {
      this.msg.setMessage('an other operation done');
      this.closebuttonII.nativeElement.click();
    });
  }

}
