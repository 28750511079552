<div class="row col-12 p-0 mx-0 mb-4 bg-white shadow border-top border-bottom pubitembox" style="position: relative;">

	<!-- Editeur information -->
	<div class="editeurinfo col-12 mx-0 px-1 py-0 d-flex border-bottom">
		<div class="d-flex">
			<div class="editeur-avatar">

				<img src="assets/img/avatar.png" *ngIf="useritem?.avatar == null">
				<img src="{{filepath}}/profileAvatars/{{useritem?.avatar}}" *ngIf="useritem?.avatar != null">
			</div>
			<span>{{useritem?.fname}}</span> <span>{{useritem?.lname}}</span>
			<small *ngIf="useritem?.role == 'admin'">(Editeur)</small>
		</div>
		<div *ngIf="role != 'admin'" style="background: transparent; line-height: 40px; margin: 0 0 0 auto;">
			<button type="button" class="btn btn-sm btn-light text-gaj" style="margin: auto 0;" (click)="addtofavoris($event)" *ngIf="searchFavori == false"><i class="far fa-bookmark"></i></button>
			<button type="button" class="btn btn-sm btn-light text-gaj" style="margin: auto 0;" (click)="deletefavoris()" *ngIf="searchFavori == true"><i class="fas fa-bookmark"></i></button>
		</div>
		<div class="gererpubbtns"  *ngIf="role == 'admin'">
			<a class="btn btn-sm btn-light text-primary border-primary shadow" data-toggle="modal" href="#editpub{{publication?.id}}"><i class="fas fa-edit"></i></a>
			<a class="btn btn-sm btn-light text-danger border-danger shadow" data-toggle="modal" href="#deletepub{{publication?.id}}"><i class="fas fa-times"></i></a>
		</div>
	</div>

	<div id="pubupdatesuccess{{publication?.id}}" class="alert alert-success py-1 text-center col-12" role="alert" style="display: none; height: auto;">
	  Mise à jour du publication réussie!
  	</div>



	<!-- publication description -->
	<div class="description col-12 m-0 bg-gaj text-white border-bottom">
		{{publication?.description}}
	</div>
	<!-- publication media files -->
	<div class="col-12 media-row d-flex border-bottom p-2">
		<div style="min-width: 120px; max-width: 120px; height: 100%; background: transparent; display: flex; flex-direction: column;" *ngIf="role == 'admin'">
			<div>
				<input type="file" class="form-control-file" id="imagefile{{publication?.id}}" name="imagefile" (change)="onFileSelected($event, 'img')"><!--   (change)="onImageSelected($event)"   [(ngModel)]="imagefile" -->
			    <label for="imagefile{{publication?.id}}" class="label-for-file"><i class="far fa-2x fa-image"></i></label>
			</div>
			<div>
				<input type="file" class="form-control-file" id="videofile{{publication?.id}}" name="videofile" (change)="onFileSelected($event, 'video')"><!--   (change)="onVideoSelected($event)"   [(ngModel)]="videofile" -->
			    <label for="videofile{{publication?.id}}" class="label-for-file"><i class="fas fa-2x fa-film"></i></label>
			</div>
		</div>

		<div *ngFor="let file of files">
        	<app-publication-files [file]="file"></app-publication-files>
        </div>

		<div style="position: relative;" *ngIf="publication?.image != null">
			<div style="top: 4px; left: 8px; position: absolute;" *ngIf="role == 'admin'">
				<!--<button class="btn btn-sm btn-primary mr-1"><i class="fas fa-pen"></i></button>-->
				<button class="btn btn-sm btn-danger" (click)="DeleteInitPic(publication?.id)"><i class="fas fa-times"></i></button>
			</div>
			<a data-toggle="modal" href="#showpubimg{{publication?.id}}">
				<img class="mx-1" src="{{filepath}}/publicationsimgs/{{publication?.image}}" style="height: 140px;">
			</a>
		</div>

		<div style="position: relative;" *ngIf="publication?.video != null">
			<div style="top: 4px; left: 8px; position: absolute; z-index: 1000;" *ngIf="role == 'admin'">
				<!--<button class="btn btn-sm btn-primary mr-1"><i class="fas fa-pen"></i></button>-->
				<button class="btn btn-sm btn-danger" (click)="DeleteInitVideo(publication?.id)"><i class="fas fa-times"></i></button>
			</div>
			<a data-toggle="modal" href="#showvideo{{publication?.id}}"><video src="{{filepath}}/publicationsvideos/{{publication?.video}}" class="mx-1" controls style="height: 140px;" *ngIf="publication?.video != null">
          	Sorry, your browser doesn't support embedded videos.
        	</video></a>
		</div>



		<div class="alert alert-primary col-md-8 text-center mx-auto" role="alert" *ngIf="publication?.image == null && publication?.video == null && files.length == 0">
		  <h6 class="alert-heading" style="margin-top: 30px;">Aucun(e) photo/video à afficher!</h6>
		</div>
	</div>
	<!-- publication comments section -->
	<div class="commentform bg-light pb-5">
		<form (submit)="AddComment($event)" autocomplete="off" class="d-flex">
			<div class="comment-user-avatar">
				<img src="assets/img/avatar.png" *ngIf="currentuser?.avatar == null">
				<img src="{{filepath}}/profileAvatars/{{currentuser?.avatar}}" *ngIf="currentuser?.avatar != null">
			</div>
			<textarea name="comment" class="form-control shadow-sm" placeholder="Commenter. . ." [(ngModel)]="commentform.commenttext" rows="1"></textarea>
			<button type="submit" class="btn btn-sm btn-light"><i class="fab text-gaj fa-telegram-plane"></i></button>
		</form>
	</div>
	<div class="row px-3 py-1 mx-0 col-12 justify-content-between">
		<span>Commentaires ({{comments.length}})</span> <small class="ml-3 my-auto"><a data-toggle="collapse" href="#collapseExample{{publication?.id}}" role="button" aria-expanded="false" aria-controls="collapseExample">Voir les commentaires</a></small>
	</div>
	<div class="collapse col-12 m-0 p-0 show" id="collapseExample{{publication?.id}}">
	  <div class="card col-12 card-body p-0 m-0 comments-container">
	    <div class="comments-content" *ngFor="let comment of comments">
			<app-comment-item [comment]="comment"></app-comment-item>
		</div>
		<div class="alert alert-info text-center mx-3 my-3" role="alert" *ngIf="comments.length == 0">
		  aucun commentaire à afficher!
		</div>
	  </div>
	</div>


	<!--<div class="col-12 comments-container my-1">
		<div class="row comments-content col-12" *ngFor="let comment of comments">
			<app-comment-item [comment]="comment"></app-comment-item>
		</div>
		<div class="alert alert-info text-center my-3" role="alert" *ngIf="comments.length == 0">
		  aucun commentaire à afficher!
		</div>
	</div>-->
</div>

<!-- Edit Publication Modal -->
<div class="modal fade" id="editpub{{publication?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <form class="mt-3" (submit)="updatepublication()" autocomplete="off">

			<div class="row">
				<div class="form-group col-sm-8">
					<h5>Modifier l'annonce</h5>
				</div>
				<div class="form-group col-sm-4">
					<select class="form-control form-control-sm col-12" name="category" [(ngModel)]="pubform.category" required>
						<option value="stage">Stages</option>
						<option value="job">Jobs</option>
						<option value="appartement">Appartements</option>
						<option value="meuble">Meubles</option>
						<option value="event">Evenements</option>
						<option value="flashinfo">Flash Info</option>
					</select>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-sm-12">
				    <label for="description">Description</label>
				    <textarea class="form-control" rows="3" name="description"[(ngModel)]="pubform.description" required></textarea>
				</div>
			</div>
			<div class="row mx-0 px-0 col-sm-12">
				<button type="submit" class="ml-auto btn btn-sm btn-block btn-gaj">
					Modifier
				</button>
			</div>

        </form>
      </div>
    </div>
  </div>
</div>

<!-- Delete Publication Modal -->
<div class="modal fade" id="deletepub{{publication?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel">Confirmer la Suppression</h6>
        <button type="button" #closedeletebutton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      	<!--<div id="pubdeletesuccess{{publication?.id}}" class="alert alert-success py-1 text-center" role="alert" style="display: none;">
		  {{deleteresponse}}
		</div>-->
        <div class="row mx-0 col-12">
        	<button type="button" class="btn btn-secondary ml-auto mr-1" data-dismiss="modal">Annuler</button>
        	<button  type="button" class="btn btn-danger ml-1 mr-auto" (click)="Delete()">Supprimer</button>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Show the init image Modal -->
<div class="modal fade" id="showpubimg{{publication?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body" style="padding: 0; width: auto; background: transparent;">
      	<button type="button" #closebuttonimg class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
		<div style="position: relative;">
			<div style="top: 8px; left: 8px; position: absolute; z-index: 10000;" *ngIf="role == 'admin'">
				<button class="btn btn-sm btn-danger" (click)="DeleteInitPic(publication?.id)">Supprimer</button>
			</div>
			<img src="{{filepath}}/publicationsimgs/{{publication?.image}}" style="width: 100%;" *ngIf="publication?.image != null">
		</div>
      </div>
    </div>
  </div>
</div>



<!-- Show the init video Modal -->
<div class="modal fade" id="showvideo{{publication?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body p-0" style="padding: 0; width: auto; background: transparent;">
      	<button type="button" #closebuttonvideo class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
		<div style="position: relative;">
			<div style="top: 8px; left: 8px; position: absolute; z-index: 10000;" *ngIf="role == 'admin'">
				<button class="btn btn-sm btn-danger" (click)="DeleteInitVideo(publication?.id)">Supprimer</button>
			</div>
			<video src="{{filepath}}/publicationsvideos/{{publication?.video}}" controls style="width: 100%; height: auto; margin-bottom: -20px;" *ngIf="publication?.video != null">
			Sorry, your browser doesn't support embedded videos.
			</video>
		</div>

      </div>
    </div>
  </div>
</div>

