import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Message1Service } from '../../../services/message1.service';
import { AddService } from '../../../services/add.service';

@Component({
  selector: 'app-add-actuality-form',
  templateUrl: './add-actuality-form.component.html',
  styleUrls: ['./add-actuality-form.component.css']
})
export class AddActualityFormComponent implements OnInit {
  
  public actform = {
    actid: null,
  	email: null,
    title: null,
    image: null,
    description: null,
    show: null
  };

  selectedImageFile: File = null;

  formSubmitted: boolean = false;
  currentusermail: any;

  constructor(
  	private http: HttpClient,
    private msg1: Message1Service,
    private addservice: AddService
    ) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.actform.email = this.currentusermail;
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  onImageSelected(event) {
    this.selectedImageFile = <File>event.target.files[0];
    console.log(this.selectedImageFile);
  }

  AddActuality(e) {
    e.preventDefault();

    this.actform.actid = '';

    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const l = 16;
    let randomstring = '';
    for (let i=0; i<l; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring = randomstring + chars.substring(rnum, rnum+1);
    }

    this.actform.actid = this.actform.actid + randomstring;


    this.actform.show = 1;
    const fd = new FormData();

    fd.append('actid', this.actform.actid);
    fd.append('email', this.actform.email);
    fd.append('title', this.actform.title);
    fd.append('image', this.selectedImageFile);
    fd.append('description', this.actform.description);
    fd.append('show', this.actform.show);

    this.addservice.addActuality(fd).subscribe(res => {
    	console.log(res);
    	this.msg1.setMessage("new operation");
      document.getElementById('addactualitysuccess').style.display = "block";

      setTimeout(function() {
        document.getElementById('addactualitysuccess').style.display = "none";
      }, 8000);

      this.actform.actid = '';
    	this.actform.title = '';
      this.actform.description = '';
      this.actform.image = null;
      this.selectedImageFile = null;
    });
  }

}
