import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

@Component({
  selector: 'app-request-reset',
  templateUrl: './request-reset.component.html',
  styleUrls: ['./request-reset.component.css']
})
export class RequestResetComponent implements OnInit {
  
  public form = {
    email: null
  };

  public error = [];

  constructor(
    private authentication: AuthenticationService
  ) { }

  ngOnInit(): void {
  }

  onSubmit() {
    this.authentication.sendPasswordResetLink(this.form).subscribe(
        data => this.handleResponse(data),
        error => this.error = error.error.error
      );
    }
  
    handleResponse(res) {
    console.log(res);
    this.form.email = null;
    }

}
