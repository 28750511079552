<!--<div class="not-auth-navbar" style="height: 50px; background: red; top: 0; left: 0; right: 0; position: fixed;">
	
</div>-->

<nav class="navbar fixed-top navbar-expand-lg navbar-dark notauthnavbaritem" id="notauthnavbar">
	<div class="container-lg">
		<a class="navbar-brand" routerLink="/accueil">
			<img src="assets/logos/Logo.png">
		</a>
	  <button class="navbar-toggler " style="color: #fff;" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
	    <span class="navbar-toggler-icon"></span>
	  </button>

	  <div class="collapse navbar-collapse" id="navbarSupportedContent">
	    <ul class="navbar-nav ml-auto">
	      <li class="nav-item">
	        <a class="nav-link" routerLink="/accueil">Acceuil <span class="sr-only">(current)</span></a>
	      </li>
		  <!--<li class="nav-item" [routerLink]="['']" fragment="actualités">
	        <a class="nav-link" href="#actualités">Actualités</a>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link" href="#"></a>
	      </li>-->
		  <!--<li class="nav-item" [routerLink]="['']" fragment="publications">
	        <a class="nav-link" href="#publications">Publications</a>
	      </li>
		  <li class="nav-item" [routerLink]="['']" fragment="cycles">
	        <a class="nav-link" href="#cycles">Cycles</a>
	      </li>
	      <li class="nav-item" [routerLink]="['']" fragment="gallerie">
	        <a class="nav-link" href="#gallerie">Gallerie</a>
	      </li>
		  <li class="nav-item" [routerLink]="['']" fragment="partenaires">
	        <a class="nav-link" href="#partenaires">Partenaires</a>
	      </li>-->
	      
	      <li class="nav-item dropdown">
	        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				A propos
	        </a>
	        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
	          <a class="dropdown-item" routerLink="/presentation">Présentation</a>
	          <a class="dropdown-item" routerLink="/departements">Départements</a>
			  <div class="dropdown-divider"></div>
			  <a class="dropdown-item" href="actualities">Actualités</a>
			  <a class="dropdown-item" href="publications">Publications</a>
			  <a class="dropdown-item" href="#partenaires">Partenaires</a>
	          <a class="dropdown-item"  href="#contacter-nous">Contactez-Nous</a>
			  <a class="dropdown-item"  href="#faqs">Questions Fréquentes</a>
			  <!--<a class="dropdown-item"  routerLink="/aides">Aides</a>-->
	        </div>
	      </li>
		  <li class="nav-item">
	        <a class="nav-link" routerLink="/frais">Nos services</a>
	      </li>
		  <li class="nav-item" [routerLink]="['']" fragment="gallerie">
	        <a class="nav-link" href="#gallerie">Galerie</a>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link"  data-toggle="modal" href="#authmodal" style="min-width: 120px;">Se Connecter</a>
	      </li>
	    </ul>
	  </div>
	</div>
</nav>


<!-- Modal -->
<div class="modal fade" id="authmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content radius-0">
      <div class="modal-body p-0">
        <div class="close-auth">
        	<button type="button" #closebutton class="close text-white" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
		  <li class="nav-item text-dark text-center" role="presentation">
		    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Se Connecter a</a>
		  </li>
		  <li class="nav-item text-dark text-center" role="presentation">
		    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">S'inscrire</a>
		  </li>
		</ul>
		<div class="tab-content p-2" id="myTabContent">
		  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
		  	<form #loginForm=ngForm (ngSubmit)="onSubmit()">
		  	  <div class="alert alert-danger" role="alert" [hidden]="!error">
	            {{error}}
	          </div>
		  	  <div class="form-group">
			    <label for="loginemail">Address email</label>
			    <input type="email" class="form-control form-control-sm" name="email" id="hloginemail" placeholder="Address email" [(ngModel)]="loginform.email" required>
			  </div>
			  <div class="form-group">
			    <label for="loginpassword">Mot de passe</label>
			    <input type="password" class="form-control form-control-sm" name="password" id="loginpassword" placeholder="Mot de passe" [(ngModel)]="loginform.password" required>
			  </div>
			  <button type="submit" class="btn btn-block btn-gaj" [disabled]="!loginForm.valid">Se Connecter</button>
		  	</form>
		  </div>
		  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
		  	<form [formGroup]="form" #signUpForm=ngForm (submit)="onSignup()">

		  		<div class="col-12 alert alert-sm alert-danger" role="alert" *ngIf="emailerror.length != 0">
				  {{emailerror}}
				</div>
				<div class="col-12 alert alert-sm alert-danger" role="alert" *ngIf="passworderror.length != 0">
				  {{passworderror}}
				</div>

		  		<div class="row">
		  		  <div class="col-sm-6 form-group">
				    <label for="lname">Nom</label>
				    <input formControlName="lname" type="text" class="form-control form-control-sm" id="lname" name="lname" placeholder="Nom" required>
				  </div>
			  	  <div class="col-sm-6 form-group">
				    <label for="fname">Prénom</label>
				    <input formControlName="fname" type="text" class="form-control form-control-sm" id="fname" name="fname" placeholder="Nom et Prénom" required>
				  </div>
				</div>
				<div class="form-group">
			      <label for="status">Type d'Utilisateur</label>
			      <select formControlName="usertype" name="usertype" class="form-control form-control-sm" id="status" required>
			        <option value="etudiant">Etudiant(e)</option>
			        <option value="stagiaire">Stagiaire</option>
			        <option value="abonne">Abonné(e)</option>
			      </select>
			    </div>
		  	  <div class="form-group">
			    <label for="registeremail">Address email</label>
			    <input formControlName="email" type="email" class="form-control form-control-sm" id="registeremail" name="registeremail" placeholder="Address email" required>
			  </div>
			  <div class="row">
			  	<div class="col-sm-6 form-group">
				    <label for="password">Mot de passe</label>
				    <input formControlName="password" type="password" class="form-control form-control-sm" name="password" placeholder="Mot de passe" required>
				</div>
				<div class="col-sm-6 form-group">
				    <label for="cpassword">Confirmer le Mot de passe</label>
				    <input formControlName="password_confirmation" type="password" class="form-control form-control-sm" name="cpassword" placeholder="Confirmer le Mot de passe" required>
				</div>
			  </div>
			  <button type="submit" class="btn btn-block btn-gaj" [disabled]="!signUpForm.valid">S'inscrire</button><!--   -->
		  	</form>
		  </div>
		</div>

      </div>
    </div>
  </div>
</div>



<!--<nav class="navbar fixed-top navbar-expand-lg navbar-dark notauthnavbaritem" id="notauthnavbaritem">
	<div class="container-lg">
		<a class="navbar-brand" routerLink="/accueil">
			<img src="assets/logos/Logo.png">
		</a>
	  <button class="navbar-toggler " style="color: #fff;" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
	    <span class="navbar-toggler-icon"></span>
	  </button>

	  <div class="collapse navbar-collapse" id="navbarSupportedContent">
	    <ul class="navbar-nav ml-auto">
	      <li class="nav-item" [routerLink]="['']" fragment="section1">
	        <a class="nav-link" href="#section1">Acceuil <span class="sr-only">(current)</span></a>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link" href="#">Cycles</a>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link" href="#">Gallerie</a>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link" href="#">Partenaires</a>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link" href="#">Publications</a>
	      </li>
	      <li class="nav-item dropdown">
	        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
	          Présentation
	        </a>
	        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
	          <a class="dropdown-item" href="#">Action</a>
	          <a class="dropdown-item" href="#">Another action</a>
	          <div class="dropdown-divider"></div>
	          <a class="dropdown-item" href="#">Something else here</a>
	        </div>
	      </li>
	      <li class="nav-item">
	        <a class="nav-link"  data-toggle="modal" href="#authmodal" style="min-width: 120px;">Se Connecter</a>
	      </li>
	    </ul>
	  </div>
	</div>
</nav>-->


<!-- Modal -->
<div class="modal fade" id="authmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content radius-0">
      <div class="modal-body p-0">
        <div class="close-auth">
        	<button type="button" #closebutton class="close text-white" data-dismiss="modal" aria-label="Close">
	          <span aria-hidden="true">&times;</span>
	        </button>
        </div>

        <ul class="nav nav-tabs" id="myTab" role="tablist">
		  <li class="nav-item text-dark text-center" role="presentation">
		    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Se Connecter a</a>
		  </li>
		  <li class="nav-item text-dark text-center" role="presentation">
		    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">S'inscrire</a>
		  </li>
		</ul>
		<div class="tab-content p-2" id="myTabContent">
		  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
		  	<form #loginForm=ngForm (ngSubmit)="onSubmit()">
		  	  <div class="alert alert-danger" role="alert" [hidden]="!error">
	            {{error}}
	          </div>
		  	  <div class="form-group">
			    <label for="loginemail">Address email</label>
			    <input type="email" class="form-control form-control-sm" name="email" id="hloginemail" placeholder="Address email" [(ngModel)]="loginform.email" required>
			  </div>
			  <div class="form-group">
			    <label for="loginpassword">Mot de passe</label>
			    <input type="password" class="form-control form-control-sm" name="password" id="loginpassword" placeholder="Mot de passe" [(ngModel)]="loginform.password" required>
			  </div>
			  <button type="submit" class="btn btn-block btn-gaj" [disabled]="!loginForm.valid">Se Connecter</button>
		  	</form>
		  </div>
		  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
		  	<form [formGroup]="form" #signUpForm=ngForm (submit)="onSignup()">

		  		<div class="col-12 alert alert-sm alert-danger" role="alert" *ngIf="emailerror.length != 0">
				  {{emailerror}}
				</div>
				<div class="col-12 alert alert-sm alert-danger" role="alert" *ngIf="passworderror.length != 0">
				  {{passworderror}}
				</div>

		  		<div class="row">
			  	  <div class="col-sm-6 form-group">
				    <label for="fname">Nom et Prénom</label>
				    <input formControlName="fname" type="text" class="form-control form-control-sm" id="fname" name="fname" placeholder="Nom et Prénom" required>
				  </div>
				  <div class="col-sm-6 form-group">
				    <label for="lname">Nom</label>
				    <input formControlName="lname" type="text" class="form-control form-control-sm" id="lname" name="lname" placeholder="Nom" required>
				  </div>
				</div>
		  	  <div class="form-group">
			    <label for="registeremail">Address email</label>
			    <input formControlName="email" type="email" class="form-control form-control-sm" id="registeremail" name="registeremail" placeholder="Address email" required>
			  </div>
			  <div class="row">
			  	<div class="col-sm-6 form-group">
				    <label for="password">Mot de passe</label>
				    <input formControlName="password" type="password" class="form-control form-control-sm" name="password" placeholder="Mot de passe" required>
				</div>
				<div class="col-sm-6 form-group">
				    <label for="cpassword">Confirmer le Mot de passe</label>
				    <input formControlName="password_confirmation" type="password" class="form-control form-control-sm" name="cpassword" placeholder="Confirmer le Mot de passe" required>
				</div>
			  </div>
			  <button type="submit" class="btn btn-block btn-gaj" [disabled]="!signUpForm.valid">S'inscrire</button><!--   -->
		  	</form>
		  </div>
		</div>

      </div>
    </div>
  </div>
</div>

<div class="go-top-btn" id="gotopbtn" onclick="gotopfunction()" >
	<i class="fas fa-chevron-up"></i>
</div>