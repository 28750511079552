import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publication } from '../models/Publication';
import { Actuality } from '../models/Actuality';
import { Comment } from '../models/Comment';
import { CommentResponse } from '../models/CommentResponse';
import { Cycle } from '../models/Cycle';
import { Filiere } from '../models/Filiere';
import { Inscription } from '../models/Inscription';
import { Notification } from '../models/Notification';
import { Favoris } from '../models/Favoris';
import { Partenaire } from '../models/Partenaire';
import { GalleryFile } from '../models/GalleryFile';
import { Message } from '../models/Message';
import { ContactUs } from '../models/ContactUs';
import { Faq } from '../models/Faq';
import { Terms } from '../models/Terms';
import { InscriptFile } from '../models/InscriptFile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AddService {

  server: string = environment.api;

  constructor(private http: HttpClient) {}

  addPublication(id, pubid, email, category, description, image, video, show) {
  	const newPublication = new Publication(id, pubid, email, category, description, image, video, show);
  	return this.http.post<Publication>(this.server + '/addPublication', newPublication);
  }

  addActuality(fd) {
  	//const newActuality = new Actuality(id, email, title, image, description, show);
  	return this.http.post<Actuality>(this.server + '/addActuality', fd);
  }

  addComment(id, pub_id, user_email, commenttext, created_at) {
    const newComment = new Comment(id, pub_id, user_email, commenttext, created_at);
    return this.http.post<Comment>(this.server + '/addComment', newComment);
  }
  addCommentResponse(id, comment_id, email, commenttext, created_at) {
    const newComment = new CommentResponse(id, comment_id, email, commenttext, created_at);
    return this.http.post<CommentResponse>(this.server + '/addCommentResponse', newComment);
  }

  addtofavoris(id, email, pubid) {
    const newFavori = new Favoris(id, email, pubid);
    return this.http.post<Favoris>(this.server+'/addtofavoris', newFavori);
  }

  addCycle(id, email, cycle_id, name, description) {
    const newCycle = new Cycle(id, email, cycle_id, name, description);
    return this.http.post<Cycle>(this.server + '/addCycle', newCycle);
  }

  addFiliere(id, cycle_id, email, filiere_id, name, description, years_number) {
    const newFiliere = new Filiere(id, cycle_id, email, filiere_id, name, description, years_number);
    return this.http.post<Filiere>(this.server + '/addFiliere', newFiliere);
  }

  Inscrit(id, filiere_id, niveau, nom, postnom, prenom, nationality, passportcin, email, tlf, diplome, status, created_at) {
    const inscription = new Inscription(id, filiere_id, niveau, nom, postnom, prenom, nationality, passportcin, email, tlf, diplome, status, created_at);
    return this.http.post<Inscription>(this.server + '/inscription', inscription);
  }

  addNotifRow(id, useremail, actuality, officiel, gajconsulting, gajTraining, gajScholarship, gajFablab, gajMagazine) {
    const newNotif = new Notification(id, useremail, actuality, officiel, gajconsulting, gajTraining, gajScholarship, gajFablab, gajMagazine);
    return this.http.post<Notification>(this.server + '/addNotif', newNotif);
  }


  addPartenaire(fd) {
    return this.http.post<Partenaire>(this.server + '/addPartenaire', fd);
  }

  addGalleryFile(fd) {
    return this.http.post<GalleryFile>(this.server + '/addGalleryFile', fd);
  }



  addTextMsg(id, sendermail, senderid, receiverid, text, file, fileformat, created_at): Observable<Message> {
    const newTextMsg = new Message(id, sendermail, senderid, receiverid, text, file, fileformat, created_at);
    return this.http.post<Message>(this.server + '/addTextMsg', newTextMsg);
  }

  contactus(id, name, email, message, status, created_at) {
    const newMsg = new ContactUs(id, name, email, message, status, created_at);
    return this.http.post<ContactUs>(this.server+'/addContactus', newMsg);
  }

  addfaq(id, question, response, status) {
    const newFaq = new Faq(id, question, response, status);
    return this.http.post<Faq>(this.server+'/addFaq', newFaq);
  }

  addTerms(id, termsfile) {
    const newTerms = new Terms(id, termsfile);
    return this.http.post<Terms>(this.server+'/addFaq', newTerms);
  }

  uploadInscriptFile(data) {
    return this.http.post<InscriptFile>(this.server+'/uploadInscriptFile', data);
  }
}
