<router-outlet></router-outlet>

<!--<ng-http-loader
[backdrop]="true"
    [backgroundColor]="'#f7f8ff'"
    [debounceDelay]="100"
    [extraDuration]="300"
    [minDuration]="300"
    opacity=".8"
    [backdropBackgroundColor]="'#00000088'"
[spinner]="spinkit.skWanderingCubes"
></ng-http-loader>-->
<ng-http-loader></ng-http-loader>
