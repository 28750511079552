import { Component, OnInit, Input } from '@angular/core';
import { Message } from 'src/app/models/Message';
import { User } from 'src/app/models/User';
import { UserdataService } from 'src/app/services/userdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-messenger-item',
  templateUrl: './messenger-item.component.html',
  styleUrls: ['./messenger-item.component.css']
})
export class MessengerItemComponent implements OnInit {

  @Input() messenger: Message;
  user: User;

  filepath = environment.filepath;

  messengerId: string = '';

  isAdmin = false;

  constructor(
    private userdata: UserdataService
  ) { }

  ngOnInit(): void {
    this.messengerId = this.messenger.toString();
    this.userdata.getUserDataByUserId(this.messenger.toString()).subscribe(res => {
      if(res[0].role == 'admin') {
        this.isAdmin = true
      } else {
        this.isAdmin = false;
      }
      this.user = res[0];
    });
  }

}
