<div class="top-img-section">
	<div class="bienvenue-top"><p>Page <strong>Actualités</strong></p></div>
</div>
<div class="publications-section container-fluid">
	<div class="p-1 pub-item" *ngFor="let actuality of actualities">
		<div class="">
			<app-act-item [actuality]="actuality"></app-act-item>
		</div>
	</div>
</div>
