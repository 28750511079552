import { Component, OnInit } from '@angular/core';
import { UserdataService } from '../../services/userdata.service';
import { User } from '../../models/User';
import { Publication } from '../../models/Publication';
import { MessageService } from '../../services/message.service';
import { AddService } from '../../services/add.service';
import { HttpClient } from '@angular/common/http';
import { SelectService } from '../../services/select.service';
import { Favoris } from 'src/app/models/Favoris';

@Component({
  selector: 'app-publications',
  templateUrl: './publications.component.html',
  styleUrls: ['./publications.component.css']
})
export class PublicationsComponent implements OnInit {
  
  user: User;
  role: any;
  currentusermail: any;

  publications: Publication[] = []; /* the inverse of pubs */
  initpublications: Publication[] = [];

  i: any = 0;
  j: any = 0;
  
  polling: any;

  allFavoris: Favoris[] = [];

  constructor(
    private userdataservice: UserdataService,
    private addservice: AddService,
    private http: HttpClient,
    private selectservice: SelectService,
    private msg: MessageService
    ) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  		this.role = this.user.role; 
  	});

    this.selectservice.selectUserFavorite(this.currentusermail).subscribe(data => {
      this.allFavoris = data;
    });

    this.selectservice.getAll('publications').subscribe((pubs) => {
        this.publications = pubs.reverse();
        this.initpublications = pubs.reverse();
    });


    //this.consulta();
    //this.pollData();

    /*do {
      setTimeout(function() {
        this.msg.getMessage().subscribe((pubs) => {
          this.selectservice.getAll('publications').subscribe((pubs) => {
            this.publications = pubs.reverse();
          }); 
        });
      },5000)
      this.verif = true;
    } while (this.verif == true);*/

    this.msg.getMessage().subscribe((pjct) => {
      this.publications = []
      this.selectservice.getAll('publications').subscribe((pubs) => {
        this.publications = pubs.reverse();
      }); 

      this.selectservice.selectUserFavorite(this.currentusermail).subscribe(data => {
        this.allFavoris = data;
        console.log(this.allFavoris);
      });
    });
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  /*pollData() {
    this.polling = setInterval(() => {
      this.consulta();
    }, 5000);
  }

  consulta() {
    //this.publications = [];
    this.selectservice.getAll('publications').subscribe((pubs) => {
      if(this.publications.length != pubs.length) {
        this.publications = [];
        this.publications = pubs.reverse();
      }
    }); 
  }

  ngOnDestroy() {
    clearInterval(this.polling);
  }*/

  filter($category) {
    if($category == 'all') {
      this.publications = this.initpublications;
    } else {
      this.publications = [];
      this.j = 0;
      for(this.i = 0; this.i < this.initpublications.length; this.i++) {
        if(this.initpublications[this.i].category == $category) {
          this.publications[this.j] = this.initpublications[this.i];
          this.j++;
        }
      }
    }
  }

}
