<div *ngIf="role == 'admin'">
  <div class="row col-12 mx-0 px-0 justify-content-end">
    <label for="addpubbtn" class="addpublicbtn">
      <button type="button" id="addpubbtn" class="btn ml-auto btn-sm btn-gaj add-pub-btn" data-toggle="modal" data-target="#addActuality"><i class="fas fa-plus"></i></button>
      <span>Ajouter</span>
    </label>
  </div>
</div>




<!-- Add Actualité Modal -->
<div class="modal fade" id="addActuality" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <app-add-actuality-form></app-add-actuality-form>
      </div>
    </div>
  </div>
</div>

<div class="alert col-md-8 mx-auto text-center alert-info mt-5" role="alert" *ngIf="actualities.length == 0">
  Il n'y a pas encore d'actualités
</div>

<div class="col-lg-8 mx-auto bg-white col-md-10 col-sm-12  py-5 px-0" *ngIf="actualities.length != 0">
	<div class="mx-0" *ngFor="let actuality of actualities">
		<app-actuality-item [actuality]="actuality"></app-actuality-item>
	</div>
</div>