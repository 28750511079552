<div class="messagingcontainer d-flex">
	<div style="display: flex; flex-direction: column; position: relative; width: 100%">
		<div id="msgbox">

			<div *ngFor="let message of uxMessages">
				<div class="msg-item-student relative" *ngIf="message?.senderid == user?.userid && message?.msg != ''">
					<p>
            {{message?.created_at | date: "dd MMM, HH:mm"}}
            <span *ngIf="message?.seen == false"><i class="far fa-circle text-secondary"></i></span>
            <span *ngIf="message?.seen == true"><i class="fas fa-check-circle text-gaj"></i></span>
          </p>
					<div class="studentmsgtext">
						{{message?.msg}}
					</div>
				</div>
				<div class="msg-item-admin" *ngIf="message?.senderid != user?.userid && message?.msg != ''">
					<p>{{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="adminmsgtext">
						{{message?.msg}}
					</div>
				</div>

				<div class="msg-item-student relative" *ngIf="message?.senderid == user?.userid && message?.file != null && message?.fileType == 'img'">
					<p> {{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="adminstudentmsgtext">
						<img style="width: 100%;" src="{{message?.file}}">
					</div>
				</div>
				<div class="msg-item-admin" *ngIf="message?.senderid != user?.userid && message?.file != null && message?.fileType == 'img'">
					<p>{{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="fileadminmsgtext">
						<img style="width: 100%;" src="{{message?.file}}">
					</div>
				</div>

				<div class="msg-item-student relative" *ngIf="message?.senderid == user?.userid && message?.file != null && message?.fileType == 'video'">
					<p> {{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="adminstudentmsgtext">
						<video  style="width: 100%;" src="{{message?.file}}" controls></video>
					</div>
				</div>
				<div class="msg-item-admin" *ngIf="message?.senderid != user?.userid && message?.file != null && message?.fileType == 'video'">
					<p>{{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="fileadminmsgtext">
						<video  style="width: 100%;" src="{{message?.file}}" controls></video>
					</div>
				</div>

        <div class="msg-item-student relative" *ngIf="message?.senderid == user?.userid && message?.file != null && message?.fileType == 'doc'">
					<p> {{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="studentmsgtext">
						<a target="_blank" href="{{message?.file}}" style="width: 100%; color: #fff;">document-{{message?.created_at}}</a>
					</div>
				</div>
				<div class="msg-item-admin" *ngIf="message?.senderid != user?.userid && message?.file != null && message?.fileType == 'doc'">
					<p>{{message?.created_at | date: "dd MMM, HH:mm"}}</p>
					<div class="adminmsgtext">
						<a target="_blank" href="{{message?.file}}" style="width: 100%; color: #921831;">document-{{message?.created_at}}</a>
					</div>
				</div>

			</div>

		</div>
		<div class="msginputs col-12 px-3 d-flex">
			<button class="messagefilebtn mx-0 btn btn-sm btn-light text-gaj my-auto" data-toggle="modal" data-target="#adminsendfileinmessage">
				<i class="fas fa-file-medical"></i>
			</button>
			<form class="col-12 d-flex" #sendform=ngForm (submit)="addTextMessage()" autocomplete="off">
				<input type="text" class="messagetextinput mx-1 form-control my-auto form-control-sm" placeholder="Message" name="textmsg" (focus)="markAsSeenMessages()" [(ngModel)]="message.text" required>
				<button type="submit" [disabled]="!sendform.valid" class="messagesubmitbtn btn btn-sm btn-primary mx-1 my-auto">
					Envoyer
				</button>
			</form>
		</div>
	</div>
	<div id="studentinfo" class="border-right shadow">

	</div>

</div>


<!-- Add Actualité Modal -->
<div class="modal fade" id="adminsendfileinmessage" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
	  <div class="modal-content">
		<div class="modal-body p-0">
		  <button type="button" #selectFileMessageModal class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
			<i class="fas fa-times closemodalicon"></i>
		  </button>


		  <ul class="nav nav-pills p-0 mb-3 col-12" id="pills-tab" role="tablist">
			<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link active radius-0 text-center" id="img-tab" data-toggle="pill" href="#adminimg" role="tab" aria-controls="img" aria-selected="true"><i class="fas fa-image"></i></a>
			</li>
			<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link radius-0 text-center" id="video-tab" data-toggle="pill" href="#adminvideo" role="tab" aria-controls="video" aria-selected="false"><i class="fas fa-film"></i></a>
			</li>
			<!--<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link radius-0 text-center" id="audio-tab" data-toggle="pill" href="#adminaudio" role="tab" aria-controls="audio" aria-selected="false"><i class="fas fa-microphone"></i></a>
			</li>-->
			<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link radius-0 text-center" id="otherfile-tab" data-toggle="pill" href="#adminotherfile" role="tab" aria-controls="otherfile" aria-selected="false"><i class="fas fa-file-medical"></i></a>
			</li>
		  </ul>
		  <div class="tab-content" id="pills-tabContent">
			<div class="tab-pane fade show active p-3" id="adminimg" role="tabpanel" aria-labelledby="img-tab">
				<img id="imgtosendpreview" src="" alt="" class="d-none">
				<label for="adminimgtosend" class="btn btn-block btn-primary">Sélectionner ton image</label>
				<input type="file" name="adminimgtosend" id="adminimgtosend" class="d-none" (change)="selectImage($event)">
        <div class="row mx-0">
          <button class="btn ml-auto btn-gaj" (click)="addImgMsg()">Envoyer</button>
        </div>

      </div>


			<div class="tab-pane fade p-3" id="adminvideo" role="tabpanel" aria-labelledby="video-tab">
				<video id="videotosendpreview" src="" class="d-none"></video>
				<label for="adminvideotosend" class="btn btn-block btn-primary">Sélectionner ton video</label>
				<input type="file" name="adminvideotosend" id="adminvideotosend" class="d-none" (change)="selectVideo($event)">
        <div class="row mx-0">
          <button class="btn ml-auto btn-gaj" (click)="addVideoMsg()">Envoyer</button>
        </div>

      </div>


			<div class="tab-pane fade p-3" id="adminaudio" role="tabpanel" aria-labelledby="audio-tab">
				<audio id="audiotosendpreview" src="" class="d-none"></audio>
				<label for="adminaudiotosend" class="btn btn-block btn-gaj">Sélectionner ton audio</label>
				<input type="file" name="adminaudiotosend" id="adminaudiotosend" class="d-none"> <!--  (change)="adminaddAudioMsg($event)" -->
      </div>


			<div class="tab-pane fade p-3" id="adminotherfile" role="tabpanel" aria-labelledby="otherfile-tab">
				<p id="filepreview" class="d-none"></p>
				<label for="adminfiletosend" class="btn btn-block btn-primary">Sélectionner ton fichier</label>
				<input type="file" name="adminfiletosend" id="adminfiletosend" class="d-none" (change)="selectDoc($event)">
        <div class="row mx-0">
          <button class="btn ml-auto btn-gaj" (click)="addDocMsg()">Envoyer</button>
        </div>

      </div>
		  </div>



		</div>
	  </div>
	</div>
</div>
