<div class="card card-body mb-3 p-0 relative">

	<a class="btn btn-primary btn-sm" *ngIf="currentuser?.role == 'admin'" data-toggle="modal" href="#filesModal{{post?.id}}" style="right: 0; top: 0; position: absolute; margin-top: 5px; margin-right: 5px; z-index: 1000;"><i class="fas fa-photo-video"></i></a>
	<div class="dropdown dropleft" *ngIf="currentuser?.role == 'admin'" style="top: 0; right: 40px; position: absolute; margin-top: 5px; margin-right: 5px; z-index: 1000;">
		<button class="btn btn-light btn-sm" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="fas text-gaj fa-edit"></i>
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
			<a class="dropdown-item text-primary" data-toggle="modal" href="#exampleModal{{post?.id}}"><i class="fas fa-edit"></i> Modifier la publication</a>
		    <a class="dropdown-item text-danger" data-toggle="modal" href="#deletepost{{post?.id}}"><i class="fas fa-edit"></i> Supprimer la publication</a>
		</div>
	</div>


	<!--<div class="dropdown dropleft" *ngIf="currentuser?.role == 'admin'" style="top: 36px; right: 0; position: absolute; margin-top: 5px; margin-right: 5px; z-index: 1000;">
		<button class="btn btn-primary btn-sm" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
			<i class="fas fa-photo-video"></i>
		</button>
		<div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
		    <button class="dropdown-item text-primary" data-toggle="modal" (click)="addImageToPost(post?.id)"><i class="fas fa-plus"></i> Ajouter une image</button>
		    <button class="dropdown-item text-danger" data-toggle="modal" (click)="addVideoToPost(post?.id)"><i class="fas fa-plus"></i> Ajouter un video</button>
		</div>
	</div>-->

	<div class="media p-0 m-0 relative" *ngIf="nbmedia != 0 && post?.image != null && post?.video == null" style="width: 100%; overflow: hidden; background: transparent; z-index: 1;">
		<div class="media-content" style="width: 100%;">
			<img src="{{filepath}}/BlogPostsimages/{{post?.image}}" style="width: 100%; max-height: 250px; min-height: 120px; object-fit: cover;">
		</div>
	</div>
	<div class="media p-0 m-0 relative" *ngIf="nbmedia != 0 && post?.image == null && post?.video != null" style="width: 100%; overflow: hidden; background: transparent; z-index: 1;">
		<div class="media-content" style="width: 100%;">
			<video src="{{filepath}}/BlogPostsvideos/{{post?.video}}" controls style="width: 100%; max-height: 250px; min-height: 120px;">
				Sorry, your browser doesn't support embedded videos.
			</video>
		</div>
	</div>

	<div class="media p-0 m-0" *ngIf="nbmedia != 0 && post?.image != null && post?.video != null" style="width: 100%; overflow: hidden; background: transparent; z-index: 1;">
		<div class="media-content relative" style="width: 100%; height: 220px;">
			<img src="{{filepath}}/BlogPostsimages/{{post?.image}}" style="width: 50%; height: 100%; object-fit: cover; top: 0; left: 0; position: absolute;">
			<video src="{{filepath}}/BlogPostsvideos/{{post?.video}}" controls style="width: 50%; height: 100%; object-fit: cover; top: 0; right: 0; position: absolute;">
				Sorry, your browser doesn't support embedded videos.
			</video>
		</div>
	</div>

	<div class="text p-3 m-0">
		{{post?.description}}
	</div>
</div>


<!-- Edit Modal -->
<div class="modal fade" id="exampleModal{{post?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <form (submit)="EditBlogPost()" autocomplete="off">
    		<div class="form-group mx-0 my-2">
			    <textarea class="form-control radius-0 border-0" id="exampleFormControlTextarea1" rows="8" placeholder="Que voulez-vouz dire {{currentuser?.fname}} ?" name="description" [(ngModel)]="post.description"></textarea>
			</div>
			<div class="row mx-0 px-0 col-sm-12">
				<button type="submit" class="mx-3 mb-3 btn btn-sm btn-block btn-gaj">
					Enregistrer les modifications
				</button>
			</div>
        </form>
      </div>
    </div>
  </div>
</div>

<!-- Edit Modal -->
<div class="modal fade pb-0" id="filesModal{{post?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
	  <div class="modal-content">
		<div class="modal-body px-0 pb-0 pt-4">
		  <button #closedeleteIVbutton type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
			<i class="fas fa-times closemodalicon"></i>
		  </button>


		  <div class="card col-12 mt-3 p-0" style="min-height: 300px;">
			<div class="card-header p-0 bg-white">
				<ul class="nav nav-tabs nav-tabs-myjobs" id="myTab" role="tablist" style="border-bottom: 0;">
					<li class="nav-item p-0 col-6 mx-0 text-center" role="presentation">
						<a class="nav-link active" id="image-tab" data-toggle="tab" href="#image{{post?.id}}" role="tab" aria-controls="image" aria-selected="true"><i class="fas fa-image fa-2x"></i></a>
					</li>
					<li class="nav-item p-0 col-6 mx-0 text-center" role="presentation">
						<a class="nav-link" id="videopart-tab" data-toggle="tab" href="#videopart{{post?.id}}" role="tab" aria-controls="videopart" aria-selected="true"><i class="fas fa-video fa-2x"></i></a>
					</li>
				</ul>
			</div>
			<div class="card-body p-0 mb-0">
				<div class="tab-content p-0" id="myTabContent">
					<div class="tab-pane p-0 fade show active relative" id="image{{post?.id}}" role="tabpanel" aria-labelledby="image-tab">
						<label for="changeimg{{post?.id}}" class="btn settingbtn btn-primary" style="top: 8px; right: 8px; position: absolute; z-index: 10000;"><i class="fas fa-pen"></i></label>
						<button class="btn settingbtn btn-danger" style="top: 46px; right: 8px; position: absolute; z-index: 10000;" *ngIf="post?.image != null" (click)="DeleteImg(post?.id)"><i class="fas fa-times"></i></button>
						<input type="file" id="changeimg{{post?.id}}" name="image" [(ngModel)]="post.image" style="display: none;" (change)="editImage(post?.id, $event)">

						<img src="{{filepath}}/BlogPostsimages/{{post?.image}}" style="width: 100%;" *ngIf="post?.image != null">
						<div class="row col-12 m-0 p-0">
							<span class="alert alert-info mx-auto mt-5" *ngIf="post?.image == null">Ajouter une image à cette publication</span>
						</div>
					</div>
					<div class="tab-pane p-0 fade relative" id="videopart{{post?.id}}" role="tabpanel" aria-labelledby="videopart-tab">
						<label for="changevid{{post?.id}}" class="btn settingbtn btn-primary" style="top: 8px; right: 8px; position: absolute; z-index: 10000;"><i class="fas fa-pen"></i></label>
						<button class="btn settingbtn btn-danger" style="top: 46px; right: 8px; position: absolute; z-index: 10000;" *ngIf="post?.video != null" (click)="DeleteVid(post?.id)"><i class="fas fa-times"></i></button>
						<input type="file" id="changevid{{post?.id}}" name="image" [(ngModel)]="post.video" style="display: none;" (change)="editVideo(post?.id, $event)">

						<div class="row col-12 m-0 p-0">
							<span class="alert alert-info mx-auto mt-5" *ngIf="post?.video == null">Ajouter un video à cette publication</span>
						</div>

						<video src="{{filepath}}/BlogPostsvideos/{{post?.video}}" controls style="width: 100%;" *ngIf="post?.video != null">
							Sorry, your browser doesn't support embedded videos.
						</video>
					</div>

				</div>
			</div>
		</div>



		</div>
	  </div>
	</div>
  </div>


<!-- Delete Modal -->
<div class="modal fade" id="deletepost{{post?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" #closedeletebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <h5 class="card-title col-12 text-center">
        	Voulez-vous vraiment supprimer ce post?
        </h5>
	    <div class="row col-sm-12 mb-3">
	    	<button class="btn btn-danger col-sm-4 mx-auto" (click)="deleteBlogPost(post?.id)">
	        	Supprimer
	        </button>
	    </div>
      </div>
    </div>
  </div>
</div>
