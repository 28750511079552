import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Actuality } from '../../../models/Actuality';

@Component({
  selector: 'app-act-item',
  templateUrl: './act-item.component.html',
  styleUrls: ['./act-item.component.css']
})
export class ActItemComponent implements OnInit {
  @Input() actuality: Actuality;

  filepath = environment.filepath;

  constructor() { }

  ngOnInit(): void {
  }

}
