import { Component, OnInit } from '@angular/core';
import { SelectService } from '../../services/select.service';
import { AvisRecherche } from '../../models/AvisRecherche';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-avis-recherche',
  templateUrl: './avis-recherche.component.html',
  styleUrls: ['./avis-recherche.component.css']
})
export class AvisRechercheComponent implements OnInit {

  allavis: AvisRecherche[] = [];
  doneavis: AvisRecherche[] = [];

  constructor(
  	private select: SelectService,
  	private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.select.getAvisRecherche('0').subscribe(res => {
  		this.allavis = res;
  	});
  	this.select.getAvisRecherche('1').subscribe(res => {
  		this.doneavis = res;
  	});

  	this.msg.getMessage().subscribe(res => {
  		this.select.getAvisRecherche('0').subscribe(res => {
	  		this.allavis = res;
	  	});
	  	this.select.getAvisRecherche('1').subscribe(res => {
	  		this.doneavis = res;
	  	});
  	})
  }

}
