<div class="row col-12 m-0 pl-1 pr-3 my-2 justify-content-between">
	<div class="d-flex">
    <button class="btn btn-sm btn-back" routerLink="/gaj/cycles">
      <i class="fas fa-arrow-left"></i>
    </button>
    <h5 class="modal-title" id="exampleModalLabel">{{cycle?.name}}</h5>
  </div>
	<button class="btn btn-sm btn-gaj" type="button" data-toggle="modal" data-target="#addFiliere" *ngIf="user?.role == 'admin'"><i class="fas fa-plus"></i> Nouvelle Filière</button>
</div>
<hr class="col-12">
<div *ngFor="let filiere of filieres">
  <app-filiere-item [filiere]="filiere"></app-filiere-item>
</div>

<!-- Modal -->
<div class="modal fade" id="addFiliere" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <h5 class="modal-title" id="exampleModalLabel">Ajouter Filière</h5>
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <div class="p-0">
          <form (submit)="AddFiliere($event)" autocomplete="off">
            <div class="row m-0">
              <div class="form-group col-sm-8">
                  <label for="name">Filière <strong class="required-star">*</strong></label>
                  <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="filiereform.name" required>
              </div>
              <div class="form-group col-sm-4">
                  <label for="yearsnumber">Période(Année) <strong class="required-star">*</strong></label>
                  <input type="number" class="form-control form-control-sm" name="yearsnumber" [(ngModel)]="filiereform.years_number" required>
              </div>
            </div>
            <div class="row m-0">
              <div class="form-group col-sm-12">
                  <label for="description">Description</label>
                  <textarea class="form-control form-control-sm" rows="6" name="description" [(ngModel)]="filiereform.description"></textarea>
              </div>
            </div>
            <div class="row mx-0 col-sm-12">
              <button type="submit" class="ml-auto btn btn-sm col-sm-4 btn-gaj">
                Ajouter
              </button>
            </div>
          </form>
        </div>

      </div>
    </div>
  </div>
</div>


<div id="confirmAddFiliere" class="confirm-message py-1 px-3 bg-success text-center ml-auto shadow text-white" style="z-index: 1; bottom: 0; left: 0; right: 0; position: fixed; margin-bottom: 10px; margin-right: 25px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 60px; line-height: 50px;">
  <i class="fas fa-check-circle"></i> Filière ajouter avec succés!
</div>
