<div class="p-0">
	<form (submit)="AddCycle($event)" autocomplete="off">
		<div class="row m-0">
			<div class="form-group col-sm-12">
				<h5>Ajouter une Spécialitée</h5>
			</div>
		</div>
		<div class="row m-0">
			<div class="form-group col-sm-12">
			    <label for="name">Cycle <strong class="required-star">*</strong></label>
			    <input type="text" class="form-control form-control-sm" name="name" id="name" [(ngModel)]="cycleform.name" required #name="ngModel">

			    <div *ngIf="name.invalid && (name.dirty || name.touched)" class="alert alert-danger p-0 mt-2">
				  <div *ngIf="name.errors.required">
				    Obligatoire!
				  </div>
				</div>
			</div>
		</div>
		<div class="row m-0">
			<div class="form-group col-sm-12">
			    <label for="description">Description</label>
			    <textarea class="form-control form-control-sm" rows="2" id="description" name="description" [(ngModel)]="cycleform.description"></textarea>
			</div>
		</div>
		<div class="row mx-0 col-sm-12">
			<button type="submit" class="ml-auto btn btn-sm col-sm-4 btn-gaj">
				Ajouter
			</button>
		</div>
	</form>
</div>



<div id="confirmAddmessage" class="confirm-message py-1 px-3 bg-success text-center ml-auto shadow text-white" style="z-index: 1; bottom: 0; left: 0; right: 0; position: fixed; margin-bottom: 10px; margin-right: 25px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 60px; line-height: 50px;">
	<i class="fas fa-check-circle"></i> Cycle ajouter avec succés!
</div>