import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private serverUrl = environment.api;

  constructor(private http: HttpClient) { }

  register(data) {
    return this.http.post(`${this.serverUrl}/signup`, data);
  }

  login(data) {
    return this.http.post(`${this.serverUrl}/login`, data);
  }

  sendPasswordResetLink(data) {
  	return this.http.post(`${this.serverUrl}/sendPasswordResetLink`, data);
  }


  changePassword(data) {
  	return this.http.post(`${this.serverUrl}/resetPassword`, data);
  }

  sendRegisterLink(data) {
    return this.http.post(`${this.serverUrl}/sendRegisterLink`, data);
  }

}
