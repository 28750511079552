<div class="card col-lg-8 col-md-10 col-sm-12 py-3 my-3 mx-auto">
    <div class="row mx-0 justify-content-between d-flex">
        <h3 class="card-title">Détails</h3>
        <button class="btn my-auto btn-sm btn-gaj" data-toggle="modal" data-target="#addAfterInscriptionFile">Joindre des fichiers</button>
    </div>
    <div class="card-body">
        <div class="row border-bottom justify-content-between">
            <p><strong>Nom & Prènom</strong></p>
            <p>{{inscription?.nom}} {{inscription?.postnom}} {{inscription?.prenom}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Nationalité</strong></p>
            <p>{{inscription?.nationality}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Passport/Cin</strong></p>
            <p>{{inscription?.passportcin}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Email</strong></p>
            <p>{{inscription?.email}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Téléphone</strong></p>
            <p>{{inscription?.tlf}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Diplome</strong></p>
            <p>{{inscription?.diplome}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Niveau</strong></p>
            <p>{{inscription?.niveau}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Filière</strong></p>
            <p>{{filiere?.name}}</p>
        </div>

        <div class="row border-bottom justify-content-between">
            <p><strong>Nombre des Mois</strong></p>
            <p>{{filiere?.years_number}} Mois</p>
        </div>

        <div class="">
            <p><strong>Description</strong></p>
            <p>{{filiere?.description}}</p>
        </div>
        <hr>
        <h3 class="card-title">
            Mes Fichiers
        </h3>
        <div class="list-group" *ngFor="let file of files">
            <div class="d-flex">
                <a target="_blank" href="{{filepath}}/InscriptFiles/{{file?.fichier}}" class="list-group-item list-group-item-action mr-1">
                <strong class="mr-3">{{file?.filename}}</strong> <small class="text-primary">({{file?.fichier}})</small>
                </a>
                <button class="btn btn-sm mx-auto btn-danger my-auto" (click)="Delete(file?.id)">X</button>
            </div>
        </div>
    </div>

</div>



<!-- Add Files Modal -->
<div class="modal fade" id="addAfterInscriptionFile" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg">
      <div class="modal-content">
        <div class="modal-body px-0 pb-0 pt-4">
          <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
            <i class="fas fa-times closemodalicon"></i>
          </button>
          <form class="px-3" (submit)="uploadInscriptFile($event)">
            <div class="form-group">
                <label for="filename">Titre du Fichier</label>
                <input type="text" id="filename" name="filename" placeholder="Titre du Fichier" class="form-control" [(ngModel)] = "inscriptFile.filename">
            </div>
            <div class="form-group">
                <label for="fichier" class="btn btn-block btn-primary">Choisir le Fichier</label>
                <input type="file" id="fichier" name="fichier" class="d-none" [(ngModel)] = "inscriptFile.fichier"  (change)="onFileSelected($event)">
            </div>

            <div class="form-group mt-5">
                <button class="btn btn-block btn-gaj" type="submit">Enregistrer</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
