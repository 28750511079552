export class Filiere {
	id: string;
	cycle_id: string;
	email: string;
	filiere_id: string;
	name: string;
	description: string;
	years_number: string;

	constructor(id, cycle_id, email, filiere_id, name, description, years_number) {
		this.cycle_id = cycle_id;
		this.email = email;
		this.filiere_id = filiere_id;
		this.name = name;
		this.description = description;
		this.years_number = years_number;
	}
}