import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Publication } from '../../../models/Publication';
import { UserdataService } from '../../../services/userdata.service';
import { UpdateService } from '../../../services/update.service';
import { DeleteService } from '../../../services/delete.service';
import { SelectService } from '../../../services/select.service';
import { User } from '../../../models/User';
import { Comment } from '../../../models/Comment';
import { MessageService } from '../../../services/message.service';
import { Message2Service } from '../../../services/message2.service';
import { AddService } from '../../../services/add.service';
import { HttpClient } from '@angular/common/http';
import { PublicationFile } from '../../../models/PublicationFile';
import { Favoris } from 'src/app/models/Favoris';
import { ThisReceiver } from '@angular/compiler';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publication-item',
  templateUrl: './publication-item.component.html',
  styleUrls: ['./publication-item.component.css']
})
export class PublicationItemComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;
  @ViewChild('closedeletebutton') closedeletebutton: any;
  @ViewChild('closebuttonimg') closebuttonimg: any;
  @ViewChild('closebuttonvideo') closebuttonvideo: any;

  userdata: User[] = [];
  useritem: User;

  filepath = environment.filepath;

  comments: Comment[] = [];
  files: PublicationFile[] = [];

  allFavoris: Favoris[] = [];
  oneFavori: Favoris;
  searchFavori: any = false;
  f: any = 0;

  currentuser: User;
  currentemail: any;
  role: any;

  newimg: any;
  newvideo: any;
  selectedimgFile: File = null;
  selectedvideoFile: File = null;


  updateresponse: any;
  deleteresponse: any;

  public pubform = {
  	id: null,
    email: null,
    category: null,
    description: null,
    image: null,
    video: null,
    show: null
  };

  public commentform = {
    id: null,
    pub_id: null,
    user_email: null,
    commenttext: null
  };

  public pubFileForm = {
    id: null,
    file_type: null,
    file: null,
    pub_id: null
  }

  @Input() publication: Publication;
  constructor(
  	private userdataservice: UserdataService,
  	private updateservice: UpdateService,
  	private deleteservice: DeleteService,
    private selectservice: SelectService,
  	private msg: MessageService,
    private msg2: Message2Service,
    private addservice: AddService,
    private http: HttpClient
  	) { }

  ngOnInit(): void {

  	this.pubform.id = this.publication.id;
    this.commentform.pub_id = this.publication.id;

  	this.pubform.email = this.publication.email;
  	this.pubform.category = this.publication.category;
  	this.pubform.description = this.publication.description;
  	this.pubform.image = this.publication.image;
  	this.pubform.video = this.publication.video;
  	this.pubform.show = this.publication.show;

  	this.userdataservice.getUserData(this.publication.email).subscribe(
  		(data) => {
  			this.userdata[0] = data;
  			this.useritem = this.userdata[0][0];
  	});

  	this.currentemail = localStorage.getItem('currentusermail');

  	this.userdataservice.getUserData(this.currentemail).subscribe((data) => {
  		this.currentuser = data[0];
  		this.role = this.currentuser.role;
      this.commentform.user_email = this.currentuser.email;
  	});

    this.selectservice.getPubComments(this.commentform.pub_id).subscribe(res => {
      this.comments = res.reverse();
    });

    this.selectservice.selectpubfiles(this.publication.id).subscribe(res => {
      //console.log(res);
      this.files = res.reverse();
    });

    this.msg2.getMessage().subscribe((pjct) => {
      this.selectservice.getPubComments(this.commentform.pub_id).subscribe(res => {
        this.comments = res.reverse();
      })
    });

    this.selectservice.selectUserFavorite(this.currentemail).subscribe(data => {
      this.allFavoris = data;
      this.f = 0;
      for(this.f = 0; this.f < this.allFavoris.length; this.f++) {
        if(this.allFavoris[this.f].pubid == this.publication.id) {
          this.searchFavori = true;
          break;
        }
      }
    });
  }



  updatepublication() {
  	this.updateservice.updatePublication('publications', this.pubform).subscribe(res => {
  		//console.log(res);
  		this.updateresponse = res.message;



      var aux = 'pubupdatesuccess'+this.publication.id;


      setTimeout(function() {
        document.getElementById(aux).style.display = "block";
      }, 1000);
      this.msg.setMessage("new operation");
      this.closebutton.nativeElement.click();

  		setTimeout(function() {
			document.getElementById(aux).style.display = "none";
  		},8000);

  	});
  }

  Delete() {
    this.deleteservice.deleteData('publications', this.publication.id).subscribe((res) => {
      //console.log(res);
      this.deleteresponse = res.message;
      this.msg.setMessage("new operation");
      this.closedeletebutton.nativeElement.click();
    });
  }

  AddComment(e) {
    this.addservice.addComment('', this.commentform.pub_id, this.commentform.user_email, this.commentform.commenttext, '').subscribe(res => {
      //console.log(res);
      this.msg2.setMessage("new operation");
      this.commentform.commenttext = '';
    });
  }

  onFileSelected(event, filetype) {
    this.selectedimgFile = <File>event.target.files[0];
    this.pubFileForm.file_type = filetype;
    this.pubFileForm.pub_id = this.publication.id;

    const fd = new FormData();

    fd.append('file', this.selectedimgFile);
    fd.append('file_type', this.pubFileForm.file_type);
    fd.append('pub_id', this.pubFileForm.pub_id);

    this.http.post(environment.api+'/pubfileupload', fd).subscribe(res => {
      console.log(res);
      this.msg.setMessage('an other operation done');

      this.selectedimgFile = null;
      this.pubFileForm.file_type = '';
      this.pubFileForm.pub_id = '';
    });
  }


  addtofavoris(e) {
    this.addservice.addtofavoris('', this.currentemail, this.publication.id).subscribe(res => {
      this.msg.setMessage('new Operation');
      console.log(res);
    });
  }

  deletefavoris() {
    this.deleteservice.deletefavoris(this.currentemail, this.publication.id).subscribe(res => {
      console.log(res);
      this.msg.setMessage('new Operation');
    });
  }

  DeleteInitPic(id) {
    this.deleteservice.deleteinitpic(id).subscribe(res => {
      console.log(res);
      this.msg.setMessage("new operation");
    });
  }
  DeleteInitVideo(id) {
    this.deleteservice.deleteinitvideo(id).subscribe(res => {
      console.log(res);
      this.msg.setMessage("new operation");
    });
  }

}
