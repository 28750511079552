export class Publication {
	id: string;
	pubid: string;
	email: string;
	category: string;
	description: string;
	image: string;
	video: string;
	show: number;

	constructor(id, pubid, email, category, description, image, video, show) {
		this.pubid = pubid;
		this.email = email;
		this.category = category;
		this.description = description;
		this.image = image;
		this.video = video;
		this.show = show;
	}
}