import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SelectService } from '../../services/select.service';
import { UserdataService } from '../../services/userdata.service';
import { Publication } from '../../models/Publication';
import { User } from '../../models/User';
import { Comment } from '../../models/Comment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-publication-page',
  templateUrl: './publication-page.component.html',
  styleUrls: ['./publication-page.component.css']
})
export class PublicationPageComponent implements OnInit {
  pubitem: Publication;
  id:any;
  user: User;

  filepath = environment.filepath;

  comments: Comment[] = [];
  constructor(
  	private route: ActivatedRoute,
  	private select: SelectService,
  	private userdata: UserdataService,
  	) { }

  ngOnInit(): void {
  	this.route.params.subscribe((data) => {
  		this.id = data.id;
  		this.select.getOnePublication(this.id).subscribe((item) => {
  			this.pubitem = item[0];
  			console.log(this.pubitem);

  			this.userdata.getUserData(this.pubitem.email).subscribe((udata) => {
		  		this.user = udata[0];
		  		console.log(this.user);
		  	});

		  	this.select.getPubComments(this.pubitem.id).subscribe((data) => {
		  		this.comments = data;
		  		console.log(this.comments);
		  	})
  		});
  	});

  }

}
