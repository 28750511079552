<div class="card m-2" style="width: 18rem;" *ngIf="demande?.status == '1'">
  <div class="card-body">
    <h5 class="card-title">{{demande?.diplome}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">{{demande?.nom}} {{demande?.prenom}}</h6>
    <p class="card-text my-0"><strong>Email: </strong> {{demande?.email}}</p>
    <p class="card-text my-0"><strong>Nationalité: </strong> {{demande?.nationality}}</p>
    <p class="card-text my-0"><strong>CIN/Passport: </strong> {{demande?.passportcin}}</p>
    <p class="card-text my-0"><strong>Téléphone: </strong> {{demande?.tlf}}</p>

    <a routerLink="/gaj/suivi-demarches/demande/{{demande?.id}}" class="btn btn-sm btn-block btn-gaj">Etapes à suivre</a>
    
  </div>
</div>


<div class="card m-2" style="width: 18rem;" *ngIf="demande?.status == '0'">
  <div class="card-body">
    <h5 class="card-title">{{demande?.diplome}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">{{demande?.nom}} {{demande?.prenom}}</h6>
    <p class="card-text my-0"><strong>Email: </strong> {{demande?.email}}</p>
    <p class="card-text my-0"><strong>Nationalité: </strong> {{demande?.nationality}}</p>
    <p class="card-text my-0"><strong>CIN/Passport: </strong> {{demande?.passportcin}}</p>
    <p class="card-text my-0"><strong>Téléphone: </strong> {{demande?.tlf}}</p>

    <!--<a routerLink="/gaj/suivi-demarches/demande/{{demande?.id}}" class="btn btn-sm btn-block btn-gaj">Etapes à suivre</a>-->
    
  </div>
</div>


<div class="card m-2" style="width: 18rem;" *ngIf="demande?.status == '2'">
  <div class="card-body">
    <h5 class="card-title">{{demande?.diplome}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">{{demande?.nom}} {{demande?.prenom}}</h6>
    <p class="card-text my-0"><strong>Email: </strong> {{demande?.email}}</p>
    <p class="card-text my-0"><strong>Nationalité: </strong> {{demande?.nationality}}</p>
    <p class="card-text my-0"><strong>CIN/Passport: </strong> {{demande?.passportcin}}</p>
    <p class="card-text my-0"><strong>Téléphone: </strong> {{demande?.tlf}}</p>

    <!--<a routerLink="/gaj/suivi-demarches/demande/{{demande?.id}}" class="btn btn-sm btn-block btn-gaj">Etapes à suivre</a>-->
    
  </div>
</div>
