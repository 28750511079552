import { Component, OnInit, Input } from '@angular/core';
import { Publication } from '../../../models/Publication';
import { UserdataService } from '../../../services/userdata.service';
import { User } from '../../../models/User';
import { SelectService } from '../../../services/select.service';
import { Comment } from '../../../models/Comment';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-pub-item',
  templateUrl: './pub-item.component.html',
  styleUrls: ['./pub-item.component.css']
})
export class PubItemComponent implements OnInit {
  @Input() publication: Publication;
  user: User;
  comments: Comment[] = [];

  filepath = environment.filepath;

  constructor(
  	private userdata: UserdataService,
  	private selectservice: SelectService
  	) { }

  ngOnInit(): void {
  	this.userdata.getUserData(this.publication.email).subscribe(data => {
  		this.user = data[0];
  	});

  	this.selectservice.getPubComments(this.publication.id).subscribe(res => {
      this.comments = res.reverse();
    });
  }

}
