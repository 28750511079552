<div class="publication-card card col-md-8 mx-auto" style="margin-bottom: 100px;">
	<div class="header">
		<div class="avatar">
			<img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
			<img src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
		</div>
		<span>{{user?.fname}} {{user?.lname}}</span>
		<small>(Editeur)</small>
		<button class="btn btn-light ml-auto my-auto" style="height: 40px; width: 40px; padding: 0; line-height: 40px; border-radius: 50%;" routerLink="/publications">
			<i class="fas fa-arrow-left"></i>
		</button>
	</div>
	<div class="description bg-gaj">
		{{pubitem?.description}}
	</div>
	<div class="pub-files p-2">
		<img class="mx-1" src="{{filepath}}/publicationsimgs/{{pubitem?.image}}" style="height: 100%; max-width: 220px;" *ngIf="pubitem?.image != null">

		<video src="{{filepath}}/publicationsvideos/{{pubitem?.video}}" class="mx-1" controls style="height: 100%;" *ngIf="pubitem?.video != null">
          Sorry, your browser doesn't support embedded videos.
        </video>

		<div class="alert alert-primary col-md-8 text-center mx-auto" role="alert" *ngIf="pubitem?.image == null && pubitem?.video == null">
		  <h6 class="alert-heading" style="margin-top: 30px;">Aucun(e) photo/video à afficher!</h6>
		</div>
	</div>
	<div class="footer py-1 px-2 border-top">
		<a data-toggle="collapse" href="#collapseExample" role="button" style="text-decoration-color: #921831"><span class="text-gaj"><i class="far text-gaj fa-comments"></i> Commentaires ({{comments.length}})</span></a>
	</div>
	<div class="collapse show" id="collapseExample">
	  <div class="m-2" *ngFor="let comment of comments">
	    <div style="padding: 5px; background: #92183133; margin: 0; border-radius: 0 15px 15px 15px;">
	    	{{comment?.commenttext}}
	    </div>
	  </div>
	  <div class="alert alert-primary text-center col-md-8 mx-auto" role="alert" *ngIf="comments.length == 0">
	    Aucun commentaire pour cette publication.
	  </div>
	</div>
</div>
