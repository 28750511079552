export class Faq {
	id: string;
	question: string;
	response: string;
	status: number;

	constructor(id, question, response, status) {
		this.question = question;
		this.response = response;
		this.status = status;
	}
}