<div class="bg-white gallery-container">
	<div class="header">
		<label class="btn btn-light text-gaj" for="image"><i class="fas fa-image"></i> Ajouter Photo</label>
		<label class="btn btn-light text-gaj" for="videoinput"><i class="fas fa-video"></i> Ajouter Video</label>
	</div>

	<input type="file" class="form-control-file display-none" id="image" name="image" required (change)="onImageSelected($event)" accept="image/*">

	<input type="file" class="form-control-file display-none" id="videoinput" name="video" required (change)="onVideoSelected($event)" accept="video/*">

	<div class="body">
		<div class="relative" *ngFor="let file of files; let index = index">
			<div class="gestion-media">
				<button (click)="delete(files[index].id)" class="btn btn-sm btn-light text-danger"><i class="fas fa-times"></i></button>
			</div>
			<a type="button" class="media-element" data-toggle="modal" href="#showMedia{{files[index].id}}"><img style="height: 180px; margin: 0 5px;" src="{{filepath}}/galleryFiles/{{file?.file}}" *ngIf="file?.format == 'img'"></a>
			<a type="button" class="media-element" data-toggle="modal" href="#showMedia{{files[index].id}}"><video style="height: 180px; margin: 0 5px;" src="{{filepath}}/galleryFiles/{{file?.file}}" *ngIf="file?.format == 'video'"></video></a>

			<!-- media file Modal -->
			<div class="modal fade" data-backdrop="static" id="showMedia{{files[index].id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
			  <div class="modal-dialog modal-lg">
			    <div class="modal-content">
			      <div class="modal-body p-0">
			        <button *ngIf="files[index].format=='img'" type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
			          <i class="fas fa-times closemodalicon"></i>
			        </button>
			        <button (click)="playVideo(files[index].id)" *ngIf="files[index].format=='video'" type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
			          <i class="fas fa-times closemodalicon"></i>
			        </button>


			        <img style="width: 100%;" src="{{filepath}}/galleryFiles/{{file?.file}}" *ngIf="file?.format == 'img'">

					<video controls #myVideo style="width: 100%;" src="{{filepath}}/galleryFiles/{{file?.file}}" *ngIf="file?.format == 'video'"></video>

			      </div>
			    </div>
			  </div>
			</div>

		</div>
	</div>
</div>

