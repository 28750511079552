import { Component } from '@angular/core';
import * as firebase from 'firebase';
import { Spinkit } from 'ng-http-loader';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'gajacademyfront';

  public spinkit = Spinkit;

  constructor() {
    const firebaseConfig = {
      apiKey: "AIzaSyCCxqa5n0k0STC3aS82d4dI55Ud96h_Hds",
      authDomain: "gaj-msg.firebaseapp.com",
      databaseURL: "https://gaj-msg-default-rtdb.firebaseio.com",
      projectId: "gaj-msg",
      storageBucket: "gaj-msg.appspot.com",
      messagingSenderId: "570585098876",
      appId: "1:570585098876:web:68490d71b99069140337f9",
      measurementId: "G-SQK4696GXD"
    };
    firebase.initializeApp(firebaseConfig);
  }
}
