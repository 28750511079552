<div class="card">
  <img src="{{filepath}}/partenaires/{{partenaire?.image}}" class="card-img-top" alt="...">
  <div class="card-body">
    <h5 class="card-title">{{partenaire?.name}}</h5>
  </div>

  <label for="updatepartpic{{partenaire?.id}}" class="btn btn-sm btn-gaj update-photo"><i class="fas fa-camera"></i></label>
  <input type="file" id="updatepartpic{{partenaire?.id}}" class="d-none" (change)="onFileSelected($event)">

  <div class="manage-partenaire-box">
  	<a class="btn btn-light text-primary mr-1" data-toggle="modal" href="#updateparteaire{{partenaire?.id}}"><i class="fas fa-edit"></i></a>
  	<button (click)="delete(partenaire.id)" class="btn btn-light text-danger"><i class="fas fa-times"></i></button>
  </div>

</div>



<!-- Add Actualité Modal -->
<div class="modal fade" id="updateparteaire{{partenaire?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <form class="p-3" (submit)="updatePartenaireName()" autocomplete="off">
          <div class="row">
            <div class="form-group col-sm-12">
                <label for="name">Nom du Partenaire</label>
                <input type="text" class="form-control form-control-sm" id="name" name="name" placeholder="Nom du Partenaire" [(ngModel)]="partenaireform.name" required>
            </div>
          </div>
          <div class="row mx-0 px-0 col-sm-12">
            <button type="submit" class="ml-auto btn-block btn btn-sm btn-gaj">
              Modifier
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
