import { CommonModule } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './visitor/home/home.component';
import { NotAuthLayoutComponent } from './layouts/not-auth-layout/not-auth-layout.component';
import { NotAuthHeaderComponent } from './layouts/not-auth-header/not-auth-header.component';
import { ProfileComponent } from './user/profile/profile.component';
import { AuthHeaderComponent } from './layouts/auth-header/auth-header.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PublicationsComponent } from './user/publications/publications.component';
import { CyclesComponent } from './user/cycles/cycles.component';
import { RenseignementsComponent } from './user/renseignements/renseignements.component';
import { MessagesComponent } from './user/messages/messages.component';
import { AddPublicationFormComponent } from './user/forms/add-publication-form/add-publication-form.component';
import { PublicationItemComponent } from './user/dashboard/publication-item/publication-item.component';
import { PublicationFilesComponent } from './user/dashboard/publication-item/publication-files/publication-files.component';
import { AddActualityFormComponent } from './user/forms/add-actuality-form/add-actuality-form.component';
import { ActualityItemComponent } from './user/dashboard/actuality-item/actuality-item.component';
import { ActualitiesComponent } from './user/actualities/actualities.component';
import { StudentFilesComponent } from './user/student-files/student-files.component';
import { StudentsComponent } from './user/students/students.component';
import { CommentItemComponent } from './user/dashboard/publication-item/comment-item/comment-item.component';
import { PubItemComponent } from './visitor/home/pub-item/pub-item.component';
import { AllpublicationsComponent } from './visitor/allpublications/allpublications.component';
import { PublicationPageComponent } from './visitor/publication-page/publication-page.component';
import { ItemHeaderComponent } from './layouts/item-header/item-header.component';
import { ItemLayoutComponent } from './layouts/item-layout/item-layout.component';
import { ActItemComponent } from './visitor/home/act-item/act-item.component';
import { AllactualitiesComponent } from './visitor/allactualities/allactualities.component';
import { ActualityPageComponent } from './visitor/actuality-page/actuality-page.component';
import { NotificationsComponent } from './user/notifications/notifications.component';
import { SuiviDemarchesComponent } from './user/suivi-demarches/suivi-demarches.component';
import { AddCycleFormComponent } from './user/forms/add-cycle-form/add-cycle-form.component';
import { CycleItemComponent } from './user/cycles/cycle-item/cycle-item.component';
import { CycleDetailsComponent } from './user/cycles/cycle-item/cycle-details/cycle-details.component';
import { FiliereItemComponent } from './user/cycles/cycle-item/cycle-details/filiere-item/filiere-item.component';
import { DemandeItemComponent } from './user/suivi-demarches/demande-item/demande-item.component';
import { DemandeApprovedDetailsComponent } from './user/suivi-demarches/demande-item/demande-approved-details/demande-approved-details.component';
import { StudentItemComponent } from './user/students/student-item/student-item.component';
import { AddFileFormComponent } from './user/forms/add-file-form/add-file-form.component';
import { FileItemComponent } from './user/student-files/file-item/file-item.component';
import { AdminDemandeItemComponent } from './user/suivi-demarches/admin-demande-item/admin-demande-item.component';
import { FavoritepubsComponent } from './user/favoritepubs/favoritepubs.component';
import { PresentationblogComponent } from './user/blogs/presentationblog/presentationblog.component';
import { EtudesblogComponent } from './user/blogs/etudesblog/etudesblog.component';
import { AccueilblogComponent } from './user/blogs/accueilblog/accueilblog.component';
import { AmenagementblogComponent } from './user/blogs/amenagementblog/amenagementblog.component';
import { CartesejourblogComponent } from './user/blogs/cartesejourblog/cartesejourblog.component';
import { VieestudiantineblogComponent } from './user/blogs/vieestudiantineblog/vieestudiantineblog.component';
import { PresentationblogItemComponent } from './user/blogs/presentationblog/presentationblog-item/presentationblog-item.component';
import { DemandeAcceuilComponent } from './user/demande-acceuil/demande-acceuil.component';
import { AvisRechercheComponent } from './user/avis-recherche/avis-recherche.component';
import { DemandeAcceuilItemComponent } from './user/demande-acceuil/demande-acceuil-item/demande-acceuil-item.component';
import { AvisItemComponent } from './user/avis-recherche/avis-item/avis-item.component';
import { GalleryComponent } from './user/gallery/gallery.component';
import { PartenairesComponent } from './user/partenaires/partenaires.component';
import { PartenaireItemComponent } from './user/partenaires/partenaire-item/partenaire-item.component';
import { ContactListComponent } from './user/support/contact-list/contact-list.component';
import { FaqsListComponent } from './user/support/faqs-list/faqs-list.component';
import { TermsListComponent } from './user/support/terms-list/terms-list.component';
import { SupportComponent } from './user/support/support.component';
import { MessengerItemComponent } from './layouts/auth-header/messenger-item/messenger-item.component';
import { VCycleItemComponent } from './visitor/home/v-cycle-item/v-cycle-item.component';
import { VCycleDetailsComponent } from './visitor/home/v-cycle-details/v-cycle-details.component';
import { ResponseItemComponent } from './user/dashboard/publication-item/comment-item/response-item/response-item.component';
import { AproposdenousComponent } from './visitor/aproposdenous/aproposdenous.component';
import { DepartementsComponent } from './visitor/departements/departements.component';
import { FraisComponent } from './visitor/frais/frais.component';
import { RequestResetComponent } from './visitor/resetpassword/request-reset/request-reset.component';
import { ResponseResetComponent } from './visitor/resetpassword/response-reset/response-reset.component';

import { NgHttpLoaderModule } from 'ng-http-loader';


@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    NotAuthLayoutComponent,
    NotAuthHeaderComponent,
    ProfileComponent,
    AuthHeaderComponent,
    AuthLayoutComponent,
    DashboardComponent,
    PageNotFoundComponent,
    PublicationsComponent,
    CyclesComponent,
    RenseignementsComponent,
    MessagesComponent,
    AddPublicationFormComponent,
    PublicationItemComponent,
    PublicationFilesComponent,
    AddActualityFormComponent,
    ActualityItemComponent,
    ActualitiesComponent,
    StudentFilesComponent,
    StudentsComponent,
    CommentItemComponent,
    PubItemComponent,
    AllpublicationsComponent,
    PublicationPageComponent,
    ItemHeaderComponent,
    ItemLayoutComponent,
    ActItemComponent,
    AllactualitiesComponent,
    ActualityPageComponent,
    NotificationsComponent,
    SuiviDemarchesComponent,
    AddCycleFormComponent,
    CycleItemComponent,
    CycleDetailsComponent,
    FiliereItemComponent,
    DemandeItemComponent,
    DemandeApprovedDetailsComponent,
    StudentItemComponent,
    AddFileFormComponent,
    FileItemComponent,
    AdminDemandeItemComponent,
    FavoritepubsComponent,
    PresentationblogComponent,
    EtudesblogComponent,
    AccueilblogComponent,
    AmenagementblogComponent,
    CartesejourblogComponent,
    VieestudiantineblogComponent,
    PresentationblogItemComponent,
    DemandeAcceuilComponent,
    AvisRechercheComponent,
    DemandeAcceuilItemComponent,
    AvisItemComponent,
    GalleryComponent,
    PartenairesComponent,
    PartenaireItemComponent,
    ContactListComponent,
    FaqsListComponent,
    TermsListComponent,
    SupportComponent,
    MessengerItemComponent,
    VCycleItemComponent,
    VCycleDetailsComponent,
    ResponseItemComponent,
    AproposdenousComponent,
    DepartementsComponent,
    FraisComponent,
    RequestResetComponent,
    ResponseResetComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpClientModule,
    ReactiveFormsModule,
    CommonModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    NgHttpLoaderModule.forRoot()

  ],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule { }
