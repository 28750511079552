import { Component, OnInit, Input } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Document } from '../../../models/Document';
import { DeleteService } from '../../../services/delete.service';
import { MessageService } from '../../../services/message.service';

declare var require: any
const FileSaver = require('file-saver');

@Component({
  selector: 'app-file-item',
  templateUrl: './file-item.component.html',
  styleUrls: ['./file-item.component.css']
})
export class FileItemComponent implements OnInit {

  filepath = environment.filepath;

  @Input() document: Document;
  constructor(
    private deleteservice: DeleteService,
    private msg: MessageService
    ) { }

  ngOnInit(): void {
  }

  downloadPdf(pdfUrl: string, pdfName: string) {
    FileSaver.saveAs(pdfUrl, pdfName);
  }

  DeleteFile() {
    this.deleteservice.deleteData('documents', this.document.id).subscribe(docs => {
      this.msg.setMessage('an other operation');
    });
  }

}
