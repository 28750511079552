<div class="card m-1" style="width: 18rem;">
  <a type="button" data-toggle="modal" href="#showAvatar{{demande?.id}}">
  	<img src="{{filepath}}/DemandeAcceuil/{{demande?.customFile}}" class="card-img-top" alt="...">
  </a>
  <div class="card-body">
    <h5 class="card-title">{{demande?.name}} {{demande?.prenom}}</h5>
    <h6 class="card-subtitle mb-2 text-muted">{{demande?.date | date: 'dd/MM/yyyy'}} - {{demande?.time}}</h6>
    <p class="card-text"><i class="fas fa-plane-departure"></i> {{demande?.payorigine}}</p>
    <p><i class="fas fa-plane-arrival"></i> {{demande?.destination}}</p>
    <p class="card-text"></p>
    <div class="col-12 text-right" *ngIf="demande?.status == '0'">
    	<button (click)="updateStatus(demande.id)" class="btn btn-sm btn-gaj text-right">Cloturer l'accueil</button>
    </div>

  </div>
</div>

<!-- Add Actualité Modal -->
<div class="modal fade" id="showAvatar{{demande?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog p-0">
    <div class="modal-content p-0">
      <div class="modal-body p-0">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
    	<img style="width: 100%;" src="{{filepath}}/DemandeAcceuil/{{demande?.customFile}}">
      </div>
    </div>
  </div>
</div>
