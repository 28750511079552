<div class="p-3">
	<form (submit)="AddActuality($event)" autocomplete="off">
		<div class="row">
			<div class="form-group col-sm-8">
				<h5>Publier une Actualité</h5>
			</div>
		</div>

		<div class="row col-12" id="addactualitysuccess">
			<div class="alert alert-success col-sm-8 text-center mx-auto p-1 alert-dismissible fade show" role="alert">
			  Actualité a été ajoutée avec succée!
			  <button type="button" class="close m-0 py-1" data-dismiss="alert" aria-label="Close">
			    <span aria-hidden="true">&times;</span>
			  </button>
			</div>
		</div>

		<div class="form-group">
		    <label for="exampleFormControlFile1">Sélectionner image</label>
		    <input type="file" class="form-control-file" name="image" [(ngModel)]="actform.image" required #image="ngModel" (change)="onImageSelected($event)">

		    <div *ngIf="image.invalid && (image.dirty || image.touched)" class="alert alert-danger p-0 mt-2">
			  <div *ngIf="image.errors.required">
			    Obligatoire!
			  </div>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-sm-12">
			    <label for="title">Titre de l'Actualité</label>
			    <input type="text" class="form-control form-control-sm" id="title" name="title" placeholder="Titre de l'Actualité" [(ngModel)]="actform.title" required #title="ngModel">
			    <div *ngIf="title.invalid && (title.dirty || title.touched)" class="alert alert-danger p-0 mt-2">
				  <div *ngIf="title.errors.required">
				    Obligatoire!
				  </div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="form-group col-sm-12">
			    <label for="informations">Description</label>
			    <textarea class="form-control" rows="3" id="informations" name="description" placeholder="Informations de l'Actualité" [(ngModel)]="actform.description" required #description="ngModel"></textarea>
			    <div *ngIf="description.invalid && (description.dirty || description.touched)" class="alert alert-danger p-0 mt-2">
				  <div *ngIf="description.errors.required">
				    Obligatoire!
				  </div>
				</div>
			</div>
		</div>
		<div class="row mx-0 px-0 col-sm-12">
			<button type="submit" class="ml-auto btn-block btn btn-sm btn-gaj">
				Ajouter
			</button>
		</div>
	</form>
</div>