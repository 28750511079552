import { Component, OnInit } from '@angular/core';
import { UserdataService } from '../../services/userdata.service';
import { AddService } from '../../services/add.service';
import { User } from '../../models/User';
import { HttpClient } from '@angular/common/http';
import { SelectService } from '../../services/select.service';
import { Publication } from '../../models/Publication';
import { Actuality } from '../../models/Actuality';
import { MessageService } from '../../services/message.service';
import { Message1Service } from '../../services/message1.service';
import { Favoris } from 'src/app/models/Favoris';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit {


  user: User;
  //users: User[] = [];
  role: any;
  currentusermail: any;
  pubs: Publication[] = [];/* all publications */
  publications: Publication[] = []; /* the inverse of pubs */
  somepublications: Publication[] = []; /* the last 10 publications*/
  initpublications: Publication[] = [];

  acts: Actuality[] = [];/* all actualities */
  actualities: Actuality[] = []; /* the inverse of acts */
  someactualities: Actuality[] = []; /* the last 10 actualities*/

  i: any = 0;
  j: any = 0;
  ii: any = 0;
  jj: any = 0;
  x: any = 0;
  y: any = 0;

  allFavoris: Favoris[] = [];




  constructor(
    private userdataservice: UserdataService,
    private addservice: AddService,
    private http: HttpClient,
    private selectservice: SelectService,
    private msg: MessageService,
    private msg1: Message1Service
    ) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  		this.role = this.user.role;
  	});

    this.selectservice.selectUserFavorite(this.currentusermail).subscribe(data => {
      this.allFavoris = data;
    });

    this.selectservice.getAll('publications').subscribe((pubs) => {
        this.publications = pubs.reverse();

        if(this.publications.length > 10) {
          for(this.i = 0; this.i<10; this.i++) {
            this.somepublications[this.i] = this.publications[this.i];
            this.initpublications[this.i] = this.publications[this.i];
          }
        } else {
          for(this.i = 0; this.i < this.publications.length; this.i++) {
            this.somepublications[this.i] = this.publications[this.i];
            this.initpublications[this.i] = this.publications[this.i];
          }
        }
      });

      this.msg.getMessage().subscribe((pjct) => {
          this.selectservice.getAll('publications').subscribe((pubs) => {
            this.publications = pubs.reverse();
            this.somepublications = [];
            if(this.publications.length > 10) {
              for(this.i = 0; this.i<10; this.i++) {
                this.somepublications[this.i] = this.publications[this.i];
                this.initpublications[this.i] = this.publications[this.i];
              }
            } else {
              for(this.i = 0; this.i < this.publications.length; this.i++) {
                this.somepublications[this.i] = this.publications[this.i];
                this.initpublications[this.i] = this.publications[this.i];
              }
            }
          });

          this.selectservice.selectUserFavorite(this.currentusermail).subscribe(data => {
            this.allFavoris = data;
          });
      });

      this.msg1.getMessage().subscribe((pjct) => {
          this.selectservice.getAll('actualities').subscribe((act) => {
            this.actualities = act.reverse();
            this.someactualities = [];

            if(this.actualities.length > 4) {
              for(this.x = 0; this.x<4; this.x++) {
                this.someactualities[this.x] = this.actualities[this.x];
              }
            } else {
              for(this.x = 0; this.x < this.actualities.length; this.x++) {
                this.someactualities[this.x] = this.actualities[this.x];
              }
            }
          });
      });


      this.selectservice.getAll('actualities').subscribe((act) => {
        this.actualities = act.reverse();

        if(this.actualities.length > 4) {
          for(this.ii = 0; this.ii<4; this.ii++) {
            this.someactualities[this.ii] = this.actualities[this.ii];
          }
        } else {
          for(this.ii = 0; this.ii < this.actualities.length; this.ii++) {
            this.someactualities[this.ii] = this.actualities[this.ii];
          }
        }
      });
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  loadPubs() {

  }

  filter($category) {
    if($category == 'all') {
      this.somepublications = this.initpublications;
    } else {
      this.somepublications = [];
      this.j = 0;
      for(this.i = 0; this.i < this.initpublications.length; this.i++) {
        if(this.initpublications[this.i].category == $category) {
          this.somepublications[this.j] = this.initpublications[this.i];
          this.j++;
        }
      }
    }
  }

}
