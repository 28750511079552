import { Component, OnInit } from '@angular/core';
import { Cycle } from '../../../../models/Cycle';
import { ActivatedRoute } from '@angular/router';
import { SelectService } from '../../../../services/select.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../../services/message.service';
import { AddService } from '../../../../services/add.service';
import { Filiere } from '../../../../models/Filiere';
import { UserdataService } from '../../../../services/userdata.service';
import { User } from '../../../../models/User';

@Component({
  selector: 'app-cycle-details',
  templateUrl: './cycle-details.component.html',
  styleUrls: ['./cycle-details.component.css']
})
export class CycleDetailsComponent implements OnInit {
  cycle: Cycle;
  filieres: Filiere[] = [];
  user: User;

  public filiereform = {
    cycle_id: '',
    email: '',
    filiere_id: '',
    name: '',
    description: '',
    years_number: ''
  };

  formSubmitted: boolean = false;
  currentusermail: any;

  constructor(
    private http: HttpClient,
    private msg: MessageService,
    private add: AddService,
  	private route: ActivatedRoute,
  	private select: SelectService,
    private userdata: UserdataService
  	) { }

  ngOnInit(): void {
    this.currentusermail = this.getMail();

    this.filiereform.email = this.currentusermail;

    this.userdata.getUserData(this.currentusermail).subscribe(user => {
      this.user = user[0];
    });

  	this.route.params.subscribe((data) => {
  		this.select.getOneCycle(data.id).subscribe(res => {
  			this.cycle = res[0];
        this.filiereform.cycle_id = this.cycle.cycle_id;

        this.select.getFilieres(this.filiereform.cycle_id).subscribe(res => {
          this.filieres = res;
        });
  		});
  	});

    this.msg.getMessage().subscribe(res => {
      this.select.getFilieres(this.filiereform.cycle_id).subscribe(data => {
        this.filieres = data;
      });
    });
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  AddFiliere(e) {
    this.formSubmitted = true;
    e.preventDefault();

    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const l = 16;
    let randomstring = '';
    for (let i=0; i<l; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring = randomstring + chars.substring(rnum, rnum+1);
    }

    this.filiereform.filiere_id = this.filiereform.filiere_id + randomstring;

    this.add.addFiliere('', this.filiereform.cycle_id, this.filiereform.email, this.filiereform.filiere_id, this.filiereform.name, this.filiereform.description, this.filiereform.years_number).subscribe(res => {

      setTimeout(function() {
        document.getElementById('confirmAddFiliere').style.display = "block";
      }, 1000);

      this.msg.setMessage("new operation");

      setTimeout(function() {
        document.getElementById('confirmAddFiliere').style.display = "none";
      }, 8000);

      this.filiereform.name = '';
      this.filiereform.description = '';
      this.filiereform.filiere_id = '';
      this.filiereform.years_number = '';
    });
  }

}
