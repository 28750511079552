import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Partenaire } from '../../../models/Partenaire';
import { DeleteService } from '../../../services/delete.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-partenaire-item',
  templateUrl: './partenaire-item.component.html',
  styleUrls: ['./partenaire-item.component.css']
})
export class PartenaireItemComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  @Input() partenaire: Partenaire;

  filepath = environment.filepath;

  public partenaireform = {
  	image: null,
  	name: null
  };

  selectedFile: File = null;

  constructor(
  	private msg: MessageService,
    private http: HttpClient,
  	private deleteservice: DeleteService
  	) { }

  ngOnInit(): void {
    this.partenaireform.name = this.partenaire.name;
  }

  delete(id) {
  	this.deleteservice.deleteData('partenaires', id).subscribe(res => {
  		console.log(res);
  		this.msg.setMessage('new operation');
  	});
  }

  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];

    const fd = new FormData();

    fd.append('image', this.selectedFile, this.selectedFile.name);
    fd.append('id', this.partenaire.id);


    this.http.post(environment.api+'/partenairepicupdate', fd).subscribe(res => {
      console.log(res);
      this.msg.setMessage('new operation');
    });
  }

  updatePartenaireName() {
    const fd = new FormData();

    fd.append('id', this.partenaire.id);
    fd.append('name', this.partenaireform.name);

    this.http.post(environment.api+'/updatepartenairename', fd).subscribe(res => {
      console.log(res);
      this.closebutton.nativeElement.click();
      this.msg.setMessage('new operation');
    });
  }

}
