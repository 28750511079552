<div class="container-fluid bg-gaj" style="min-height: 220px;">

</div>

<div class="reg-form col-lg-8 col-md-10 col-sm-12 mx-auto p-2 bg-white brad-1 shadow" style="margin-top: -80px; border-radius: 5px;">
    <h5 class="form-title text-center"> Reset Password </h5>
    <div class="row col-12 py-3">
  
      <div class="col-12 pt-5 pr-0">
  
        <form #ResetPasswordForm=ngForm (ngSubmit)="onSubmit()">
  
          <div class="form-group">
            <label for="inputEmail" class="col-sm-4 col-form-label">Email</label>
            <div class="col-sm-12">
              <input type="email" name="email" class="form-control" id="inputEmail" placeholder="Email" [(ngModel)]="form.email" required disabled>
            </div>
          </div>
  
          <div class="form-group">
            <label for="inputPassword5" class="col-sm-4 col-form-label">Password</label>
            <div class="col-sm-12">
              <input type="password" name="password" class="form-control" id="inputPassword5" placeholder="Password" [(ngModel)]="form.password" required>
            </div>
          </div>
  
          <div class="form-group">
            <label for="inputPassword6" class="col-sm-6 col-form-label">Confirm Password</label>
            <div class="col-sm-12">
              <input type="password" name="password_confirmation" class="form-control" id="inputPassword6" placeholder="Confirm Password" [(ngModel)]="form.password_confirmation" required>
            </div>
          </div>
  
          <div class="form-group">
            <div class="col-sm-12">
              <button type="submit" class="col-sm-12 btn text-white" style="background-color: #042331" [disabled]="!ResetPasswordForm.valid">Change Password</button>
            </div>
          </div>
  
        </form>
  
      </div>
  
    </div>
  </div>
  