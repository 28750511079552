import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-renseignements',
  templateUrl: './renseignements.component.html',
  styleUrls: ['./renseignements.component.css']
})
export class RenseignementsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
