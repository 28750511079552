<div class="publication-card card col-md-12 p-0 shadow" routerLink="/publication/{{publication?.pubid}}">
	<div class="header">
		<div class="avatar">
			<img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
			<img src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
		</div>
		<span>{{user?.fname}} {{user?.lname}}</span>
		<small>(Editeur)</small>
	</div>
	<div class="description bg-gaj">
		{{publication?.description}}
	</div>
	<div class="pub-files p-2">
		<img class="mx-1" src="{{filepath}}/publicationsimgs/{{publication?.image}}" style="height: 100%; max-width: 220px;" *ngIf="publication?.image != null">

		<video src="{{filepath}}/publicationsvideos/{{publication?.video}}" class="mx-1" controls style="height: 100%;" *ngIf="publication?.video != null">
          Sorry, your browser doesn't support embedded videos.
        </video>

		<div class="alert alert-primary col-md-8 text-center mx-auto" role="alert" *ngIf="publication?.image == null && publication?.video == null">
		  <h6 class="alert-heading" style="margin-top: 30px;">Aucun(e) photo/video à afficher!</h6>
		</div>
	</div>
	<div class="footer p-1 border-top">
		Commentaires ({{comments.length}})
	</div>
</div>
