<div class="p-3">
	<form (submit)="AddDocument($event)" autocomplete="off">
		<div class="row">
			<div class="form-group col-sm-12">
				<h5>Publier une annonce</h5>
			</div>
		</div>

		<div class="row col-12" id="addfilesuccess">
			<div class="alert alert-success col-sm-11 text-center mx-auto p-1 alert-dismissible fade show" role="alert">
			  Fichier a été ajoutée avec succée!
			  <button type="button" class="close m-0 py-1" data-dismiss="alert" aria-label="Close">
			    <span aria-hidden="true">&times;</span>
			  </button>
			</div>
		</div>		

		<div class="row">
			<div class="form-group col-sm-12">
				<label for="category" class="relative">Type de fichier <i class="fas absolute fa-asterisk mt-0 pt-0"></i></label>
				<select class="form-control form-control-sm col-12" id="category" name="category" [(ngModel)]="fileform.category" required #category="ngModel">
					<option value="" selected>Sélectionner Fichier</option>
					<option value="attest-bac">Attestation Baccaloréat</option>
					<option value="passport">passport</option>
					<option value="diplome">Diplome obtenu</option><!-- can be many files-->
					<option value="relevenotes">relevés des notes</option><!-- can be many files-->
				</select>

				<div *ngIf="category.invalid && (category.dirty || category.touched)" class="alert alert-danger p-0 mt-2">
				  <div *ngIf="category.errors.required">
				    Catégorie est obligatoire!
				  </div>
				</div>
			</div>
		</div>
		<div class="form-group col-sm-12 p-0">
			<div class="form-group col-sm-12 p-0">
				<label for="realname">Fichier</label>
				<label class="btn btn-sm col-12 btn-block text-gaj btn-light" for="realname"><i class="fas fa-file"></i></label>
				<input type="file" class="form-control-file" id="realname" name="realname" (change)="onFileSelected($event)" [(ngModel)]="fileform.realname" required  #realname="ngModel">
				<p id="filename"></p>
			</div>
			<div *ngIf="realname.invalid && (realname.dirty || realname.touched)" class="alert alert-danger p-0 mt-2">
				  <div *ngIf="realname.errors.required">
				    Catégorie est obligatoire!
				  </div>
				</div>
		</div>
		<div class="row mx-0 px-0 col-sm-12">
			<button type="submit" class="ml-auto btn btn-sm col-sm-4 btn-gaj">
				Ajouter
			</button>
		</div>
	</form>
</div>