import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publication } from '../models/Publication';
import { Cycle } from '../models/Cycle';
import { Filiere } from '../models/Filiere';
import { Document } from '../models/Document';
import { Message } from '../models/Message';
import { Notif } from '../models/Notif';
import { Actuality } from '../models/Actuality';
import { Inscription } from '../models/Inscription';
import { InscriptFile } from '../models/InscriptFile';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SelectService {

  server: string = environment.api;

  constructor(private http: HttpClient) { }

  getAll(table_name): Observable<any[]> {
    return this.http.get<any[]>(this.server + '/getAll/' + table_name);
  }

  getAllMsgUsersList(): Observable<Message[]> {
    return this.http.get<Message[]>(this.server+'/getAllMsgUsers');
  }



  getPubComments($pub_id) {
    return this.http.get<any[]>(this.server + '/getPubComments/'+$pub_id);
  }

  getCommentResponses($comment_id) {
    return this.http.get<any[]>(this.server + '/getCommentResponses/'+$comment_id);
  }

  getOnePublication($id): Observable<Publication> {
  	return this.http.get<Publication>(this.server+'/getOnePublication/'+$id);
  }

  getFavoritePublicationItem($id): Observable<Publication> {
    return this.http.get<Publication>(this.server+'/getFavoritePublicationItem/'+$id);
  }

  getOneCycle($id): Observable<Cycle[]> {
    return this.http.get<Cycle[]>(this.server+'/getOneCycle/'+$id);
  }

  getOneActuality($id): Observable<Actuality> {
    return this.http.get<Actuality>(this.server+'/getOneActuality/'+$id);
  }

  getFilieres($id): Observable<Filiere[]> {
    return this.http.get<Filiere[]>(this.server+'/getFilieres/'+$id);
  }

  selectUserFavorite($email): Observable<any[]> {
    return this.http.get<any[]>(this.server+'/selectUserFavorite/'+$email);
  }

  getOneNotifRow($email): Observable<any[]> {
    return this.http.get<any[]>(this.server+'/getUserNotifRow/'+$email);
  }

  getUserDocuments($email): Observable<Document[]> {
    return this.http.get<Document[]>(this.server+'/getDocuments/'+$email);
  }

  searchInscrit($email): Observable<any[]> {
    return this.http.get<any[]>(this.server+'/searchInscription/'+$email);
  }

  selectpubfiles($pub_id) {
    return this.http.get<any[]>(this.server+'/selectpubfiles/'+$pub_id);
  }

  getPosts(post_category): Observable<any[]> {
    return this.http.get<any[]>(this.server + '/getPosts/' + post_category);
  }

  getDemandeAccueil($status): Observable<any[]> {
    return this.http.get<any[]>(this.server + '/getDemandeAccueil/'+$status);
  }

  getAvisRecherche($status): Observable<any[]> {
    return this.http.get<any[]>(this.server+'/getAvisRecherche/'+$status);
  }

  getDiplomes(userid): Observable<any[]> {
    return this.http.get<any[]>(this.server+'/getDiplomes/'+userid);
  }


  getAllUserMessages($id): Observable<Message[]> {
    return this.http.get<Message[]>(this.server+'/getUserMessages/'+$id);
  }
  getAllAdminMessages($id): Observable<Message[]> {
    return this.http.get<Message[]>(this.server+'/getAdminMessages/'+$id);
  }

  getNotifs($email): Observable<Notif[]> {
    return this.http.get<Notif[]>(this.server+'/getNotifs/'+$email);
  }


  selectInscriptionItem($id): Observable<Inscription> {
    return this.http.get<Inscription>(this.server+'/selectInscriptionItem/'+$id);
  }

  getOneFiliere($id): Observable<Filiere> {
    return this.http.get<Filiere>(this.server+'/getOneFiliere/'+$id);
  }

  getInscriptItemFiles($id): Observable<InscriptFile[]> {
    return this.http.get<InscriptFile[]>(this.server+'/getInscriptItemFiles/'+$id)
  }

  getResetPasswordData($token): Observable<any> {
    return this.http.get<any>(this.server+'/getResetPasswordData/'+$token);
  }

}
