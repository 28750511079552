<div class="auth-header-container">
	<div id="sidebarmenu" class="sidebar border-right shadow-sm">
		<div class="sidebar-header border-bottom">
			<a id="logolg" class="navbar-brand" routerLink="/gaj/accueil">
				<img src="assets/logos/Logo.png">
			</a>
		</div>
		<div class="sidebar-body">
			<span class="border-bottom">Bienvenue <strong class="ml-2">{{user?.fname}}</strong></span>
			<a class="sidemenu-item" routerLink='/gaj/accueil'><i class="fas sidemenuicon fa-tachometer-alt"></i> <span>Accueil</span></a>
			<a class="sidemenu-item" routerLink='/gaj/publications'><i class="far sidemenuicon fa-newspaper"></i> <span>Publications</span></a>
			<a class="sidemenu-item" routerLink='/gaj/actualities'><i class="fas sidemenuicon fa-calendar-week"></i> <span>Actualités</span></a>
			<a class="sidemenu-item" id="renseignementid" (click)="togglerenseignement()" style="cursor: pointer;"><i class="fas sidemenuicon fa-blog"></i> <span>Renseignements</span></a>
			<div id="renseignementboxlink">
				<a class="sub-link" routerLink='/gaj/blog/presentation'><i class="fas sidemenuicon fa-info-circle"></i> <span class="sublinktext">A propos</span></a>
				<a class="sub-link" routerLink='/gaj/blog/etudes'><i class="fas sidemenuicon fa-university"></i> <span class="sublinktext">Etudes</span></a>
				<a class="sub-link" routerLink='/gaj/blog/accueil'><i class="fas sidemenuicon fa-times"></i> <span class="sublinktext">Accueil</span></a>
				<a class="sub-link" routerLink='/gaj/blog/amenagement'><i class="fas sidemenuicon fa-times"></i> <span class="sublinktext">Aménagement</span></a>
				<a class="sub-link" routerLink='/gaj/blog/carte-de-sejour'><i class="fas sidemenuicon fa-times"></i> <span class="sublinktext">Carte de Séjour</span></a>
				<a class="sub-link" routerLink='/gaj/blog/vie-estudiantine'><i class="fas sidemenuicon fa-user-graduate"></i> <span class="sublinktext">Vie Estudiantine</span></a>
			</div>
			<a class="sidemenu-item" style="font-size: 14px !important;" routerLink='/gaj/demandes-accueil' *ngIf="user?.role == 'admin'"><i class="fas sidemenuicon fa-hands"></i> <span>Demandes d'Acceuil</span></a>
			<a class="sidemenu-item" routerLink='/gaj/avis-recherche' *ngIf="user?.role == 'admin'"><i class="fas sidemenuicon fa-search-location"></i> <span>Avis de recherche</span></a>
			<a class="sidemenu-item" routerLink='/gaj/cycles'><i class="fas sidemenuicon fa-graduation-cap"></i> <span>Spécialités</span></a>
			<a class="sidemenu-item" routerLink='/gaj/mesfichiers' *ngIf="user?.role == 'user'"><i class="fas sidemenuicon fa-file"></i> <span>Mes Fichiers</span></a>
			<a class="sidemenu-item" routerLink='/gaj/suivi-demarches' *ngIf="user?.role == 'user'"><i class="fas sidemenuicon fa-eye"></i> <span>Suivi Démarches</span></a>
			<a class="sidemenu-item" routerLink='/gaj/etudiants' *ngIf="user?.role == 'admin'"><i class="fas sidemenuicon fa-user-graduate"></i> <span>Les Etudiants</span></a>
			<a class="sidemenu-item" routerLink='/gaj/inscriptions' *ngIf="user?.role == 'admin'"><i class="fas sidemenuicon fa-school"></i> <span>Inscriptions</span></a>
			<a class="sidemenu-item" routerLink='/gaj/partenaires' *ngIf="user?.role == 'admin'"><i class="fas sidemenuicon fa-handshake"></i> <span>Gérer Partenaires</span></a>
			<a class="sidemenu-item" routerLink='/gaj/gallery' *ngIf="user?.role == 'admin'"><i class="fas sidemenuicon fa-images"></i> <span>Gérer Galeries</span></a>
			<a class="sidemenu-item" routerLink='/gaj/support' *ngIf="user?.role == 'admin'"><i class="far sidemenuicon fa-question-circle"></i> <span>Supports</span></a>

			<!--<a class="sidemenu-item" routerLink='/gaj/inscription'><i class="fas sidemenuicon fa-user-plus"></i> <span>Inscription</span></a>
			<a class="sidemenu-item" routerLink='/gaj/messages' *ngIf="user?.userid != null && user?.role == 'admin'"><i class="far sidemenuicon fa-envelope"></i> <span>Messages</span></a>-->
		</div>

		<div class="sidebar-footer">
			<div class="side-footer-content">
				<div>
					<button class="text-white" (click)="openmsgbox()" *ngIf="user?.userid != null && user?.role == 'admin'"><i class="far fa-envelope"></i></button>



					<!--<div class="btn-group dropright">

					  <div class="dropdown-menu supportdropup" aria-labelledby="dropdownMenuButton">
					    <a class="dropdown-item" href="#">Action</a>
					    <a class="dropdown-item" href="#">Another action</a>
					    <a class="dropdown-item" href="#">Something else here</a>
					  </div>
					</div>-->

					<div class="btn-group dropright p-0">
					  <button class="text-white" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" *ngIf="user?.role == 'user'"><i class="far fa-question-circle"></i></button>
					  <div class="dropdown-menu bg-gaj p-0" style="margin-left: -54px; margin-bottom: 38px;">
					    <a class="dropdown-item py-0 text-white px-2" routerLink="/gaj/faqs"><i class="fas fa-question"></i> FAQs</a>
					    <a class="dropdown-item py-0 text-white px-2" routerLink="/gaj/contact-us"><i class="fas fa-envelope"></i> Contacter Nous</a>
					    <a class="dropdown-item py-0 text-white px-2" routerLink="/gaj/termes-et-conditions"><i class="fas fa-file-contract"></i> Termes et conditions</a>
					  </div>
					</div>



				</div>
				<div class="relative">
					<button class="text-white" id="notifbtn" routerLink="/gaj/notifications">
						<i class="far fa-bell"></i>
						<span class="badge badge-warning" *ngIf="notreadnotif != 0" style="top: 0; right: 0; position: absolute;">{{notreadnotif}}</span>
					</button>
				</div>
				<div>
					<button class="text-white" id="logoutbtn" (click)="logout($event)"><i class="fas fa-sign-out-alt"></i></button>
				</div>
			</div>
		</div>
	</div>
	<div id="topbarmenu" class="topbar d-flex border-bottom shadow-sm justify-content-between">
		<button onclick="showhidesidemenu()" class="toggle-sidebar-btn"><i id="showhidesidebar" class="fas fa-align-right"></i></button>
		<!--<div class="search-box">
			<input class="px-2 shadow-sm" type="search" name="search">
			<button class="search-lg shadow-sm"><i class="fas fa-search"></i></button>
			<button class="search-sm" onclick="showsearchbar()"><i class="fas fa-search"></i></button>
		</div>
		<div class="search-sm-box" id="searchsmbox">
			<input type="search" name="search" class="form-control form-control-sm col-10 my-auto">
			<button class="btn btn-gaj btn-sm my-auto searchbtn"><i class="fas fa-search"></i></button>
			<button type="button" class="close my-auto ml-auto" style="outline: none;" onclick="hidesearchbar()">
	          <span aria-hidden="true">&times;</span>
	        </button>
		</div>-->

    <div>
      <a href="" class="mr-3" (click)="logout($event)" type="button">Déconnecté</a>

      <button routerLink="/gaj/profile" class="profile-btn">
        <img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
        <img src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
        <span></span>
      </button>
    </div>
	</div>

</div>

<div class="messagebox" id="msgbox" onclick="openmsgboxStudent()" *ngIf="user?.role == 'user'">
	<i class="far fa-comments"></i>
</div>

<div class="msgcontainer" id="msgcontainer">
	<div class="msgsection">
		<div class="msgcontainerheader d-flex justify-content-between">
			<p>Messages</p>
			<button class="minimizebtn" onclick="closemsgbox()"><i class="fas fa-minus"></i></button>
		</div>

		<div class="msgcontainerbody" id="msgcontainerbody">
			<div class="msgcontent">
				<div *ngFor="let message of uxMessages">
					<!-- TEXT MESSAGE-->
					<div class="rightsidemsg" *ngIf="message.senderid == user.userid && message.msg != ''">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">{{message?.msg}}</div>
					</div>
					<div class="leftsidemsg" *ngIf="message.senderid != user.userid && message.msg != ''">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">{{message?.msg}}</div>
					</div>

					<div class="rightsidemsg" *ngIf="message.senderid == user.userid && message.file != null && message.fileType == 'img'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext"><img style="width: 100%;" src="{{message?.file}}"></div>
					</div>
					<div class="leftsidemsg" *ngIf="message.senderid != user.userid && message.file != null && message.fileType == 'img'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext"><img style="width: 100%;" src="{{message?.file}}"></div>
					</div>

					<div class="rightsidemsg" *ngIf="message.senderid == user.userid && message.file != null && message.fileType == 'video'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">
							<video  style="width: 100%;" src="{{message?.file}}" controls></video>
						</div>
					</div>
					<div class="leftsidemsg" *ngIf="message.senderid != user.userid && message.file != null && message.fileType == 'video'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">
							<video  style="width: 100%;" src="{{message?.file}}" controls></video>
						</div>
					</div>

          <div class="rightsidemsg" *ngIf="message.senderid == user.userid && message.file != null && message.fileType == 'doc'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">
							<a target="_blank" href="{{message?.file}}" style="max-width: 90%; font-size: 14px;">document-{{message?.created_at}}</a>
						</div>
					</div>
					<div class="leftsidemsg" *ngIf="message.senderid != user.userid && message.file != null && message.fileType == 'doc'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">
							<a target="_blank" href="{{message?.file}}" style="max-width: 90%; font-size: 14px;">document-{{message?.created_at}}</a>
						</div>
					</div>
          <!--
					<div class="rightsidemsg" *ngIf="message.senderid == user.userid && message.file != null && message.fileformat == 'audio'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}} <button (click)="delete(message.id)" class="deletemsgbtn text-danger"><i class="fas fa-times-circle"></i></button></span>
						<div class="msgtext">
							<audio  style="width: 100%;" src="http://localhost/GAJAcademy/public/MessageFiles/{{message?.file}}" controls></audio>
						</div>
					</div>
					<div class="leftsidemsg" *ngIf="message.senderid != user.userid && message.file != null && message.fileformat == 'audio'">
						<span>{{message?.created_at | date: "dd MMM, HH:m"}}</span>
						<div class="msgtext">
							<audio  style="width: 100%;" src="http://localhost/GAJAcademy/public/MessageFiles/{{message?.file}}" controls></audio>
						</div>
					</div>

					-->

				</div>
			</div>
		</div>

		<div class="msgcontainerfooter d-flex">
			<button class="btn m-auto btn-sm" data-toggle="modal" data-target="#sendfileinmessage"><i class="fas text-white fa-plus"></i></button>
			<form class="d-flex" (submit)="addTextMessage()" autocomplete="off">
				<input type="text" class="form-control form-control-sm my-auto ml-1" placeholder="Message..." name="textmsg" [(ngModel)]="message.text">
				<button type="submit" class="btn btn-sm btn-light text-gaj my-auto mx-1 sendbtn"><i class="fas fa-paper-plane"></i></button>
			</form>
		</div>

	</div>
</div>

<div id="msglistadmin" *ngIf="user?.role == 'admin'">
	<div class="header border-bottom">
		<h5>Messages</h5>
	</div>
	<!--<div style="width: 249px; height: 35px; background: #fff; box-shadow: 0px 5px 8px 2px rgba(0,0,0,.3); line-height: 35px; padding: 0 10px; font-size: 14px;">
		214 discussions
	</div>-->
	<div class="body">
		<div *ngFor="let messenger of msgUserList">
			<app-messenger-item [messenger]="messenger"></app-messenger-item>
		</div>
	</div>
</div>

<!-- Add Actualité Modal -->
<div class="modal fade" id="sendfileinmessage" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
	  <div class="modal-content">
		<div class="modal-body p-0">
		  <button type="button" #selectFileMessageModal class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
			<i class="fas fa-times closemodalicon"></i>
		  </button>


		  <ul class="nav nav-pills p-0 mb-3 col-12" id="pills-tab" role="tablist">
			<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link active radius-0 text-center" id="img-tab" data-toggle="pill" href="#img" role="tab" aria-controls="img" aria-selected="true"><i class="fas fa-image"></i></a>
			</li>
			<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link radius-0 text-center" id="video-tab" data-toggle="pill" href="#video" role="tab" aria-controls="video" aria-selected="false"><i class="fas fa-film"></i></a>
			</li>
			<!--<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link radius-0 text-center" id="audio-tab" data-toggle="pill" href="#audio" role="tab" aria-controls="audio" aria-selected="false"><i class="fas fa-microphone"></i></a>
			</li>-->
			<li class="nav-item col-3 p-0" role="presentation">
			  <a class="nav-link radius-0 text-center" id="otherfile-tab" data-toggle="pill" href="#otherfile" role="tab" aria-controls="otherfile" aria-selected="false"><i class="fas fa-file-medical"></i></a>
			</li>
		  </ul>
		  <div class="tab-content" id="pills-tabContent">
			<div class="tab-pane fade show active p-3" id="img" role="tabpanel" aria-labelledby="img-tab">
				<img id="imgtosendpreview" src="" alt="" class="d-none">
				<label for="imgtosend" class="btn btn-block btn-primary">Sélectionner ton image</label>
				<input type="file" name="imgtosend" id="imgtosend" class="d-none" (change)="selectImage($event)">
        <div class="row mx-0">
          <button class="btn ml-auto btn-gaj" (click)="addImgMsg()">Envoyer</button>
        </div>

			</div>


			<div class="tab-pane fade p-3" id="video" role="tabpanel" aria-labelledby="video-tab">
				<video id="videotosendpreview" src="" class="d-none"></video>
				<label for="videotosend" class="btn btn-block btn-primary">Sélectionner ton video</label>
				<input type="file" name="videotosend" id="videotosend" class="d-none" (change)="selectVideo($event)">
        <div class="row mx-0">
          <button class="btn ml-auto btn-gaj" (click)="addVideoMsg()">Envoyer</button>
        </div>

			</div>


			<div class="tab-pane fade p-3" id="audio" role="tabpanel" aria-labelledby="audio-tab">
				<audio id="audiotosendpreview" src="" class="d-none"></audio>
				<label for="audiotosend" class="btn btn-block btn-primary">Sélectionner ton audio</label>
				<input type="file" name="audiotosend" id="audiotosend" class="d-none" (change)="addAudioMsg($event)">
			</div>


			<div class="tab-pane fade p-3" id="otherfile" role="tabpanel" aria-labelledby="otherfile-tab">
				<p id="filepreview" class="d-none"></p>
				<label for="filetosend" class="btn btn-block btn-primary">Sélectionner ton fichier</label>
				<input type="file" name="filetosend" id="filetosend" class="d-none" (change)="selectDoc($event)">
        <div class="row mx-0">
          <button class="btn ml-auto btn-gaj" (click)="addDocMsg()">Envoyer</button>
        </div>
			</div>
		  </div>



		</div>
	  </div>
	</div>
</div>
