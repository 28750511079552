export class CommentResponse {
	id: string;
	comment_id: string;
	email: string;
	commenttext: string;
	created_at: string;

	constructor(id, comment_id, email, commenttext, created_at) {
		this.id = 'not set';
		this.comment_id = comment_id;
		this.email = email;
		this.commenttext = commenttext;
		this.created_at = created_at;
	}
}