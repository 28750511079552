import { Component, OnInit } from '@angular/core';
import { SelectService } from '../../services/select.service';
import { Actuality } from '../../models/Actuality';
@Component({
  selector: 'app-allactualities',
  templateUrl: './allactualities.component.html',
  styleUrls: ['./allactualities.component.css']
})
export class AllactualitiesComponent implements OnInit {
  actualities: Actuality[] = [];
  constructor(
  	private select: SelectService
  	) { }

  ngOnInit(): void {
  	this.select.getAll('actualities').subscribe(res => {
  		this.actualities = res.reverse();
  		console.log(this.actualities);
  	});
  }

}
