<div class="py-3">
	<div class="row col-12 justify-content-end">
		<button class="btn btn-gaj btn-sm" data-toggle="modal" data-target="#addPartenaire">
			<i class="fas fa-plus"></i> Ajouter Partenaire
		</button>
	</div>
</div>
<div class="p-3 bg-white partenaires-box">
	<div class="row m-1" *ngFor="let partenaire of partenaires">
		<app-partenaire-item [partenaire]="partenaire"></app-partenaire-item>
	</div>
</div>



<!-- Modal Add Partenaire -->
<div class="modal fade" id="addPartenaire" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        
        <form class="p-3" (submit)="AddPartenaire($event)" autocomplete="off">
        	<div class="form-group">
			    <label for="image">Sélectionner image</label>
			    <input type="file" class="form-control-file" id="image" name="image" [(ngModel)]="partenaireform.image" required (change)="onImageSelected($event)">
			</div>
        	<div class="row">
				<div class="form-group col-sm-12">
				    <label for="name">Nom du Partenaire</label>
				    <input type="text" class="form-control form-control-sm" id="name" name="name" placeholder="Nom du Partenaire" [(ngModel)]="partenaireform.name" required>
				</div>
			</div>
			<div class="row mx-0 px-0 col-sm-12">
				<button type="submit" class="ml-auto btn-block btn btn-sm btn-gaj">
					Ajouter
				</button>
			</div>
        </form>

      </div>
    </div>
  </div>
</div>