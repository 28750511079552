<section style=" height: auto;">
  <div class="" style="background: #921831; height: 360px; padding-top: 120px;"></div>
    <h1 class="text-center text-white section-title-animate" style="margin-top: -250px;">{{cycle?.name}}</h1>
    <div class="card my-3 col-lg-10 col-md-11 col-sm-12 mx-auto bg-white border shadow">
        <div class="card-body">
            <h5 class="card-title">
                Description:
            </h5>
            <hr class="my-1">
            {{cycle?.description}}
            <div class="alert alert-info text-center" *ngIf="cycle?.description == null">Aucune Description pour cette spécialité.</div>
        </div>
    </div>
    <div class="card col-lg-10 col-md-11 col-sm-12 mx-auto bg-white border shadow" style="height: auto;">
        <div class="card-body">
            <h5 class="card-title">
                Liste des Filières:
            </h5>
            <div class="accordion" id="accordionExample">
                <div class="card" *ngFor="let filiere of filieres">
                  <div class="card-header p-0" id="headingOne">
                    <h2 class="mb-0">
                      <a class="btn btn-block text-left filirename" type="button" data-toggle="collapse" href="#filiere{{filiere?.id}}" aria-expanded="true" aria-controls="collapseOne">
                        {{filiere?.name}}
                      </a>
                    </h2>
                  </div>
              
                  <div id="filiere{{filiere?.id}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
                    <div class="card-body">
                        {{filiere?.description}}
                        <div class="alert alert-info text-center" *ngIf="filiere?.description == null">Aucune Description pour cette filière.</div>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</section>