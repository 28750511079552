import { Component, OnInit, ViewChild } from '@angular/core';
import { User } from '../../../models/User';
import { UserdataService } from '../../../services/userdata.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../services/message.service';
import { BlogPost } from '../../../models/BlogPost';
import { SelectService } from '../../../services/select.service';
import { DeleteService } from 'src/app/services/delete.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-accueilblog',
  templateUrl: './accueilblog.component.html',
  styleUrls: ['./accueilblog.component.css']
})
export class AccueilblogComponent implements OnInit {
  @ViewChild('closedeletebutton') closedeletebutton: any;

  currentuser: User;
  currentmail: any;

  filepath = environment.filepath;

  posts: BlogPost[] = [];

  selectedImageFile: File = null;
  selectedVideoFile: File = null;
  selectedCustomFile: File = null;

  public bpform = {
    email: null,
    userid: null,
    category: "",
    image: null,
    video: null,
    description: null
  };

  public demandeAcceuil = {
    name: '',
    prenom: '',
    payorigine: '',
    date: '',
    time: '',
    destination: '',
    customFile: '',
    status: '0'
  };

  formSubmitted: boolean = false;

  constructor(
  	private userdata: UserdataService,
  	private http: HttpClient,
    private msg: MessageService,
    private select: SelectService,
    private deleteservice: DeleteService,
  	) { }

  ngOnInit(): void {
  	this.currentmail = this.getMail();

  	this.select.getPosts('accueil').subscribe(data => {
  		this.posts = data.reverse();
  	});

    this.msg.getMessage().subscribe(res => {
      this.select.getPosts('accueil').subscribe(data => {
        this.posts = data.reverse();
      });
    });

  	this.userdata.getUserData(this.currentmail).subscribe(data => {
  		this.currentuser = data[0];

  		this.bpform.email = this.currentuser.email;
  		this.bpform.userid = this.currentuser.userid;
  		this.bpform.category = 'accueil';
  	});
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  onImageSelected(event) {
    this.selectedImageFile = <File>event.target.files[0];
  }

  onCustomFileSelected(event) {
    this.selectedCustomFile = <File>event.target.files[0];
  }

  onVideoSelected(event) {
    //document.getElementById('videoname').innerHTML = '';
    this.selectedVideoFile = <File>event.target.files[0];
    //document.getElementById('videoname').innerHTML = this.selectedVideoFile.name;
  }

  AddBlogPost(e) {

  	const fd = new FormData();

  	fd.append('email', this.bpform.email);
  	fd.append('userid', this.bpform.userid);
  	fd.append('category', this.bpform.category);
  	fd.append('description', this.bpform.description);
  	fd.append('image', this.selectedImageFile);
  	fd.append('video', this.selectedVideoFile);

  	this.http.post(environment.api+'/addBlogPost', fd).subscribe(res => {
      this.msg.setMessage("new operation");

      this.bpform.description = null;
      this.selectedImageFile = null;
      this.selectedVideoFile = null;
      //document.getElementById('imgname').innerHTML = '';
      //document.getElementById('videoname').innerHTML = '';
    });
  }

  AddDemandeAcceuil(e) {
    const fd  = new FormData();

    fd.append('name', this.demandeAcceuil.name);
    fd.append('prenom', this.demandeAcceuil.prenom);
    fd.append('payorigine', this.demandeAcceuil.payorigine);
    fd.append('date', this.demandeAcceuil.date);
    fd.append('time', this.demandeAcceuil.time);
    fd.append('destination', this.demandeAcceuil.destination);
    fd.append('customFile', this.selectedCustomFile);
    fd.append('status', this.demandeAcceuil.status);



    this.http.post(environment.api+'/addDemandeAcceuil', fd).subscribe(res => {
      this.msg.setMessage("new operation");

      document.getElementById('confirmAccueilAdd').style.display = "block";
      setTimeout(() => {
        document.getElementById('confirmAccueilAdd').style.display = "none";
      }, 5000);

      this.demandeAcceuil.name = null;
      this.demandeAcceuil.prenom = null;
      this.demandeAcceuil.payorigine = null;
      this.demandeAcceuil.date = null;
      this.demandeAcceuil.time = null;
      this.selectedCustomFile = null;
    });
  }

  deleteBlogPost(id) {
    this.deleteservice.deleteData('blog_posts', id).subscribe(res => {
      this.msg.setMessage("new operation");
      this.closedeletebutton.nativeElement.click();
    });
  }

}
