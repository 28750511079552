import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publication } from '../models/Publication';
import { Inscription } from '../models/Inscription';
import { BlogPost } from '../models/BlogPost';
import { Notif } from '../models/Notif';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UpdateService {

  server: string = environment.api;

  constructor(private http: HttpClient) { }

  updatePublication(table_name, data): Observable<any> {
    return this.http.post<any>(this.server + '/updatepublication', {table_name, data});
  }

  updateActuality(table_name, data): Observable<any> {
    return this.http.post<any>(this.server + '/updateactuality', {table_name, data});
  }

  updateActualityPic(data): Observable<any> {
    return this.http.post<any>(this.server + '/updateActualityPic', data);
  }

  updateCycle(table_name, data): Observable<any> {
    return this.http.post<any>(this.server + '/updatecycle', {table_name, data});
  }

  updateFiliere(table_name, data): Observable<any> {
    return this.http.post<any>(this.server + '/updatefiliere', {table_name, data});
  }

  updateBlogPost(data): Observable<BlogPost> {
    return this.http.post<BlogPost>(this.server+'/updateblogitem', data)
  }



  showNotif(row_name, useremail): Observable<any> {
    return this.http.post<any>(this.server+ '/shownotif', {row_name, useremail});
  }

  hideNotif(row_name, useremail): Observable<any> {
    return this.http.post<any>(this.server+ '/hidenotif', {row_name, useremail});
  }

  acceptInscription(id): Observable<any> {
    return this.http.post<any>(this.server+ '/acceptInscription', {id});
  }

  rejectInscription(id): Observable<any> {
    return this.http.post<any>(this.server+ '/rejectInscription', {id});
  }

  /*deleteData(table_name, id): Observable<any> {
    return this.http.post<any>(this.server + 'deletedata', {table_name, id});
  }*/

  updateStatusID(table_name, id): Observable<any> {
    return this.http.post<any>(this.server+'/updateStatusID', {table_name, id});
  }
  updateStatusEMAIL(table_name, email): Observable<any> {
    return this.http.post<any>(this.server+'/updateStatusEMAIL', {table_name, email});
  }


  BlockUser(id, status): Observable<any> {
    return this.http.post<any>(this.server+'/blockUser', {id, status});
  }

  updatePubNotifStatus(pubid, email): Observable<Notif> {
    return this.http.post<Notif>(this.server+'/updatePubNotifStatus', {pubid, email});
  }

  deleteBlogPostImg(id): Observable<any> {
    return this.http.post<any>(this.server+ '/deleteBlogPostImg', id);
  }
  deleteBlogPostVideo(id): Observable<any> {
    return this.http.post<any>(this.server+ '/deleteBlogPostVideo', id);
  }

  updateBlogPostPic(data): Observable<any> {
    return this.http.post<any>(this.server + '/updateBlogPostPic', data);
  }
  updateBlogPostVideo(data): Observable<any> {
    return this.http.post<any>(this.server + '/updateBlogPostVideo', data);
  }

}
