import { Component, OnInit, Input } from '@angular/core';
import { AvisRecherche } from '../../../models/AvisRecherche';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';

@Component({
  selector: 'app-avis-item',
  templateUrl: './avis-item.component.html',
  styleUrls: ['./avis-item.component.css']
})
export class AvisItemComponent implements OnInit {

  @Input() avis: AvisRecherche;
  constructor(
  	private updateservice: UpdateService,
  	private msg: MessageService
  	) { }

  ngOnInit(): void {
  }

  updateStatus($id) {
  	this.updateservice.updateStatusID('avis_recherches', $id).subscribe(res => {
  		this.msg.setMessage('new Operation');
  	});
  }

}
