import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Cycle } from '../../../models/Cycle';
import { ActivatedRoute } from '@angular/router';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';
import { DeleteService } from '../../../services/delete.service';
import { User } from '../../../models/User';
import { UserdataService } from '../../../services/userdata.service';

@Component({
  selector: 'app-cycle-item',
  templateUrl: './cycle-item.component.html',
  styleUrls: ['./cycle-item.component.css']
})
export class CycleItemComponent implements OnInit {
  @ViewChild('closebutton') closebutton: any;

  @Input() cycle: Cycle;

  public cycleform = {
   id: '',
    email: '',
    cycle_id: '',
    name: '',
    description: ''
  };
  confirm: any;

  user: User;
  role: any;
  currentusermail: any;

  constructor(
  	private userdataservice: UserdataService,
    private update: UpdateService,
    private msg: MessageService,
    private deleteservice: DeleteService
   ) {}

  ngOnInit(): void {
   this.currentusermail = this.getMail();
   this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  		this.role = this.user.role;
  	});

   this.cycleform.id = this.cycle.id;
   this.cycleform.email = this.cycle.email;
   this.cycleform.cycle_id = this.cycle.cycle_id;
   this.cycleform.name = this.cycle.name;
   this.cycleform.description = this.cycle.description;
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  UpdateCycle() {
   this.update.updateCycle('cycles', this.cycleform).subscribe((data) => {
      console.log('Service updated successfully');

      var confirmboxid = 'confirmmessage'+this.cycle.id;

      setTimeout(function() {
        document.getElementById(confirmboxid).style.display = "block";
      }, 1000);
      this.msg.setMessage('an other operation done');
      this.closebutton.nativeElement.click();
      setTimeout(function() {
        document.getElementById(confirmboxid).style.display = "none";
      }, 8000);
    });
  }

  Delete() {
    this.deleteservice.deleteData('cycles', this.cycle.id).subscribe((res) => {
      var deleteboxid = 'deletemessage'+this.cycle.id;

      setTimeout(function() {
        document.getElementById(deleteboxid).style.display = "block";
      }, 2000);
      this.msg.setMessage('an other operation done');
      setTimeout(function() {
        document.getElementById(deleteboxid).style.display = "none";
      }, 8000);
    });
  }
}
