<app-not-auth-header></app-not-auth-header>

<div style="height: auto !important">
  <router-outlet></router-outlet>
</div>

<div class="footer p-5 shadow">
  <div class="row col-12">
    <div class="col-md-6">
      <h4 class="card-title">Group Atua Junior</h4>
      <div class="d-flex my-1">
        <i class="fas fa-map-marker-alt"></i>
        <span class="text-gaj">Tunisie, R.D. Congo</span>
      </div>
      <div class="d-flex my-1">
        <i class="far fa-envelope"></i>
        <span
          ><a class="text-gaj" href="mailto:direction@groupe-atuajunior.com"
            >direction@groupe-atuajunior.com</a
          ></span
        >
      </div>
      <div class="d-flex my-1">
        <i class="fas fa-phone-alt"></i>
        <span class="text-gaj">+216 54 490 638 / +243 827 445 329</span>
      </div>

      <div class="d-flex my-1">
        <i class="fab fa-whatsapp"></i>
        <span class="text-gaj">+216 54 490 638 / +243 827 445 329</span>
      </div>

      <div class="d-flex my-1">
        <i class="fas fa-globe"></i>
        <span
          ><a
            class="text-gaj"
            target="_blank"
            href="https://www.groupe-atuajunior.com"
            >www.groupe-atuajunior.com</a
          ></span
        >
      </div>
    </div>
    <div class="col-md-6 autres-liens">
      <h4>Autres Liens</h4>
      <a class="text-gaj" routerLink="/accueil">Acceuil</a><br />
      <a class="text-gaj" routerLink="/présentation">Présentation</a><br />
      <a class="text-gaj" routerLink="/départements">Départements</a><br />
      <a
        class="text-gaj"
        data-toggle="modal"
        href="#authmodal"
        style="min-width: 120px"
        >Se Connecter</a
      >
    </div>
  </div>
</div>
<div class="copy-right shadow d-flex justify-content-between">
  <p>
    <small
      ><i class="far fa-copyright"></i> Tout droit réservé à Groupe Atua
      Junior.</small
    >
  </p>
  <div class="social-links d-flex">
    <a
      target="_blank"
      href="https://www.facebook.com/profile.php?id=100093196057996&amp;mibextid=ZbWKwL"
      ><i class="fab fa-facebook-f"></i
    ></a>
    <a target="_blank" href="https://www.linkedin.com/company/gaj-network/"
      ><i class="fab fa-linkedin-in"></i
    ></a>
    <a target="_blank" href="https://www.instagram.com/groupeatuajunior/"
      ><i class="fab fa-instagram"></i
    ></a>
  </div>
</div>

<!--<app-not-auth-footer></app-not-auth-footer>-->
