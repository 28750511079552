import { Component, Input, OnInit } from '@angular/core';
import { CommentResponse } from 'src/app/models/CommentResponse';
import { User } from 'src/app/models/User';
import { SelectService } from 'src/app/services/select.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-response-item',
  templateUrl: './response-item.component.html',
  styleUrls: ['./response-item.component.css']
})
export class ResponseItemComponent implements OnInit {

  filepath = environment.filepath;

  @Input() response: CommentResponse;
  user: User;
  constructor(
    private select: SelectService,
    private userdata: UserdataService,
  ) { }

  ngOnInit(): void {
    this.userdata.getUserData(this.response.email).subscribe(res => {
  		this.user = res[0];
  	});
  }

}
