export class Inscription {
    id: string;
    filiere_id: string;
    niveau: number;
    nom: string;
    postnom: string;
    prenom: string;
    nationality: string;
    passportcin: string;
    email: string;
    tlf: string;
    diplome: string;
    status: string;
    created_at: string

    constructor(id, filiere_id, niveau, nom, postnom, prenom, nationality, passportcin, email, tlf, diplome, status, created_at) {
        this.filiere_id = filiere_id;
        this.niveau = niveau;
        this.nom = nom;
        this.postnom = postnom;
        this.prenom = prenom;
        this.nationality = nationality;
        this.passportcin = passportcin;
        this.email = email;
        this.tlf = tlf;
        this.diplome = diplome;
        this.status = status;
        this.created_at = created_at;
    }
}


