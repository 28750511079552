import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-publication-form',
  templateUrl: './add-publication-form.component.html',
  styleUrls: ['./add-publication-form.component.css']
})
export class AddPublicationFormComponent implements OnInit {

  selectedImageFile: File = null;
  selectedVideoFile: File = null;

  public pubform = {
    pubid: null,
    email: null,
    category: "",
    description: null,
    image: null,
    video: null,
    show: null
  };

  formSubmitted: boolean = false;
  currentusermail: any;

  constructor(
    private http: HttpClient,
    private msg: MessageService
    ) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();

  	this.pubform.email = this.currentusermail;
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  onImageSelected(event) {
    this.selectedImageFile = <File>event.target.files[0];
    //console.log(this.selectedImageFile.name);
    document.getElementById('imgname').innerHTML = this.selectedImageFile.name;
  }

  onVideoSelected(event) {
    document.getElementById('videoname').innerHTML = '';
    this.selectedVideoFile = <File>event.target.files[0];
    document.getElementById('videoname').innerHTML = this.selectedVideoFile.name;
  }

  AddPublication(e) {
    e.preventDefault();

    this.pubform.pubid = '';

    const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const l = 16;
    let randomstring = '';
    for (let i=0; i<l; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring = randomstring + chars.substring(rnum, rnum+1);
    }

    this.pubform.pubid = this.pubform.pubid + randomstring;

    this.pubform.show = 1;
    const fd = new FormData();
    fd.append('email', this.pubform.email);
    fd.append('category', this.pubform.category);
    fd.append('description', this.pubform.description);
    fd.append('show', this.pubform.show);

    fd.append('image', this.selectedImageFile);
    fd.append('video', this.selectedVideoFile);
    fd.append('pubid', this.pubform.pubid);

    this.http.post(environment.api+'/addPublication', fd).subscribe(res => {
      console.log(res);

      this.msg.setMessage("new operation");
      document.getElementById('addsuccess').style.display = "block";

      setTimeout(function() {
        document.getElementById('addsuccess').style.display = "none";
      }, 8000);

      this.pubform.category = null;
      this.pubform.description = null;
      this.selectedImageFile = null;
      this.selectedVideoFile = null;
      this.pubform.pubid = null;
      document.getElementById('imgname').innerHTML = '';
      document.getElementById('videoname').innerHTML = '';
    });
  }

}
