<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item" role="presentation">
    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Avis en cours <span class="badge badge-danger">{{allavis.length}}</span></a>
  </li>
  <li class="nav-item" role="presentation">
    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Avis terminé</a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
	
	<div class="tab-pane px-2 py-3 fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
	  <div class="row col-12 cards-box">
	  	<div class="" *ngFor="let avis of allavis">
	  		<app-avis-item [avis]="avis"></app-avis-item>
	  	</div>
	  </div>
	</div>
  <div class="tab-pane px-2 py-3 fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
  	<div class="row col-12 cards-box">
	  	<div class="" *ngFor="let avis of doneavis">
	  		<app-avis-item [avis]="avis"></app-avis-item>
	  	</div>
	  </div>
  </div>
</div>