import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Publication } from '../models/Publication';
import { Filiere } from '../models/Filiere';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DeleteService {

  server: string = environment.api;

  constructor(private http: HttpClient) { }

  deleteData(table_name, id): Observable<any> {
    return this.http.post<any>(this.server + '/deletedata', {table_name, id});
  }

  deletefavoris(email, pubid): Observable<any> {
    return this.http.post<any>(this.server + '/deletefavoris', {email, pubid});
  }

  deleteinitpic(id) {
    return this.http.post<any>(this.server + '/deleteinitpic', id);
  }
  deleteinitvideo(id) {
    return this.http.post<any>(this.server + '/deleteinitvideo', id);
  }

  deletepicorvideo(id) {
    return this.http.post<any>(this.server + '/deletepicorvideo', id);
  }
}
