<div class="d-flex" *ngIf="user?.email != currentusermail">
	<div style="width: 36px; height: 36px; border-radius: 50%; overflow: hidden;">
		<a data-toggle="modal" href="#showuseravatar{{user?.userid}}">
      <img style="width: 100%; height: 100%;" src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
      <img style="width: 100%; height: 100%;" src="assets/img/avatar.png" *ngIf="user?.avatar == null">
    </a>
	</div>
	<div class="d-flex" style="line-height: 36px;">
		<div class="d-flex">
			<span class="mx-2 info-item">{{user?.lname}} {{user?.fname}}</span>
			<span class="mx-2 info-item">{{user?.email}}</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="user?.phone != null">{{user?.phone}}</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="user?.phone == null">--</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="user?.passport != null">{{user?.passport}}</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="user?.passport == null">--</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="user?.nationality != null">{{user?.nationality}}</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="user?.nationality == null">--</span>
			<!--<span class="mx-2 info-item">{{user?.residencecountry}}</span>-->
		</div>
		<div class="d-flex">
			<button (click)="blockUser(user?.id)" class="btn mx-1 my-auto btn-sm btn-danger" *ngIf="user?.status == 0">Bloquer</button>
			<button (click)="unblockUser(user?.id)" class="btn mx-1 my-auto btn-sm btn-warning" *ngIf="user?.status == 1">Débloquer</button>
			<a data-toggle="modal" href="#useritemmodal{{user?.userid}}" class="btn mx-1 my-auto btn-sm btn-primary">Info</a>
		</div>
	</div>
</div>





<!-- Modal -->
<div class="modal fade" id="showuseravatar{{user?.userid}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog mt-5">
    <div class="modal-content transparent-modal">
      <div class="modal-body p-0">
      	<button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
       	<img class="avatarimginmodal" src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
       	<img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="useritemmodal{{user?.userid}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg mt-5 p-0">
    <div class="modal-content p-0" style="overflow: hidden;">
      <div class="modal-body p-0">
      	<button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

       	<div class="row col-12 p-0">
       		<div class="col-sm-5 p-0">
       			<img style="width: 100%;" class="avatarimginmodal" src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
		       	<img style="width: 100%;" src="assets/img/avatar.png" *ngIf="user?.avatar == null">
       		</div>
       		<div class="col-sm-7 p-3">
       			{{user?.fname}} {{user?.lname}}<br>
       			{{user?.email}}<br>
       			{{user?.phone}}<br>
       			{{user?.passport}}<br>
       			{{user?.nationality}}<br>
       			{{user?.residencecountry}}<br>
       			{{user?.naissance}}
       		</div>
       	</div>
      </div>
    </div>
  </div>
</div>
