<div class="p-3" *ngIf="user?.role == 'admin'">
	

	<div class="alert alert-info m-5 text-center" role="alert" *ngIf="contacts.length == 0">
      Aucunce notification à afficher pour le moment!
    </div>

    <div class="list-group" *ngFor="let contact of contacts">

      <div class="card" *ngIf="contact?.status == 0">
	    <div class="card-body">
		    <div class="row mx-auto p-0 justify-content-between" style="display: flex; flex-wrap: wrap;">
		    	<p class="mb-2"><strong>{{contact?.name}} - <small>{{contact?.created_at | date: 'dd, MMM YYYY HH:mm'}}</small></strong></p>
		        <p class="text-center mb-2 text-muted"><strong>{{contact?.email}}</strong></p>
		        <button class="btn btn-sm mb-2 btn-gaj" (click)="marquercommelu(contact.id)">Marquer comme lu</button>
		    </div>
	        <p class="card-text"><strong>{{contact?.message}}</strong></p>
	    </div>
	  </div>

      <div class="card" *ngIf="contact?.status == 1">
	    <div class="card-body">
	    	<div class="row mx-auto p-0 justify-content-between" style="display: flex; flex-wrap: wrap;">
	    		<p class="my-0">{{contact?.name}} - <small>{{contact?.created_at | date: 'dd, MMM YYYY'}}</small></p>
	        	<p class="my-0 text-muted">{{contact?.email}}</p>
	    	</div>
	        <p class="my-0">{{contact?.message}}</p>
	    </div>
	  </div>
    </div>


</div>

<div *ngIf="user?.role != 'admin'">
	

	<div class="col-lg-8 col-md-10 col-sm-12 mx-auto p-3 my-5 bg-white border" style="min-height: 120px">
		<h5 class="card-title">Contactez-Nous</h5>
		<hr>
		<form #contactform=ngForm (submit)="addContactUs($event)">
			<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="name">Votre Nom Complet <strong class="required-star">*</strong></label>
				    <input type="text" class="form-control form-control-sm" name="name" id="name" [(ngModel)]="name" required placeholder="Nom & Prènom">
				</div>
			</div>
			<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="email">Votre Email <strong class="required-star">*</strong></label>
				    <input type="email" class="form-control form-control-sm" name="email" id="email" [(ngModel)]="email" required placeholder="Email">

				</div>
			</div>

			<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="message">Votre Message <strong class="required-star">*</strong></label>
				    <textarea rows="3" class="form-control form-control-sm" name="message" id="message" [(ngModel)]="message" required placeholder="Message..."></textarea>
				</div>
			</div>
			<div class="row mx-0 px-3">
				<button type="submit" [disabled]="!contactform.valid" class="messagesubmitbtn btn btn-gaj btn-block">
					Envoyer
				</button>
			</div>
		</form>
	</div>


</div>

<div id="confirmAddContact" class="confirm-message py-1 px-3 bg-success text-center ml-auto shadow text-white" style="z-index: 1; bottom: 0; left: 0; right: 0; position: fixed; margin-bottom: 70px; margin-right: 25px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 60px; line-height: 50px;">
	<i class="fas fa-check-circle"></i> Votre message a été envoyé!
</div>