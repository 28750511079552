<div class="row mt-2 mx-0 col-12 justify-content-between notif-setting">
  <div class="notif-page-title">
    <h4><i class="fas fa-bell"></i> Notifications</h4>
  </div>
  <button
    class="btn"
    type="button"
    id="dropdownMenuButton"
    onclick="togglenotifbox()"
  >
    <i class="fas fa-cogs"></i>
  </button>
</div>
<hr class="my-0" />
<div
  id="notifsettingbox"
  class="shadow"
  style="
    background: #fff;
    transition: 0.8s;
    width: 0px;
    height: calc(100vh - 55px);
    top: 0px;
    right: 0;
    position: absolute;
    overflow: hidden;
    z-index: 20000;
  "
>
  <div class="d-flex justify-content-between my-2 px-2">
    <h5 class="settingtitle">Paramètres</h5>
    <button onclick="hidenotifbox()" class="btn hidebtn">
      <i class="fas fa-chevron-right"></i>
    </button>
  </div>
  <div class="notif-item bordertop d-flex justify-content-between">
    <div class="notif-item-text">Actualités</div>
    <div class="notif-item-btn" *ngIf="notif?.actuality == '0'">
      <button class="btn btn-primary" (click)="showNotif('actuality')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.actuality == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('actuality')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>
  <div class="notif-item bordertop d-flex justify-content-between">
    <div class="notif-item-text">Officiel</div>
    <div class="notif-item-btn" *ngIf="notif?.officiel == '0'">
      <button class="btn btn-primary" (click)="showNotif('officiel')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.officiel == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('officiel')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>

  <div class="notif-item d-flex justify-content-between">
    <div class="notif-item-text">GAJ Consulting</div>
    <div class="notif-item-btn" *ngIf="notif?.gajconsulting == '0'">
      <button class="btn btn-primary" (click)="showNotif('gajconsulting')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.gajconsulting == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('gajconsulting')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>

  <div class="notif-item d-flex justify-content-between">
    <div class="notif-item-text">GAJ Training</div>
    <div class="notif-item-btn" *ngIf="notif?.gajTraining == '0'">
      <button class="btn btn-primary" (click)="showNotif('gajTraining')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.gajTraining == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('gajTraining')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>

  <div class="notif-item d-flex justify-content-between">
    <div class="notif-item-text">GAJ Scholarship</div>
    <div class="notif-item-btn" *ngIf="notif?.gajScholarship == '0'">
      <button class="btn btn-primary" (click)="showNotif('gajScholarship')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.gajScholarship == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('gajScholarship')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>

  <div class="notif-item d-flex justify-content-between">
    <div class="notif-item-text">GAJ Fablab</div>
    <div class="notif-item-btn" *ngIf="notif?.gajFablab == '0'">
      <button class="btn btn-primary" (click)="showNotif('gajFablab')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.gajFablab == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('gajFablab')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>

  <div class="notif-item d-flex justify-content-between">
    <div class="notif-item-text">GAJ Magazine</div>
    <div class="notif-item-btn" *ngIf="notif?.gajMagazine == '0'">
      <button class="btn btn-primary" (click)="showNotif('gajMagazine')">
        <i class="far fa-bell"></i>
      </button>
    </div>
    <div class="notif-item-btn" *ngIf="notif?.gajMagazine == '1'">
      <button class="btn btn-gaj" (click)="hideNotif('gajMagazine')">
        <i class="far fa-bell-slash"></i>
      </button>
    </div>
  </div>
</div>

<div
  class="col-lg-8 col-md-10 col-sm-12 p-0 my-3 mx-auto"
  *ngIf="userinfo?.role != 'admin'"
>
  <div
    class="alert alert-info m-5 text-center"
    role="alert"
    *ngIf="allNotifs.length == 0"
  >
    Aucunce notification à afficher pour le moment!
  </div>

  <div class="list-group" *ngFor="let notif of allNotifs">
    <a
      routerLink="/gaj/publications/{{ notif?.pubid }}"
      class="list-group-item list-group-item-action"
      *ngIf="notif?.status == '0'"
    >
      <i class="fas fa-2x text-warning fa-exclamation-triangle mr-3"></i>
      <strong
        >Notification : une nouvelle publication de {{ notif?.pubcategory }} a
        été ajouter le
        {{ notif?.created_at | date : "dd MMM YYYY HH:mm" }}</strong
      >
    </a>
    <a
      routerLink="/gaj/publications/{{ notif?.pubid }}"
      class="list-group-item list-group-item-action"
      *ngIf="notif?.status == '1'"
    >
      <i class="fas fa-2x text-warning fa-exclamation-triangle mr-3"></i>
      <span
        >Notification : une nouvelle publication de {{ notif?.pubcategory }} a
        été ajouter le
        {{ notif?.created_at | date : "dd MMM YYYY HH:mm" }}</span
      >
    </a>
  </div>
</div>
