<div class="d-flex">
	<div style="width: 36px; height: 36px; border-radius: 50%; overflow: hidden;">
		<a data-toggle="modal" href="#showuserdemavatar{{user?.userid}}"><img style="width: 100%; height: 100%;" src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null"></a>
	</div>
	<div class="d-flex" style="line-height: 36px;">
		<div class="d-flex">
			<span class="mx-2 info-item">{{demande?.nom}} {{demande?.prenom}}</span>
			<span class="mx-2 info-item">{{demande?.email}}</span>
      <span class="mx-2 info-item">{{ demande?.created_at | date: 'YYYY-MM-dd' }}</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="demande?.tlf != null">{{demande?.tlf}}</span>
			<span class="mx-2 info-item-sm text-center" *ngIf="demande?.passportcin != null">{{demande?.passportcin}}</span>
			<span class="mx-2 info-item text-center" *ngIf="demande?.diplome != null">{{demande?.diplome}}</span>
			<!--<span class="mx-2 info-item">{{user?.residencecountry}}</span>-->
		</div>
		<div class="d-flex">
			<button class="btn mx-1 my-auto btn-sm btn-success" *ngIf="demande?.status == '0'" (click)="acceptInscription()">Accepter</button>
			<button class="btn mx-1 my-auto btn-sm btn-danger" *ngIf="demande?.status == '0'" (click)="rejectInscription()">Refuser</button>
			<a class="btn mx-1 my-auto btn-sm btn-primary" data-toggle="modal" href="#info{{demande?.id}}">Info</a>
		</div>
	</div>
</div>

<!-- Info Modal -->
<div class="modal fade" id="info{{demande?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
	<div class="modal-dialog modal-lg">
	  <div class="modal-content">
		<div class="modal-body px-0 pb-0 pt-4">
		  <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
			<i class="fas fa-times closemodalicon"></i>
		  </button>

		    <div class="row mx-3 justify-content-between d-flex">
			  <h3 class="card-title">Détails</h3>
			</div>
			<div class="card-body m-3">
				<div class="row border-bottom justify-content-between">
					<p><strong>Nom & Prènom</strong></p>
					<p>{{demande?.nom}} {{demande?.postnom}} {{demande?.prenom}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Nationalité</strong></p>
					<p>{{demande?.nationality}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Passport/Cin</strong></p>
					<p>{{demande?.passportcin}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Email</strong></p>
					<p>{{demande?.email}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Téléphone</strong></p>
					<p>{{demande?.tlf}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Diplome</strong></p>
					<p>{{demande?.diplome}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Niveau</strong></p>
					<p>{{demande?.niveau}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Filière</strong></p>
					<p>{{filiere?.name}}</p>
				</div>

				<div class="row border-bottom justify-content-between">
					<p><strong>Nombre des Mois</strong></p>
					<p>{{filiere?.years_number}} Mois</p>
				</div>

				<div class="">
					<p><strong>Description</strong></p>
					<p>{{filiere?.description}}</p>
				</div>
				<hr>
				<h3 class="card-title">
					Mes Fichiers
				</h3>
				<div class="list-group" *ngFor="let file of files">
					<div class="d-flex">
						<a target="_blank" href="{{filepath}}/InscriptFiles/{{file?.fichier}}" class="list-group-item list-group-item-action mr-1">
						<strong class="mr-3">{{file?.filename}}</strong> <small class="text-primary">({{file?.fichier}})</small>
						</a>
					</div>
				</div>
			</div>

		</div>
	  </div>
	</div>
  </div>
