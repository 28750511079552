import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../services/message.service';
import { AddService } from '../../../services/add.service';
@Component({
  selector: 'app-add-cycle-form',
  templateUrl: './add-cycle-form.component.html',
  styleUrls: ['./add-cycle-form.component.css']
})
export class AddCycleFormComponent implements OnInit {
  
  public cycleform = {
    email: '',
    cycle_id: '',
    name: '',
    description: ''
  };

  formSubmitted: boolean = false;
  currentusermail: any;

  constructor(
  	private http: HttpClient,
    private msg: MessageService,
    private add: AddService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();

  	this.cycleform.email = this.currentusermail;
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  AddCycle(e) {
  	this.formSubmitted = true;
  	e.preventDefault();

  	const chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
    const l = 16;
    let randomstring = '';
    for (let i=0; i<l; i++) {
      const rnum = Math.floor(Math.random() * chars.length);
      randomstring = randomstring + chars.substring(rnum, rnum+1);
    }

    this.cycleform.cycle_id = this.cycleform.cycle_id + randomstring;

    this.add.addCycle('', this.cycleform.email, this.cycleform.cycle_id, this.cycleform.name, this.cycleform.description).subscribe(res => {
    	console.log(res);

      
      setTimeout(function() {
        document.getElementById('confirmAddmessage').style.display = "block";
      }, 1000);

      this.msg.setMessage("new operation");

      setTimeout(function() {
        document.getElementById('confirmAddmessage').style.display = "none";
      }, 8000);

    	this.cycleform.name = '';
    	this.cycleform.description = '';
    	this.cycleform.cycle_id = '';
    });
  }

}
