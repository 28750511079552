export class ContactUs {
	id: string;
	name: string;
	email: string;
	message: string;
	status: number;
	created_at: string;

	constructor(id, name, email, message, status, created_at) {
		this.name = name;
		this.email = email;
		this.message = message;
		this.status = status;
		this.created_at = created_at;
	}
}