import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/User';
import { UserdataService } from '../../../services/userdata.service';
import { HttpClient } from '@angular/common/http';

import { AddService } from '../../../services/add.service';
import { ContactUs } from '../../../models/ContactUs';
import { SelectService } from '../../../services/select.service';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';
import { DeleteService } from '../../../services/delete.service';

import { Terms } from '../../../models/Terms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-terms-list',
  templateUrl: './terms-list.component.html',
  styleUrls: ['./terms-list.component.css']
})
export class TermsListComponent implements OnInit {

  user: User;
  currentusermail: any;

  filepath = environment.filepath;

  termsfile: string = '';
  selectedFile: File = null;

  terms: Terms[] = [];
  firsttermsfile: Terms;

  constructor(
  	private userdataservice: UserdataService,
    private addservice: AddService,
    private selectservice: SelectService,
    private updateservice: UpdateService,
    private msg: MessageService,
    private http: HttpClient,
    private deleteservice: DeleteService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();

  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  	});

    this.selectservice.getAll('terms').subscribe(data => {
      this.terms = data;
      this.firsttermsfile = this.terms[0];
      console.log(this.terms);
    });

    this.msg.getMessage().subscribe(r => {
      this.selectservice.getAll('terms').subscribe(data => {
        this.terms = data;
        this.firsttermsfile = this.terms[0];
        console.log(this.terms);
      });
    });
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  /*addTerms(e) {
    e.preventDefault();
    this.addservice.addTerms('',this.termsfile).subscribe(data => {
      console.log(data);

      setTimeout(function() {
        document.getElementById('confirmAddTerms').style.display = "block";
      }, 1000);


      setTimeout(function() {
        document.getElementById('confirmAddTerms').style.display = "none";
      }, 8000);

      this.termsfile = null;
    });
  }
*/


  onFileSelected(event) {
    this.selectedFile = <File>event.target.files[0];

    const filename = this.selectedFile.name;
    if(filename.split('.').pop() === 'pdf') {
      const fd = new FormData();

      fd.append('termsfile', this.selectedFile);



      this.http.post(environment.api+'/addTermsFile', fd).subscribe(res => {
        this.msg.setMessage('new operation');

        setTimeout(function() {
          document.getElementById('confirmAddTerms').style.display = 'block';
        }, 1000);

        setTimeout(function() {
          document.getElementById('confirmAddTerms').style.display = 'none';
        }, 8000);

        this.termsfile = null;
      });
    } else {
      setTimeout(function() {
        document.getElementById('wrongTermsextention').style.display = 'block';
      }, 1000);

      setTimeout(function() {
        document.getElementById('wrongTermsextention').style.display = 'none';
      }, 8000);
    }
  }

  delete(id) {
    this.deleteservice.deleteData('terms', id).subscribe(res => {
      console.log(res);
      this.msg.setMessage('deleted data');
    });
  }

}
