<div *ngIf="user?.role == 'admin'">
	
	<nav>
	  <div class="nav nav-tabs" id="nav-tab" role="tablist">
	    <a class="nav-link col-md-4 col-sm-12 text-center text-primary active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"><i class="far fa-id-card"></i> Contact</a>
	    <a class="nav-link col-md-4 col-sm-12 text-center text-success" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"><i class="fas fa-question"></i> Questions </a>
	    <a class="nav-link col-md-4 col-sm-12 text-center text-danger" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false"><i class="fas fa-file-contract"></i> termes et conditions </a>
	  </div>
	</nav>
	<div class="tab-content" id="nav-tabContent">
	  <div class="tab-pane bg-white fade py-3 show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

	  	<!--<div class="alert alert-info text-center col-sm-4 mx-auto alert-dismissible fade show" role="alert" *ngIf="allencours.length == 0">
		  Aucune demande à affichée!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>-->

	  	<div>
	  		<app-contact-list></app-contact-list>
	  	</div>

	  </div>
	  <div class="tab-pane bg-white fade py-3" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

	  	<div>
	  		<app-faqs-list></app-faqs-list>
	  	</div>

	  </div>
	  <div class="tab-pane bg-white fade py-3" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

	  	<div>
	  		<app-terms-list></app-terms-list>
	  	</div>

	  </div>
	</div>

</div>