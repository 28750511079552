import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Actuality } from 'src/app/models/Actuality';
import { SelectService } from 'src/app/services/select.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-actuality-page',
  templateUrl: './actuality-page.component.html',
  styleUrls: ['./actuality-page.component.css']
})
export class ActualityPageComponent implements OnInit {

  actuality: Actuality;

  filepath = environment.filepath;

  constructor(
    private route: ActivatedRoute,
    private select: SelectService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(data => {
      this.select.getOneActuality(data.id).subscribe(res => {
        this.actuality = res[0];
      });
    });
  }

}
