<div class="top-img-section">
  <div class="bienvenue-top">
    <p>Page <strong>Publications</strong></p>
  </div>
</div>

<div class="relative">
  <div class="filterbtns justify-content-center d-flex px-3">
    <button
      class="btn btn-sm btn-secondary my-auto mr-2"
      (click)="filter('all')"
    >
      Tout
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2"
      (click)="filter('actuality')"
    >
      Actualité
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2"
      (click)="filter('officiel')"
    >
      Officiel
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajconsulting')"
    >
      GAJ Consulting
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajTraining')"
    >
      GAJ Training
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajScholarship')"
    >
      GAJ Scholarship
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajFablab')"
    >
      GAJ Fablab
    </button>
    <button
      class="btn btn-sm btn-secondary my-auto mr-2 bigbtn"
      (click)="filter('gajMagazine')"
    >
      GAJ Magazine
    </button>
  </div>
</div>

<div class="publications-section mx-auto container-fluid">
  <div class="p-1 pub-item" *ngFor="let publication of publications">
    <div class="">
      <app-pub-item [publication]="publication"></app-pub-item>
    </div>
  </div>
</div>
