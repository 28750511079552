import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../../services/message.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-add-file-form',
  templateUrl: './add-file-form.component.html',
  styleUrls: ['./add-file-form.component.css']
})
export class AddFileFormComponent implements OnInit {

  selectedFile: File = null;

  public fileform = {
    category: "",
    email: null,
    realname: null,
    differentname: null,
  };

  formSubmitted: boolean = false;
  currentusermail: any;

  constructor(
  	private http: HttpClient,
    private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();
  	this.fileform.email = this.currentusermail;
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  onFileSelected(event) {
  	this.selectedFile = <File>event.target.files[0];
  	document.getElementById('filename').innerHTML = this.selectedFile.name;
  }

  AddDocument(e) {
  	const fd = new FormData();

  	fd.append('file', this.selectedFile);
  	fd.append('email', this.fileform.email);
  	fd.append('category', this.fileform.category);

  	this.http.post(environment.api+'/addDocument', fd).subscribe(res => {
  		console.log(res);

  		this.msg.setMessage('new operation');
  		document.getElementById('addfilesuccess').style.display = "block";

	    setTimeout(function() {
	      document.getElementById('addfilesuccess').style.display = "none";
	    }, 8000);

	    this.fileform.category = '';
	    this.fileform.realname = null;
	    document.getElementById('filename').innerHTML = '';
  	})
  }

}
