import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { SelectService } from '../../services/select.service';
import { MessageService } from '../../services/message.service';
import { Document } from '../../models/Document';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-student-files',
  templateUrl: './student-files.component.html',
  styleUrls: ['./student-files.component.css']
})
export class StudentFilesComponent implements OnInit {

  currentusermail: any;
  documents: Document[] = [];
  passport: Document[] = [];
  diplome: Document[] = [];
  bac: Document[] = [];
  relevees: Document[] = [];

  selectedFile: File = null;

  public fileform = {
    category: null,
    email: null,
    realname: null,
    differentname: null,
  };

  formSubmitted: boolean = false;
  i: any = 0;
  j: any = 0;
  k: any = 0;

  constructor(
  	private http: HttpClient,
  	private select: SelectService,
  	private msg: MessageService
  	) { }

  ngOnInit(): void {//getDocuments
  	this.currentusermail = this.getMail();
  	this.fileform.email = this.currentusermail;

  	this.select.getUserDocuments(this.currentusermail).subscribe(data => {
  		this.documents = data;

  		for(this.i = 0; this.i < this.documents.length; this.i++) {
  			if(this.documents[this.i].category == 'passport') {
  				this.passport[0] = this.documents[this.i];
  			}
  			if(this.documents[this.i].category == 'diplome') {
  				this.diplome[this.j] = this.documents[this.i];
  				this.j++;
  			}
  			if(this.documents[this.i].category == 'attestbac') {
  				this.bac[0] = this.documents[this.i];
  			}
  			if(this.documents[this.i].category == 'relevenotes') {
  				this.relevees[this.k] = this.documents[this.i];
  				this.k++;
  			}
  		}
  	});

  	this.msg.getMessage().subscribe(res => {
  		this.documents = [];
  		this.select.getUserDocuments(this.currentusermail).subscribe(data => {
  			this.documents = data;

  			this.passport = [];
  			this.diplome = [];
  			this.bac = [];
  			this.relevees =[];
  			this.j = 0;
  			this.k = 0;

  			for(this.i = 0; this.i < this.documents.length; this.i++) {
  			if(this.documents[this.i].category == 'passport') {
  				this.passport[0] = this.documents[this.i];
  			}
  			if(this.documents[this.i].category == 'diplome') {
  				this.diplome[this.j] = this.documents[this.i];
  				this.j++;
  			}
  			if(this.documents[this.i].category == 'attestbac') {
  				this.bac[0] = this.documents[this.i];
  			}
  			if(this.documents[this.i].category == 'relevenotes') {
  				this.relevees[this.k] = this.documents[this.i];
  				this.k++;
  			}
  		}
  		});
  	});
  }
  getMail() {
    return localStorage.getItem('currentusermail');
  }

  onFileSelected(event, category) {
  	this.selectedFile = <File>event.target.files[0];
  	this.fileform.category = category;

    const filename = this.selectedFile.name;

    const fd = new FormData();

      fd.append('file', this.selectedFile);
      fd.append('email', this.fileform.email);
      fd.append('category', category);



      this.http.post(environment.api+'/addDocument', fd).subscribe(res => {
        this.msg.setMessage('new operation');

        setTimeout(function() {
          document.getElementById('fileadded').style.display = 'block';
        }, 1000);

        setTimeout(function() {
          document.getElementById('fileadded').style.display = 'none';
        }, 8000);

        this.fileform.realname = null;
      });

    /*if(filename.split('.').pop() === 'pdf') {
      const fd = new FormData();

      fd.append('file', this.selectedFile);
      fd.append('email', this.fileform.email);
      fd.append('category', category);



      this.http.post(environment.api+'/addDocument', fd).subscribe(res => {
        this.msg.setMessage('new operation');

        setTimeout(function() {
          document.getElementById('fileadded').style.display = 'block';
        }, 1000);

        setTimeout(function() {
          document.getElementById('fileadded').style.display = 'none';
        }, 8000);

        this.fileform.realname = null;
      });
    } else {
      setTimeout(function() {
        document.getElementById('wrongfileextention').style.display = 'block';
      }, 1000);

      setTimeout(function() {
        document.getElementById('wrongfileextention').style.display = 'none';
      }, 8000);
    }*/
  }

}
