<div class="container-fluid p-0">
	<div class="row col-12 mx-0 my-2 page-sub-title">Profile</div>
	<div class="row col-md-12 p-0 mx-0">
		<div class="col-lg-4 p-1">
			<div class="card left-box p-2 shadow">
				<div class="profile-avatar">
					<div class="avatar">
						<img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
						<img class="avatarimg" src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null" data-toggle="modal" data-target="#showavatarmodal">

						<a class="btn btn-gaj text-white" data-toggle="collapse" href="#updateprofileAvatar" >
							<i id="cameraupload" class="fas fa-camera" style="line-height: 35px;"></i>
							<div id="camerauploaded" class="spinner-border text-success" style="display: none; width: 22px; height: 22px; margin: 5px;" role="status">
							  <span class="sr-only">Loading...</span>
							</div>

						</a>

					</div>
					<div class="collapse p-0" id="updateprofileAvatar">
					  <div class="card p-0 border-0 card-body">
					    <input class="col-12" id="uploadavatar" type="file" (change)="onFileSelected($event)">
					    <button type="button" class="btn btn-sm btn-block btn-gaj my-2" (click)="onUpload()">Changer</button>
					  </div>
					</div>
				</div>
				<div class="filiere-section" *ngIf="user?.usertype == 'etudiant' && user?.role =='user'">

					<div class="col-12" *ngFor="let diplome of diplomes">
						<div class="card mb-2">
						  <div class="card-body py-1 px-2">
						    <h5 class="card-title d-flex justify-content-between">
						    	<div class="mr-auto">{{diplome?.diplometitle}}</div>
						    	<div class="ml-auto">
						    		<span class="badge badge-primary" *ngIf="diplome?.status == '0'">en cours</span>
						    		<span class="badge badge-success" *ngIf="diplome?.status == '1'">obtenu</span>
						    	</div>
						    </h5>
						    <h6 class="card-subtitle mb-2 text-muted">{{diplome?.start}} - <span *ngIf="diplome?.status == '1'">{{diplome?.end}}</span> <span *ngIf="diplome?.status == '0' ">Présent</span></h6>
						    <p class="card-text">{{diplome?.filiere}}</p>
						    <button (click)="DeleteDiplome(diplome.id)" style="bottom: 8px; right: 8px; position: absolute;" class="btn btn-sm btn-danger"><i class="fas fa-times"></i></button>
						  </div>
						</div>
					</div>

				  <form (submit)="addDiploma($event)" autocomplete="off" class="col-12">
					<div class="form-group">
					    <label for="diplometitle">Diplôme</label>
					    <input type="text" class="form-control form-control-sm" id="diplometitle" name="diplometitle" [(ngModel)]="diplomform.diplometitle">
					</div>
					<div class="form-group">
					    <label for="start">Année de début</label>
					    <input type="date" class="form-control form-control-sm" id="start" name="start" [(ngModel)]="diplomform.start">
					</div>
					<div class="form-group">
					    <label for="end">Année de fin</label>
					    <input type="date" class="form-control form-control-sm" id="end" name="end" [(ngModel)]="diplomform.end">
					</div>
					<div class="form-group">
					    <label for="filiere">Filière</label>
					    <select class="form-control form-control-sm" id="filiere" name="filiere" [(ngModel)]="diplomform.filiere">
							<option value="null">Séléctionner votre Filière</option>
					      <option  *ngFor="let filiere of allFilieres" value="{{filiere?.name}}">{{filiere?.name}}</option>
					    </select>
					</div>
					<div class="form-group">
					    <label for="status">Status</label>
					    <select class="form-control form-control-sm" id="status" name="status" [(ngModel)]="diplomform.status">
					      <option value="1">Obtenu</option>
					      <option value="0">En cours</option>
					    </select>
					</div>
					<button type="submit" class="btn btn-block btn-gaj btn-sm">Enregistrer</button>
				  </form>
				</div>
			</div>
		</div>
		<div class="col-lg-8 p-1">
			<div class="card p-2 shadow">
				<form (submit)="editprofile()" autocomplete="off">
					<h5 class="mt-1 mb-3">Informations</h5>
					<div id="profileupdatesuccess" class="alert alert-success py-1" role="alert" style="display: none;">
					  Mise à jour du profil réussie!
					</div>
					<div class="row">
						<div class="form-group col-sm-6">
						    <label for="email">Email</label>
						    <input type="email" class="form-control form-control-sm" id="email" name="email" [(ngModel)]="email" disabled>
						</div>
						<div class="form-group col-sm-6">
						    <label for="datenaissance">Date de Naissance</label>
						    <input type="date" class="form-control form-control-sm" id="datenaissance" name="naissance" [(ngModel)]="naissance">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-sm-6">
						    <label for="fname">Prénom</label>
						    <input type="text" class="form-control form-control-sm" id="fname" name="fname" [(ngModel)]="fname">
						</div>
						<div class="form-group col-sm-6">
						    <label for="lname">Nom</label>
						    <input type="text" class="form-control form-control-sm" id="lname" name="lname" [(ngModel)]="lname">
						</div>
					</div>
					<div class="row">
						<div class="form-group col-sm-6">
						    <label for="nationality">Nationalité</label>
						    <select class="form-control form-control-sm" id="nationality" name="nationality" [(ngModel)]="nationality">
								<option value="null">Sélectionner votre Nationalité</option>
						      <option value="{{nat?.nationality}}" *ngFor="let nat of nationalities">{{nat?.nationality}}</option>

						    </select>
						</div>
						<div class="form-group col-sm-6">
						    <label for="residence">Pays de résidence</label>
						    <select class="form-control form-control-sm" id="residence" name="residencecountry" [(ngModel)]="residencecountry">
						      <option value="{{pay?.pay}}" *ngFor="let pay of nationalities">{{pay?.pay}}</option>
						    </select>
						</div>
					</div>
					<div class="row">
						<div class="form-group col-sm-6">
						    <label for="tlf">Téléphone</label>
						    <input type="text" class="form-control form-control-sm" id="tlf" name="phone" [(ngModel)]="phone">
						</div>
						<div class="form-group col-sm-6">
						    <label for="passport">Passport</label>
						    <input type="text" class="form-control form-control-sm" id="passport" name="passport" [(ngModel)]="passport">
						</div>
					</div>

					<button class="btn btn-sm btn-block btn-gaj">Enregistrer les Modification</button>

				</form>
			</div>
		</div>
	</div>
</div>


<!-- Modal -->
<div class="modal fade" id="showavatarmodal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog mt-5">
    <div class="modal-content transparent-modal">
      <div class="modal-body p-0">
      	<button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
       	<img class="avatarimginmodal" src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
       	<img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
      </div>
    </div>
  </div>
</div>
