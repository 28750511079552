<button class="btn btn-sm btn-gaj publierpost text-white" data-toggle="modal" data-target="#addaccueilpost" *ngIf="currentuser?.role == 'admin'"><i class="fas fa-plus"></i> Publier </button>

<div class="col-lg-8 col-md-10 col-sm-12 mx-auto bg-white shadow px-3 pb-5 pt-3 blog-container">
	<div class="row mb-3 col-12 justify-content-between">
		<h5 class="card-title">
			Accueil
		</h5>
		<div>
			<button class="btn btn-light"><i class="fas fa-handshake text-gaj"></i></button>
		</div>
	</div>

	<p class="text-gaj">
		Nous vous assurons un service d'accueil lors de votre arrivée en Tunisie
	</p>



	<div class="accordion" id="accordionExample">
	  <div class="card mb-3">
	    <div class="card-header p-0" id="headingOne">
	      <h2 class="mb-0 bg-gaj">
	        <button class="btn btn-link text-white btn-block text-center m-0" type="button" data-toggle="collapse" data-target="#demandeAccueil" aria-expanded="true" aria-controls="demandeAccueil">
	          Demande d'Accueil
	        </button>
	      </h2>
	    </div>

	    <div id="demandeAccueil" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
	      <div class="card-body p-1">
	        <small id="emailHelp" class="form-text text-center text-gaj text-muted">Frais: Le service est gratuit pour nos inscrits - Tunis: 20$ - Autres ville: 50$</small>


	        <form (submit)="AddDemandeAcceuil($event)" autocomplete="off">
		        <div class="form-group col-sm-12">
				    <label for="name">Nom <strong class="required-star">*</strong></label>
				    <input type="text" class="form-control form-control-sm" name="name" id="name" [(ngModel)]="demandeAcceuil.name" required>
				</div>

				<div class="form-group col-sm-12">
				    <label for="prenom">Prénom <strong class="required-star">*</strong></label>
				    <input type="text" class="form-control form-control-sm" name="prenom" id="prenom" [(ngModel)]="demandeAcceuil.prenom" required><!-- [(ngModel)]="cycleform.name" -->
				</div>

				<div class="form-group col-sm-12">
				    <label for="payorigine">Pays d'origine <strong class="required-star">*</strong></label>
				    <input type="text" class="form-control form-control-sm" name="payorigine" id="payorigine" [(ngModel)]="demandeAcceuil.payorigine" required><!-- [(ngModel)]="cycleform.name" -->
				</div>

				<div class="form-group col-sm-12">
				    <label for="date">Date d'arrivée <strong class="required-star">*</strong></label>
				    <input type="date" class="form-control form-control-sm" name="date" id="date" [(ngModel)]="demandeAcceuil.date" required><!-- [(ngModel)]="cycleform.name" -->
				</div>

				<div class="form-group col-sm-12">
				    <label for="time">Temps d'arrivée <strong class="required-star">*</strong></label>
				    <input type="time" class="form-control form-control-sm" name="time" id="time" [(ngModel)]="demandeAcceuil.time" required><!-- [(ngModel)]="cycleform.name" -->
				</div>

				<div class="form-group col-sm-12">
				    <label for="destination">Ville de destination <strong class="required-star">*</strong></label>
				    <input type="text" class="form-control form-control-sm" name="destination" id="destination" [(ngModel)]="demandeAcceuil.destination" required><!-- [(ngModel)]="cycleform.name" -->
				</div>

				<div class="form-group col-sm-12">
					<label for="photo">Joindre votre Photo <strong class="required-star">*</strong></label>
				    <div class="custom-file">
					    <input type="file" class="custom-file-input" name="customFile" id="customFile" (change)="onCustomFileSelected($event)" [(ngModel)]="demandeAcceuil.customFile">
					    <label class="custom-file-label form-control-sm" for="customFile">Choisir un fichier</label>
					</div>
				</div>

				<div class="row mx-0 mb-3 col-sm-12">
					<button type="submit" class="ml-auto btn btn-sm btn-block btn-gaj">
						Envoyer
					</button>
				</div>
			</form>


	      </div>
	    </div>
	  </div>
	</div>






	<div *ngFor="let post of posts">
		<app-presentationblog-item [post]="post"></app-presentationblog-item>
	</div>
</div>



<!-- Modal -->
<div class="modal fade" id="addaccueilpost" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body px-0 pb-0 pt-4">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <div class="currentuserinfo px-3">
        	<div class="avatar">
        		<img src="assets/img/avatar.png" *ngIf="currentuser?.avatar == null">
				<img class="avatarimg" src="{{filepath}}/profileAvatars/{{currentuser?.avatar}}" *ngIf="currentuser?.avatar != null">
        	</div>
        	<div class="name">
        		{{currentuser?.lname}} {{currentuser?.fname}}
        	</div>
        </div>

        <form (submit)="AddBlogPost($event)" autocomplete="off">
    		<div class="form-group mx-0 my-2">
			    <textarea class="form-control radius-0 border-0" id="exampleFormControlTextarea1" rows="4" placeholder="Que voulez-vouz dire {{currentuser?.fname}} ?" name="description" [(ngModel)]="bpform.description"></textarea>
			</div>

			<div class="px-3" style="line-height: 60px; justify-content: space-between; display: flex; flex-direction: row;">
				<div>Ajouter à votre publication</div>
				<div>
					<label for="bpimage" class="btn ml-2 btn-light text-gaj"><i class="fas fa-image"></i></label>
					<label for="bpvideo" class="btn ml-2 btn-light text-gaj"><i class="fas fa-video"></i></label>
				</div>
				<input type="file" name="bpimage" id="bpimage" accept="image/*" name="image" (change)="onImageSelected($event)" [(ngModel)]="bpform.image"><!-- bp === blog post -->
				<input type="file" name="bpvideo" id="bpvideo" accept="video/mp4,video/x-m4v,video/*,video/mkv" name="video" (change)="onVideoSelected($event)" [(ngModel)]="bpform.video">
			</div>
			<div class="row mx-0 px-0 col-sm-12">
				<button type="submit" class="mx-3 mb-3 btn btn-sm btn-block btn-gaj">
					Ajouter
				</button>
			</div>

        </form>
      </div>
    </div>
  </div>
</div>


<div id="confirmAccueilAdd" class="shadow">
	<i class="fas fa-check-circle"></i> Votre demande d'accueil bien reçu!
</div>
