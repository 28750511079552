import { Component, OnInit } from '@angular/core';
import { User } from '../../../models/User';
import { UserdataService } from '../../../services/userdata.service';
import { AddService } from '../../../services/add.service';
import { SelectService } from '../../../services/select.service';
import { UpdateService } from '../../../services/update.service';
import { MessageService } from '../../../services/message.service';
import { Faq } from '../../../models/Faq';
import { DeleteService } from 'src/app/services/delete.service';

@Component({
  selector: 'app-faqs-list',
  templateUrl: './faqs-list.component.html',
  styleUrls: ['./faqs-list.component.css']
})
export class FaqsListComponent implements OnInit {

  user: User;
  currentusermail: any;

  faqs: Faq[] = [];

  question: string = '';
  response: string = '';

  constructor(
  	private userdataservice: UserdataService,
    private addservice: AddService,
    private selectservice: SelectService,
    private updateservice: UpdateService,
    private msg: MessageService,
    private deleteservice: DeleteService,
  	) { }

  ngOnInit(): void {
  	this.currentusermail = this.getMail();

  	this.userdataservice.getUserData(this.currentusermail).subscribe((data) => {
  		this.user = data[0];
  	});

    this.selectservice.getAll('faqs').subscribe(res => {
      this.faqs = res;
    });

    this.msg.getMessage().subscribe(data => {
      this.selectservice.getAll('faqs').subscribe(res => {
        this.faqs = res;
      });
    })
  }

  getMail() {
    return localStorage.getItem('currentusermail');
  }

  addFaq(e) {
    e.preventDefault();
    this.addservice.addfaq('',this.question, this.response, 0).subscribe(data => {
      console.log(data);

      setTimeout(function() {
        document.getElementById('confirmAddFaq').style.display = "block";
      }, 1000);

      this.msg.setMessage('an other faq');

      setTimeout(function() {
        document.getElementById('confirmAddFaq').style.display = "none";
      }, 8000);

      this.question = '';
      this.response = '';
    });
  }

  DeleteFAQ(id) {

    this.deleteservice.deleteData('faqs', id).subscribe(res => {
      console.log(res);
      this.msg.setMessage("new operation");
    });
  }

}
