<div class="row col-12 py-1 px-2 m-0 border bg-white filiere-item justify-content-between" *ngIf="user?.role == 'admin'">
	<div>
		{{filiere?.name}}
	</div>
	<div>
		<a class="btn btn-sm btn-primary mr-1" data-toggle="modal" href="#edit{{filiere?.id}}">
			<i class="fas fa-edit"></i>
		</a>
		<a class="btn btn-sm btn-danger mr-1" (click)="delete()" data-toggle="modal" href="#delete{{filiere?.id}}">
			<i class="fas fa-trash-alt"></i>
		</a>
	</div>
</div>

<div class="row col-12 py-1 px-2 m-0 border bg-white filiere-item justify-content-between" *ngIf="user?.role != 'admin'">
	<div>
		{{filiere?.name}}
	</div>
	<div>
		<a class="btn btn-sm btn-dark mr-1" data-toggle="modal" href="#savoir{{filiere?.id}}">
			En savoir plus
		</a>
		<a class="btn btn-sm btn-gaj" data-toggle="modal" href="#inscrire{{filiere?.id}}" *ngIf="verif == false">
			S'inscrire
		</a>
		<input type="button" class="btn btn-sm btn-success" data-toggle="modal" disabled value="Inscrit déjà" *ngIf="verif == true">
	</div>
</div>


<!-- Modal -->
<div class="modal fade" id="savoir{{filiere?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
      	<h5 class="modal-title mb-3" id="exampleModalLabel">En Savoir Plus</h5>
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <strong> Filière: </strong> {{filiere?.name}}.<br>
        <strong>Périod: </strong> {{filiere?.years_number}} mois.<br>
        <span *ngIf="filiere?.description != null"><strong>Description: </strong> <br>
        	{{filiere?.description}}.
        </span>
      </div>
    </div>
  </div>
</div>

<!-- Modal -->
<div class="modal fade" id="inscrire{{filiere?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
        <h5 class="modal-title mb-3" id="exampleModalLabel">S'inscrire à {{filiere?.name}}</h5>
      	<button type="button" #closebuttonI class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <form (submit)="Inscription($event)" autocomplete="off">
	        <div class="row m-0">
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Niveau <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="niveau" [(ngModel)]="inscritform.niveau" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Nom <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="lname" [(ngModel)]="inscritform.nom" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Post-Nom</label>
	              <input type="text" class="form-control form-control-sm" name="postnom" [(ngModel)]="inscritform.postnom">
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Prénom <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="fname" [(ngModel)]="inscritform.prenom" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Nationalité <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="nationality" [(ngModel)]="inscritform.nationality" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">N° CIN/Passport <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="passportcin" [(ngModel)]="inscritform.passportcin" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Adress Email <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" disabled name="email" [(ngModel)]="inscritform.email" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">N° Téléphone <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="phone" [(ngModel)]="inscritform.tlf" required>
	          </div>
	          <div class="form-group my-0 col-sm-12">
	              <label for="name">Diplome Souhaité <strong class="required-star">*</strong></label>
	              <input type="text" class="form-control form-control-sm" name="diplome" [(ngModel)]="inscritform.diplome" required>
	          </div>
	        </div>
	        <div class="row mx-0 col-sm-12 my-3">
	          <button type="submit" class="ml-auto btn btn-sm col-sm-12 btn-gaj">
	            Envoyer
	          </button>
	        </div>
	    </form>
      </div>
    </div>
  </div>
</div>


<!-- Modal -->
<div class="modal fade" id="edit{{filiere?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-body">
      	<h5 class="modal-title mb-3" id="exampleModalLabel">Modifier {{filiere?.name}}</h5>
      	<button type="button" #closebuttonII class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <form>
            <div class="row m-0">
              <div class="form-group col-sm-8">
                  <label for="name">Filière <strong class="required-star">*</strong></label>
                  <input type="text" class="form-control form-control-sm" name="name" [(ngModel)]="filiereupdate.name" required>
              </div>
              <div class="form-group col-sm-4">
                  <label for="yearsnumber">Période(Mois) <strong class="required-star">*</strong></label>
                  <input type="number" class="form-control form-control-sm" name="yearsnumber" [(ngModel)]="filiereupdate.years_number" required>
              </div>
            </div>
            <div class="row m-0">
              <div class="form-group col-sm-12">
                  <label for="description">Description</label>
                  <textarea class="form-control form-control-sm" rows="2" name="description" [(ngModel)]="filiereupdate.description"></textarea>
              </div>
            </div>
        	<button class="btn btn-sm btn-gaj btn-block" (click)="update()">Modifier</button>
        </form>
      </div>
    </div>
  </div>
</div>
