<div class="mb-0 p-0">
	<div class="d-flex">
		<div style="min-width: 50px; height: 36px; border-radius: 50%; overflow: hidden;">
			
		</div>
		<div class="d-flex" style="line-height: 36px;">
			<div class="d-flex">
				<span class="mx-2 info-item">Nom et Prénom</span>
				<span class="mx-2 info-item">Email</span>
				<span class="mx-2 info-item-sm text-center">Téléphone</span>
				<span class="mx-2 info-item-sm text-center">Passport</span>
				<span class="mx-2 info-item-sm text-center">Nationalité</span>
				<!--<span class="mx-2 info-item">{{user?.residencecountry}}</span>-->
			</div>
			<div class="d-flex fw-6">
				Actions
			</div>
		</div>
	</div>
</div>

<div class="usersbox px-3 border-top">
	<div class="userrowitem shadow-sm" *ngFor="let user of users">
		<app-student-item [user]="user"></app-student-item>
	</div>
</div>
