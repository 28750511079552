<div class="p-3">
  <form (submit)="AddPublication($event)" autocomplete="off">
    <div class="row">
      <div class="form-group col-sm-12">
        <h5>Publier une annonce</h5>
      </div>
    </div>

    <div class="row col-12" id="addsuccess">
      <div
        class="alert alert-success col-sm-8 text-center mx-auto p-1 alert-dismissible fade show"
        role="alert"
      >
        Publication a été ajoutée avec succée!
        <button
          type="button"
          class="close m-0 py-1"
          data-dismiss="alert"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-4">
        <label for="category" class="relative"
          >Catégorie <i class="fas absolute fa-asterisk mt-0 pt-0"></i
        ></label>
        <select
          class="form-control form-control-sm col-12"
          id="category"
          name="category"
          [(ngModel)]="pubform.category"
          required
          #category="ngModel"
        >
          <option value="" selected>Sélectionner Categorie</option>
          <option value="actuality">Actualité</option>
          <option value="officiel">Officiel</option>
          <option value="gajconsulting">GAJ Consulting</option>
          <option value="gajTraining">GAJ Training</option>
          <option value="gajScholarship">GAJ Scholarship</option>
          <option value="gajFablab">GAJ Fablab</option>
          <option value="gajMagazine">GAJ Magazine</option>
        </select>

        <div
          *ngIf="category.invalid && (category.dirty || category.touched)"
          class="alert alert-danger p-0 mt-2"
        >
          <div *ngIf="category.errors.required">Obligatoire!</div>
        </div>
      </div>
      <div class="form-group col-sm-4">
        <label for="image">Image</label>
        <label class="btn btn-sm btn-block text-gaj btn-light" for="image"
          ><i class="far fa-image"></i
        ></label>
        <input
          type="file"
          class="form-control-file"
          id="image"
          name="image"
          (change)="onImageSelected($event)"
          [(ngModel)]="pubform.image"
        />
        <p id="imgname"></p>
      </div>
      <div class="form-group col-sm-4">
        <label for="videoforpub">Video</label>
        <label class="btn btn-sm btn-block text-gaj btn-light" for="videoforpub"
          ><i class="fas fa-film"></i
        ></label>
        <input
          type="file"
          class="form-control-file"
          id="videoforpub"
          name="video"
          (change)="onVideoSelected($event)"
          [(ngModel)]="pubform.video"
        />
        <p id="videoname"></p>
      </div>
    </div>

    <div class="row">
      <div class="form-group col-sm-12">
        <label for="description relative"
          >Description <i class="fas absolute fa-asterisk mt-0 pt-0"></i
        ></label>
        <textarea
          class="form-control"
          rows="3"
          id="description"
          name="description"
          [(ngModel)]="pubform.description"
          required
          #description="ngModel"
        ></textarea>
        <div
          *ngIf="
            description.invalid && (description.dirty || description.touched)
          "
          class="alert alert-danger p-0 mt-2"
        >
          <div *ngIf="description.errors.required">Obligatoire!</div>
        </div>
      </div>
    </div>
    <div class="row mx-0 px-0 col-sm-12">
      <button type="submit" class="ml-auto btn btn-sm col-sm-4 btn-gaj">
        Ajouter
      </button>
    </div>
  </form>
</div>
