import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/models/User';
import { DeleteService } from 'src/app/services/delete.service';
import { MessageService } from 'src/app/services/message.service';
import { UserdataService } from 'src/app/services/userdata.service';
import { environment } from 'src/environments/environment';
import { PublicationFile } from '../../../../models/PublicationFile';

@Component({
  selector: 'app-publication-files',
  templateUrl: './publication-files.component.html',
  styleUrls: ['./publication-files.component.css']
})
export class PublicationFilesComponent implements OnInit {

  @Input() file: PublicationFile;

  currentuser: User;
  currentemail: any;
  role: any;

  filepath = environment.filepath;

  constructor(
    private userdataservice: UserdataService,
    private deleteservice: DeleteService,
    private msg: MessageService,
  ) { }

  ngOnInit(): void {
    this.currentemail = localStorage.getItem('currentusermail');

    this.userdataservice.getUserData(this.currentemail).subscribe((data) => {
  		this.currentuser = data[0];
  		this.role = this.currentuser.role;
  	});
  }

  DeletePicOrVideo(id) {
    this.deleteservice.deletepicorvideo(id).subscribe(res => {
      console.log(res);
      this.msg.setMessage("new operation");
    })
  }

}
