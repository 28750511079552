<div *ngIf="user?.role == 'admin'">
	
	<nav>
	  <div class="nav nav-tabs" id="nav-tab" role="tablist">
	    <a class="nav-link col-md-4 col-sm-12 text-center text-primary active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true"><i class="fas fa-spinner"></i> Demandes en cours</a>
	    <a class="nav-link col-md-4 col-sm-12 text-center text-success" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false"><i class="fas fa-vote-yea"></i> Demandes approuvées</a>
	    <a class="nav-link col-md-4 col-sm-12 text-center text-danger" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false"><i class="fas fa-window-close"></i> Demandes refusées</a>
	  </div>
	</nav>
	<div class="tab-content" id="nav-tabContent">
	  <div class="tab-pane bg-white fade py-3 show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">

	  	<div class="alert alert-info text-center col-sm-4 mx-auto alert-dismissible fade show" role="alert" *ngIf="allencours.length == 0">
		  Aucune demande à affichée!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>

	  	<div class="demandebox px-3" *ngIf="allencours.length != 0">
			<div class="demanderowitem shadow-sm" *ngFor="let demande of allencours">
				<app-admin-demande-item [demande]="demande"></app-admin-demande-item>
			</div>
		</div>
	  </div>
	  <div class="tab-pane bg-white fade py-3" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">

	  	<div class="alert alert-success text-center col-sm-4 mx-auto alert-dismissible fade show" role="alert" *ngIf="allapproved.length == 0">
		  Aucune demande à affichée!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>

	  	<div class="demandebox px-3" *ngIf="allapproved.length != 0">
			<div class="demanderowitem shadow-sm" *ngFor="let demande of allapproved">
				<app-admin-demande-item [demande]="demande"></app-admin-demande-item>
			</div>
		</div>
	  </div>
	  <div class="tab-pane bg-white fade py-3" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">

	  	<div class="alert alert-danger text-center col-sm-4 mx-auto alert-dismissible fade show" role="alert" *ngIf="allrefused.length == 0">
		  Aucune demande à affichée!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>

	  	<div class="demandebox px-3" *ngIf="allrefused.length != 0">
			<div class="demanderowitem shadow-sm" *ngFor="let demande of allrefused">
				<app-admin-demande-item [demande]="demande"></app-admin-demande-item>
			</div>
		</div>
	  </div>
	</div>

</div>


<div class="p-3" *ngIf="user?.role == 'user'">
	<div>
		<h5 class="card-title text-success">Demandes d'inscription approuvées</h5>
		<hr>
		<div class="alert alert-warning alert-dismissible fade show col-8 mx-auto text-center" role="alert" *ngIf="approved.length == 0">
		  Aucune demande approuvée!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div class="card-row">
			<div class="" *ngFor="let demande of approved">
				<app-demande-item [demande]="demande"></app-demande-item>
			</div>
		</div>
	</div>

	<div>
		<h5 class="card-title text-primary">Demandes d'inscription en cours</h5>
		<hr>
		<div class="alert alert-warning alert-dismissible fade show col-8 mx-auto text-center" role="alert" *ngIf="encours.length == 0">
		  Aucune demande en cours!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div class="card-row">
			<div class="" *ngFor="let demande of encours">
				<app-demande-item [demande]="demande"></app-demande-item>
			</div>
		</div>
	</div>

	<div>
		<h5 class="card-title text-secondary">Demandes d'inscription refusées</h5>
		<hr>
		<div class="alert alert-warning alert-dismissible fade show col-8 mx-auto text-center" role="alert" *ngIf="refused.length == 0">
		  Aucune demande refusée!
		  <button type="button" class="close" data-dismiss="alert" aria-label="Close">
		    <span aria-hidden="true">&times;</span>
		  </button>
		</div>
		<div class="card-row">
			<div class="" *ngFor="let demande of refused">
				<app-demande-item [demande]="demande"></app-demande-item>
			</div>
		</div>
	</div>
</div>