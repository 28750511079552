<div class="row col-12 m-0 px-3 my-2 justify-content-end">
	<button class="btn btn-sm btn-gaj" type="button" data-toggle="collapse" data-target="#addCycle" *ngIf="user?.role == 'admin'"><i class="fas fa-plus"></i> Nouvelle Spécialité</button>
</div>
<div class="collapse" id="addCycle">
  <div class="card card-body px-0 pt-2 radius-0">
    <app-add-cycle-form></app-add-cycle-form>
  </div>
</div>

<div class="row col-12 mx-0 p-2">
	<div class="alert alert-primary col-md-8 mx-auto text-center" role="alert" *ngIf="cycles.length == 0">
	  aucune spécialité à afficher actuellement!
	</div>
	<div class="col-12" *ngFor="let cycle of cycles">
		<app-cycle-item [cycle]="cycle"></app-cycle-item>
	</div>
</div>
