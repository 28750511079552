import { Component, OnInit, Input } from '@angular/core';
import { Comment } from '../../../../models/Comment';
import { UserdataService } from '../../../../services/userdata.service';
import { User } from '../../../../models/User';
import { AddService } from '../../../../services/add.service';
import { Message3Service } from '../../../../services/message3.service';
import { SelectService } from '../../../../services/select.service';
import { CommentResponse } from '../../../../models/CommentResponse';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.css']
})
export class CommentItemComponent implements OnInit {
  @Input() comment: Comment;
  user: User;
  userresp: User;

  filepath = environment.filepath;

  currentUser: User;
  currentEmail: any;

  commentsresponse: CommentResponse[] = [];

  public commentform = {
    id: null,
    comment_id: null,
    email: null,
    commenttext: null
  };

  constructor(
  	private userdata: UserdataService,
    private addservice: AddService,
    private msg3: Message3Service,
    private select: SelectService
  	) { }

  ngOnInit(): void {
    this.currentEmail = localStorage.getItem('currentusermail')
    this.userdata.getUserData(this.currentEmail).subscribe(res => {
  		this.currentUser = res[0];
  	});

  	this.userdata.getUserData(this.comment.user_email).subscribe(res => {
  		this.user = res[0];
  	});

    this.commentform.comment_id = this.comment.id;
    this.commentform.email = this.currentEmail;

    this.select.getCommentResponses(this.comment.id).subscribe(res => {
      this.commentsresponse = res;
      console.log(this.commentsresponse);
    });

    this.msg3.getMessage().subscribe(res => {
      this.select.getCommentResponses(this.comment.id).subscribe(res => {
        this.commentsresponse = res;
        console.log(this.commentsresponse);
      });
    });
  }


  AddCommentResp(e) {
    this.addservice.addCommentResponse('', this.commentform.comment_id, this.commentform.email, this.commentform.commenttext, '').subscribe(res => {
      //console.log(res);
      this.msg3.setMessage("new operation");
      this.commentform.commenttext = '';
    });
  }

}
