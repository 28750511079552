import { Component, Input, OnInit } from '@angular/core';
import { Cycle } from 'src/app/models/Cycle';

@Component({
  selector: 'app-v-cycle-item',
  templateUrl: './v-cycle-item.component.html',
  styleUrls: ['./v-cycle-item.component.css']
})
export class VCycleItemComponent implements OnInit {

  @Input() cycle: Cycle;
  constructor() { }

  ngOnInit(): void {
  }

}
