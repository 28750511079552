import { Component, OnInit, Input } from '@angular/core';
import { Inscription } from '../../../models/Inscription';

@Component({
  selector: 'app-demande-item',
  templateUrl: './demande-item.component.html',
  styleUrls: ['./demande-item.component.css']
})
export class DemandeItemComponent implements OnInit {
  
  @Input() demande: Inscription;
  constructor() { }

  ngOnInit(): void {
  }

}
