import { Component, OnInit } from '@angular/core';
import { AddService } from '../../services/add.service';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../services/message.service';
import { SelectService } from '../../services/select.service';
import { Partenaire } from '../../models/Partenaire';

@Component({
  selector: 'app-partenaires',
  templateUrl: './partenaires.component.html',
  styleUrls: ['./partenaires.component.css']
})
export class PartenairesComponent implements OnInit {
  
  partenaires: Partenaire[] = [];

  public partenaireform = {
  	image: null,
  	name: null
  };

  selectedImageFile: File = null;

  constructor(
  	private http: HttpClient,
    private msg: MessageService,
    private addservice: AddService,
    private select: SelectService
  	) { }

  ngOnInit(): void {
  	this.select.getAll('partenaires').subscribe(data => {
  		this.partenaires = data;
  	});

  	this.msg.getMessage().subscribe(res => {
  		this.select.getAll('partenaires').subscribe(data => {
	  		this.partenaires = data;
	  	});
  	});
  }

  onImageSelected(event) {
    this.selectedImageFile = <File>event.target.files[0];
    console.log(this.selectedImageFile);
  }

  AddPartenaire(e) {

    const fd = new FormData();

    fd.append('name', this.partenaireform.name);
    fd.append('image', this.selectedImageFile);

    this.addservice.addPartenaire(fd).subscribe(res => {
    	console.log(res);
    	this.msg.setMessage("new operation");

    	this.partenaireform.name = '';
        this.partenaireform.image = null;
        this.selectedImageFile = null;
    });
  }

}
