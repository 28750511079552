import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MessageService } from '../../services/message.service';
import { AddService } from '../../services/add.service';
import { SelectService } from '../../services/select.service';
import { GalleryFile } from '../../models/GalleryFile';
import { DeleteService } from '../../services/delete.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gallery',
  templateUrl: './gallery.component.html',
  styleUrls: ['./gallery.component.css']
})
export class GalleryComponent implements OnInit {
  @ViewChild('myVideo') myVideo: ElementRef;

  files: GalleryFile[] = [];
  filepath = environment.filepath;

  public galleryform = {
  	file: null,
  	format: null
  };

  selectedImageFile: File = null;
  selectedVideoFile: File = null;

  constructor(
  	private http: HttpClient,
    private msg: MessageService,
    private addservice: AddService,
    private select: SelectService,
    private deleteservice: DeleteService
  	) { }

  ngOnInit(): void {
  	this.select.getAll('gallery_files').subscribe(data => {
  		this.files = data.reverse();
  		console.log(this.files);
  	});

  	this.msg.getMessage().subscribe(res => {
  		this.select.getAll('gallery_files').subscribe(data => {
	  		this.files = data.reverse();
	  		console.log(this.files);
	  	});
  	});
  }

  onImageSelected(event) {
  	this.selectedImageFile = <File>event.target.files[0];
    console.log(this.selectedImageFile);

    const fd = new FormData();
    fd.append('format', 'img');
    fd.append('file', this.selectedImageFile);

    this.addservice.addGalleryFile(fd).subscribe(res => {
    	console.log(res);
    	this.msg.setMessage('new Operation');
    });
  }

  onVideoSelected(event) {
  	this.selectedVideoFile = <File>event.target.files[0];
    console.log(this.selectedVideoFile);

    const fd = new FormData();
    fd.append('format', 'video');
    fd.append('file', this.selectedVideoFile);

    this.addservice.addGalleryFile(fd).subscribe(res => {
    	this.msg.setMessage('new Operation');
    });
  }

  show(i) {
  	alert(this.files[i].file);
  }

  delete(id) {
  	this.deleteservice.deleteData('gallery_files', id).subscribe(res => {
  		console.log(res);
  		this.msg.setMessage('new operation');
  	});
  }

  playVideo(id) {
    /**
     * You are accessing a dom element directly here,
     * so you need to call "nativeElement" first.
     */
    this.myVideo.nativeElement.pause();
  }

}
