<div class="row col-12 d-flex" style="position: relative;">
	<div class="commenteduser">
		<img src="assets/img/avatar.png" *ngIf="user?.avatar == null">
		<img src="{{filepath}}/profileAvatars/{{user?.avatar}}" *ngIf="user?.avatar != null">
	</div>
	<div class="comment-body m-0 border shadow-sm" style="width: calc(100% - 35px); top: 8px; left: 28px;">
		<div class="row col-12 m-0 p-0 border-bottom border-light">
			<small class="pl-3 col-12 justify-content-between">
				<strong>{{user?.fname}} {{user?.lname}}</strong>
				<span class="ml-2">{{comment?.created_at | date: 'MMM, dd yyyy'}}</span>
			</small>
		</div>
		<span class="m-2">{{comment?.commenttext}}</span><br>
		<div class="row col-12 p-0 m-0 justify-content-end">
			<a class="my-0 mr-2 text-gaj" data-toggle="collapse" href="#repondre{{comment?.id}}" role="button" aria-expanded="false" aria-controls="collapseExample"><small>Répondre</small></a>
			<a class="my-0 mr-3 text-gaj" data-toggle="collapse" href="#responsebox{{comment?.id}}" role="button" aria-expanded="false" aria-controls="collapseExample" *ngIf="commentsresponse.length != 0"><small>Réponses ({{commentsresponse.length}})</small></a>
		</div>

		<div class="col-12 mt-2 comment-response-box">
			<div class="collapse" id="repondre{{comment?.id}}">
			  <div class="p-2">
			    <form (submit)="AddCommentResp($event)" autocomplete="off" class="d-flex">
					<div class="comment-user-avatar">
						<img src="assets/img/avatar.png" *ngIf="currentUser?.avatar == null">
						<img src="{{filepath}}/profileAvatars/{{currentUser?.avatar}}" *ngIf="currentUser?.avatar != null">
					</div>
					<textarea name="comment" class="form-control shadow-sm" placeholder="Répondre. . ." [(ngModel)]="commentform.commenttext" rows="1"></textarea>
					<button type="submit" class="btn btn-sm btn-light"><i class="fab text-gaj fa-telegram-plane"></i></button>
				</form>
			  </div>
			</div>
			<div class="collapse" id="responsebox{{comment?.id}}">
			  <div class="px-2 pt-2 border-top">

			  	<div *ngFor="let response of commentsresponse" class="d-flex">
					  <app-response-item [response]="response"></app-response-item>
			  	</div>

			    <div class="alert alert-primary text-center py-0" role="alert" *ngIf="commentsresponse.length == 0">
				  Aucune Réponse à ce commentaire!
				</div>
			  </div>
			</div>
		</div>
	</div>
</div>
