import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

/* ===== Layouts imports ===== */
import { NotAuthLayoutComponent } from './layouts/not-auth-layout/not-auth-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { ItemLayoutComponent } from './layouts/item-layout/item-layout.component';

import { BeforeLoginService } from './services/before-login.service';
import { AfterLoginService } from './services/after-login.service';

/* ===== import Visitor Components ===== */
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { HomeComponent } from './visitor/home/home.component';
import { AllpublicationsComponent } from './visitor/allpublications/allpublications.component';
import { PublicationPageComponent } from './visitor/publication-page/publication-page.component';
import { ActualityPageComponent } from './visitor/actuality-page/actuality-page.component';
import { ProfileComponent } from './user/profile/profile.component';
import { DashboardComponent } from './user/dashboard/dashboard.component';
import { PublicationsComponent } from './user/publications/publications.component';
import { ActualitiesComponent } from './user/actualities/actualities.component';
import { AllactualitiesComponent } from './visitor/allactualities/allactualities.component';
import { CyclesComponent } from './user/cycles/cycles.component';
import { MessagesComponent } from './user/messages/messages.component';
import { RenseignementsComponent } from './user/renseignements/renseignements.component';
import { StudentFilesComponent } from './user/student-files/student-files.component';
import { StudentsComponent } from './user/students/students.component';
import { NotificationsComponent } from './user/notifications/notifications.component';
import { SuiviDemarchesComponent } from './user/suivi-demarches/suivi-demarches.component';
import { CycleItemComponent } from './user/cycles/cycle-item/cycle-item.component';
import { CycleDetailsComponent } from './user/cycles/cycle-item/cycle-details/cycle-details.component';
import { DemandeApprovedDetailsComponent } from './user/suivi-demarches/demande-item/demande-approved-details/demande-approved-details.component';
import { FavoritepubsComponent } from './user/favoritepubs/favoritepubs.component';

import { AccueilblogComponent } from './user/blogs/accueilblog/accueilblog.component';
import { AmenagementblogComponent } from './user/blogs/amenagementblog/amenagementblog.component';
import { CartesejourblogComponent } from './user/blogs/cartesejourblog/cartesejourblog.component';
import { EtudesblogComponent } from './user/blogs/etudesblog/etudesblog.component';
import { PresentationblogComponent } from './user/blogs/presentationblog/presentationblog.component';
import { VieestudiantineblogComponent } from './user/blogs/vieestudiantineblog/vieestudiantineblog.component';

import { DemandeAcceuilComponent } from './user/demande-acceuil/demande-acceuil.component';
import { AvisRechercheComponent } from './user/avis-recherche/avis-recherche.component';

import { PartenairesComponent } from './user/partenaires/partenaires.component';
import { GalleryComponent } from './user/gallery/gallery.component';

import { SupportComponent } from './user/support/support.component';
import { PubItemComponent } from './user/pub-item/pub-item.component';

import { ContactListComponent } from './user/support/contact-list/contact-list.component';
import { FaqsListComponent } from './user/support/faqs-list/faqs-list.component';
import { TermsListComponent } from './user/support/terms-list/terms-list.component';
import { VCycleDetailsComponent } from './visitor/home/v-cycle-details/v-cycle-details.component';
import { AproposdenousComponent } from './visitor/aproposdenous/aproposdenous.component';
import { DepartementsComponent } from './visitor/departements/departements.component';
import { FraisComponent } from './visitor/frais/frais.component';
import { RequestResetComponent } from './visitor/resetpassword/request-reset/request-reset.component';
import { ResponseResetComponent } from './visitor/resetpassword/response-reset/response-reset.component';
import { NotAuthGuard } from './guards/not-auth.guard';
import { AuthGuard } from './guards/auth.guard';

const routes: Routes = [
	{
    path: 'accueil',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: HomeComponent}
    ]
  },

  {
    path: 'reset-password-request',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: RequestResetComponent}
    ]
  },

  {
    path: 'response-password-reset',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: ResponseResetComponent}
    ]
  },

  {
    path: 'accueil/cycles/:id',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: VCycleDetailsComponent}
    ]
  },
  {
    path: 'presentation',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: AproposdenousComponent}
    ]
  },
  {
    path: 'departements',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: DepartementsComponent}
    ]
  },
  {
    path: 'frais',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: FraisComponent}
    ]
  },

  {
    path: 'publications',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: AllpublicationsComponent}
    ]
  },
  {
    path: 'actualities',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: AllactualitiesComponent}
    ]
  },
  {
    path: 'publication/:id',
    canActivate: [NotAuthGuard],
    component: ItemLayoutComponent,
    children: [
      { path: '', component: PublicationPageComponent}
    ]
  },
  {
    path: 'actuality/:id',
    canActivate: [NotAuthGuard],
    component: ItemLayoutComponent,
    children: [
      { path: '', component: ActualityPageComponent}
    ]
  },
	{
    path: '',
    canActivate: [NotAuthGuard],
    component: NotAuthLayoutComponent,
    children: [
      { path: '', component: HomeComponent}
    ]
  },
  {
    path: 'gaj/profile',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: ProfileComponent}
    ]
  },
  {
    path: 'gaj/accueil',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: DashboardComponent}
    ]
  },
  {
    path: 'gaj/publications',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: PublicationsComponent}
    ]
  },
  {
    path: 'gaj/publications/:id',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: PubItemComponent}
    ]
  },
  {
    path: 'gaj/favoris',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: FavoritepubsComponent}
    ]
  },
  {
    path: 'gaj/actualities',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: ActualitiesComponent}
    ]
  },
  {
    path: 'gaj/mesfichiers',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: StudentFilesComponent}
    ]
  },
  {
    path: 'gaj/etudiants',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: StudentsComponent}
    ]
  },
  {
    path: 'gaj/renseignements',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: RenseignementsComponent}
    ]
  },
  {
    path: 'gaj/demandes-accueil',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: DemandeAcceuilComponent}
    ]
  },
  {
    path: 'gaj/avis-recherche',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: AvisRechercheComponent}
    ]
  },
  {
    path: 'gaj/cycles',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: CyclesComponent}
    ]
  },
  {
    path: 'gaj/messages/room/:id',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: MessagesComponent}
    ]
  },
  {
    path: 'gaj/cycles/:id',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: CycleDetailsComponent}
    ]
  },
  {
    path: 'gaj/notifications',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: NotificationsComponent}
    ]
  },
  {
    path: 'gaj/suivi-demarches',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: SuiviDemarchesComponent}
    ]
  },
  {
    path: 'gaj/suivi-demarches/demande/:id',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: DemandeApprovedDetailsComponent}
    ]
  },
  {
    path: 'gaj/inscriptions',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: SuiviDemarchesComponent}
    ]
  },

  {
    path: 'gaj/gallery',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: GalleryComponent}
    ]
  },

  {
    path: 'gaj/partenaires',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: PartenairesComponent}
    ]
  },

  {
    path: 'gaj/support',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: SupportComponent}
    ]
  },

  {
    path: 'gaj/contact-us',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: ContactListComponent}
    ]
  },
  {
    path: 'gaj/faqs',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: FaqsListComponent}
    ]
  },
  {
    path: 'gaj/termes-et-conditions',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: TermsListComponent}
    ]
  },





  {
    path: 'gaj/blog/accueil',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: AccueilblogComponent}
    ]
  },
  {
    path: 'gaj/blog/amenagement',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: AmenagementblogComponent}
    ]
  },
  {
    path: 'gaj/blog/carte-de-sejour',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: CartesejourblogComponent}
    ]
  },
  {
    path: 'gaj/blog/etudes',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: EtudesblogComponent}
    ]
  },
  {
    path: 'gaj/blog/presentation',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: PresentationblogComponent}
    ]
  },
  {
    path: 'gaj/blog/vie-estudiantine',
    canActivate: [AuthGuard],
    component: AuthLayoutComponent,
    children: [
      { path: '', component: VieestudiantineblogComponent}
    ]
  },







  {
    path: 'page-not-found',
    component: PageNotFoundComponent
  },

  {
    canActivate: [],
    path: '**',
    redirectTo: '/page-not-found'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
