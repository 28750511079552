<div *ngIf="user?.role == 'admin'">

	<div class="col-lg-10 col-md-11 col-sm-12 mx-auto p-3 my-5 bg-white border" style="min-height: 120px">
		<h5 class="card-title">Ajouter une Question Fréquente</h5>

		<form #faqform=ngForm (submit)="addFaq($event)">

			<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="question">Question <strong class="required-star">*</strong></label>
				    <textarea rows="3" class="form-control form-control-sm" name="question" id="question" [(ngModel)]="question" required placeholder="Message..."></textarea>
				</div>
			</div>
			<div class="row m-0">
				<div class="form-group col-sm-12">
				    <label for="response">Réponse <strong class="required-star">*</strong></label>
				    <textarea rows="3" class="form-control form-control-sm" name="response" id="response" [(ngModel)]="response" required placeholder="Message..."></textarea>
				</div>
			</div>

			<div class="row mx-0 px-3">
				<button type="submit" [disabled]="!faqform.valid" class="messagesubmitbtn btn btn-gaj btn-block">
					Ajouter
				</button>
			</div>

		</form>
	</div>
	<hr>
</div>

<div class="col-lg-10 col-md-11 col-sm-12 mx-auto p-2 my-3 bg-white border">

	<h4 class="subtitle my-2 ml-2">Liste des Questions Fréquentes</h4>
	<hr>

	<div class="accordion" id="accordionExample">
	  <div class="card" *ngFor="let faq of faqs">
	    <div class="card-header p-0" id="headingOne">
	      <h2 class="mb-0 bg-gaj d-flex justify-content-between">
	        <a class="btn btn-gaj btn-block text-left p-0" type="button" data-toggle="collapse" href="#collapseOne{{faq?.id}}" aria-expanded="true" aria-controls="collapseOne">
              <p class="pt-2 px-2">{{faq?.question}}</p>
	        </a>
          <button (click)="DeleteFAQ(faq?.id)" class="btn btn-sm my-auto text-danger mx-2 btn-light" style="width: 28px; height: 28px; padding: 0 !important; text-align: center; line-height: 28px; z-index: 1;"><i class="fas fa-times"></i></button>
	      </h2>
	    </div>

	    <div id="collapseOne{{faq?.id}}" class="collapse" aria-labelledby="headingOne" data-parent="#accordionExample">
	      <div class="card-body">
	        {{faq?.response}}
	      </div>
	    </div>
	  </div>
	</div>

</div>



<div id="confirmAddFaq" class="confirm-message py-1 px-3 bg-success text-center ml-auto shadow text-white" style="z-index: 1; bottom: 0; left: 0; right: 0; position: fixed; margin-bottom: 70px; margin-right: 25px; display: none; border-radius: 4px; width: auto; max-width: 300px; height: 60px; line-height: 50px;">
	<i class="fas fa-check-circle"></i> Faq ajouter avec succés!
</div>
