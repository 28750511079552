import { Component, OnInit } from '@angular/core';
import { DemandeAccueil } from '../../models/DemandeAccueil';
import { SelectService } from '../../services/select.service';
import { MessageService } from '../../services/message.service';

@Component({
  selector: 'app-demande-acceuil',
  templateUrl: './demande-acceuil.component.html',
  styleUrls: ['./demande-acceuil.component.css']
})
export class DemandeAcceuilComponent implements OnInit {

  doneDemandeAccueil: DemandeAccueil[] = [];
  nextDemandeAccueil: DemandeAccueil[] = [];

  constructor(
  	private select: SelectService,
  	private msg: MessageService
  	) { }

  ngOnInit(): void {
  	this.select.getDemandeAccueil('0').subscribe(res => {
  		this.nextDemandeAccueil = res;
  		console.log(this.nextDemandeAccueil);
  	});

  	this.select.getDemandeAccueil('1').subscribe(res => {
  		this.doneDemandeAccueil = res;
  		console.log(this.doneDemandeAccueil);
  	});

  	this.msg.getMessage().subscribe(res => {
  		this.doneDemandeAccueil = [];
  		this.nextDemandeAccueil = [];

  		this.select.getDemandeAccueil('0').subscribe(res => {
	  		this.nextDemandeAccueil = res;
	  		console.log(this.nextDemandeAccueil);
	  	});

	  	this.select.getDemandeAccueil('1').subscribe(res => {
	  		this.doneDemandeAccueil = res;
	  		console.log(this.doneDemandeAccueil);
	  	});
  	})
  }

}
