<div id="pubupdatesuccess{{actuality?.id}}" class="alert alert-success py-1 text-center col-12" role="alert" style="display: none; height: auto;">
  Mise à jour de l'actualité réussie!
</div>

<div class="row col-12 p-0 mx-0 mb-4 bg-white shadow-sm border actualitybox" style="position: relative;">

  <div class="col-12 actoperationrow p-0 d-flex justify-content-center" *ngIf="role == 'admin'">
    <div class="actoperationbox mx-auto bg-light">
      <a class="btn shadow-sm btn-sm ml-auto managebtn managebtnedit border-primary" data-toggle="modal" href="#editact{{actuality?.id}}"><i class="fas fa-edit"></i></a>
      <a class="btn shadow-sm btn-sm mx-2 managebtn managebtndelete border-danger" data-toggle="modal" href="#deleteact{{actuality?.id}}"><i class="fas fa-times"></i></a>
    </div>
  </div>

	<div class="title col-12 m-0 bg-gaj text-white border-bottom">
		<span class="m-2">{{actuality?.title}}</span>
		<a class="btn btn-link" data-toggle="modal" href="#addActuality{{actuality?.id}}"><i class="fas text-white mt-1 fa-plus-circle"></i> plus</a>
	</div>
	<div *ngIf="actuality?.image != null" style="width: 100%; height: 200px; position: relative;">
    <label for=""></label>
    <input type="file" class="form-control-file" id="imagefile{{actuality?.id}}" name="imagefile" (change)="editActPic(actuality?.id, $event)" style="display: none;">
    <label for="imagefile{{actuality?.id}}" class="btn btn-primary btn-sm" style="top: 8px; left: 8px; position: absolute;" *ngIf="role == 'admin'"><i class="fas fa-pen"></i></label>
		<a data-toggle="modal" href="#showimg{{actuality?.id}}">
      <img style="width: 100%; height: 100%; object-fit: cover;" src="{{filepath}}/actualitiesimgs/{{actuality?.image}}">
    </a>
	</div>
	<div class="description col-12 m-0 p-1">
		{{actuality?.description}}
	</div>
</div>

<!-- Show l'image de l'Actualité Modal -->
<div class="modal fade" id="showimg{{actuality?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content moremodal">
      <div class="modal-body px-0 pb-0 pt-0" style="overflow: hidden;">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <img style="width: 100%; border-radius: 4px;" src="{{filepath}}/actualitiesimgs/{{actuality?.image}}">
      </div>
    </div>
  </div>
</div>

<!-- Show More Actualité Modal -->
<div class="modal fade" id="addActuality{{actuality?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content moremodal">
      <div class="modal-body px-0 pb-0 pt-0">
        <button type="button" class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>
        <div *ngIf="actuality?.image != null" style="width: 100%; height: 200px;">
			<img style="width: 100%; height: 100%; object-fit: cover;" src="{{filepath}}/actualitiesimgs/{{actuality?.image}}">
		</div>
        <div class="moretitle col-12">
			<span class="m-2">{{actuality?.title}}</span>
		</div>
		<div class="moredescription border-top col-12 m-0 p-3">
			{{actuality?.description}}
		</div>
      </div>
    </div>
  </div>
</div>

<!-- Edit Publication Modal -->
<div class="modal fade" id="editact{{actuality?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-body">
      	<button type="button" #closebutton class="btn close-btn text-white" data-dismiss="modal" aria-label="Close">
          <i class="fas fa-times closemodalicon"></i>
        </button>

        <form class="mt-3" (submit)="updateactuality()" autocomplete="off">

			<div class="row">
				<div class="form-group col-sm-8">
					<h5>Modifier l'actualité</h5>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-sm-12">
				    <label for="title">Titre de l'Actualité</label>
				    <input type="text" name="title" class="form-control" [(ngModel)]="actform.title" required>
				</div>
			</div>
			<div class="row">
				<div class="form-group col-sm-12">
				    <label for="description">Description</label>
				    <textarea class="form-control" rows="3" name="description"[(ngModel)]="actform.description" required></textarea>
				</div>
			</div>
			<div class="row mx-0 px-0 col-sm-12">
				<button type="submit" class="ml-auto btn btn-sm btn-block btn-gaj">
					Modifier
				</button>
			</div>

        </form>
      </div>
    </div>
  </div>
</div>

<!-- Delete Publication Modal -->
<div class="modal fade" id="deleteact{{actuality?.id}}" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div class="modal-dialog modal-sm">
    <div class="modal-content">
      <div class="modal-header">
        <h6 class="modal-title" id="exampleModalLabel">Confirmer la Suppression</h6>
        <button type="button" #closedeleteactbutton class="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
      	<div id="pubdeletesuccess{{actuality?.id}}" class="alert alert-success py-1 text-center" role="alert" style="display: none;">
		  {{deleteresponse}}
		</div>
        <div class="row mx-0 col-12">
        	<button type="button" class="btn btn-secondary ml-auto mr-1" data-dismiss="modal">Annuler</button>
        	<button  type="button" class="btn btn-danger ml-1 mr-auto" (click)="Delete()">Supprimer</button>
        </div>
      </div>
    </div>
  </div>
</div>
